@eva-select-list-border-radius: 2px;
.eva-select-list {
  height: @input-box-height;
  width: 245px;
  margin-bottom: 15px;

  .btn-select {
    height: @input-box-height;
    width: 100%;
    outline: none!important;
    border: solid 1px @gray-eva-light;
    background: @white!important;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: @gray-darker;
    font-weight: 500;

    &:focus, &:hover {
      background: @white!important;
    }

  }

  .divider {
    margin: 0;
  }

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: 175px;
    overflow-y: scroll;
    border: solid 1px @input-border-focus-color;
    border-top: 0;
    border-radius: 0 0 @eva-select-list-border-radius @eva-select-list-border-radius;

    li {
      .truncated();
      height: @input-box-height;
      line-height: @input-box-height;
      padding: 0px 12px;

      &:hover, &:focus {
        background-color: @gray-17;
      }

      &.disabled {
        color: @gray-dark;
        cursor: default;
      }
    }
  }
}


.open > .dropdown-toggle.btn-select{
  border-radius: @eva-select-list-border-radius @eva-select-list-border-radius 0 0;
  border-color: @input-border-focus-color;
}
