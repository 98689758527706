/**
 * Buttons
 * @color {rgb, hex} - Button background
 */
.eva-btn(@color:@blue) {
  @text: @white;

  .border-radius(3px);
  .transition(background);

  border: none;
  color: @text;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;

  &:visited { color: @text; }
  &:hover { color: @text; }
}

.overflow-table() {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}


.eva-custom-radio-button(@color, @border-color: @color, @diameter: 16px) {
  input {
    opacity: 0;
    position: absolute;
  }

  input, label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }

  label {
    position: relative;
  }

  input + label:before {
    content: '';
    background: @white;
    border: 1px solid @border-color;
    display: inline-block;
    vertical-align: middle;
    width: @diameter;
    height: @diameter;
    padding: 2px;
    margin-right: (@diameter / 2);
    margin-top: -2px;
    text-align: center;
  }

  input + label:before {
    border-radius: 50%;
  }

  input:checked + label:before {
    background: @color;
    box-shadow: inset 0px 0px 0px 3px @white;
  }

}

.eva-custom-checkbox(@border-color: @white, @icon-color: @gray-darker, @size: 16px, @background-color-checked: @white) {
  position: relative;
  cursor: pointer;
  width: @size;
  height: @size;
  user-select: none;


  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  i {
    position: absolute;
    top: 0px;
    left: 0px;
    width: @size;
    height: @size;
    color: @icon-color;
    font-size: 12px;
    border: 1px solid @border-color;
    border-radius: 2px;
    &:before {
      display: none;
    }
  }

  /* When the checkbox is checked, add a background */
  input:checked ~ i {
    background-color: @background-color-checked;
    &:before {
      .absolute-center;
      display: block;
    }
  }
}
/* Add ellipse to multi-line text*/
/* Adapted from http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation */
.block-with-text(@line-height, @number-of-lines) {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: @line-height;
  /* max-height = line-height * lines max number */
  max-height: (@line-height * @number-of-lines * 1em);
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;

  /* create the ... */
  &:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }

  /* hide ... if we have text, which is less than or equal to max lines */
  &:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: @white;
  }
}



/* Copied from dependency mrwhite-less-mixins https://github.com/believer/mrwhite-less-mixins*/

/* Useful variables
--------------------------------------------------------*/
@mobile: ~"only screen and (max-width: 480px)";
@tablet: ~"only screen and (min-width: 768px) and (max-width:1024px)";

@landscape: ~"and (orientation: landscape)";
@portrait: ~"and (orientation: portrait)";

// Specific devices
@iphone5: ~"only screen and (min-device-width: 320px) and (max-device-width: 568px)";
@iphone4: ~"only screen and (min-device-width: 320px) and (max-device-width: 480px)";

/* Shapes and sizing
--------------------------------------------------------*/
@default-bg: @black-2;

.size(@width, @height:@width) {
  height:@height;
  width:@width;
}

.circle(@size, @bg:@default-bg) {
  .size(@size);
  .border-radius(@size);
  background:@bg;
}

.oval(@size, @bg:@default-bg) {
  @half: (@size / 2);
  .size(@size, @half);
  .border-radius("@{size} / @{half}");
  background:@bg;
}

.square(@size, @bg:@default-bg) {
  .size(@size);
  background:@bg;
}

/* Mixins
--------------------------------------------------------*/
/**
 * Remove apperance of form elements
 * @prop {value} - Property value
 */
.appearance(@prop: none) {
  .prefix(appearance, @prop);
}

/**
 * Add a border radius to element
 * @radius {px} - Border radius
 */
.border-radius(@radius: 3px) {
  .prefix(border-radius, "@{radius}");
}

/**
 * Adds a shadow to element (if shadow is inner use "inner-shadow")
 * @shadow {string} - Shadow string
 */
.box-shadow(@shadow) {
  .prefix(box-shadow, @shadow);
}

/**
 * Set box model of element
 * @type {string} - Box model type
 */
.box-sizing(@type: border-box) {
  .prefix(box-sizing, @type);
}

/**
 * Position content
 * @x {px,%} - x-positioning (-50% to center if target element is positioned left:50%)
 * @y {px,%} - y-positioning (-50% to center if target element is positioned top:50%)
 */
.centered(@x,@y) {
  .prefix(transform, "translateX(@{x}) translateY(@{y})");
}

.center(@coord, @direction:top, @y:0) when (@coord = x) {
  @dir: replace(~"@{direction}","'","","g");
  .centered(-50%,0);
  .position(@dir, left, @y, 50%);
}

.center(@coord, @direction:left, @x:0) when (@coord = y) {
  @dir: replace(~"@{direction}","'","","g");
  .centered(0,-50%);
  .position(top, @dir, 50%, @x);
}

.center(@coord) when (@coord = both) {
  .centered(-50%,-50%);
  .position(top, left, 50%, 50%);
}

/**
 * Column layout
 * @columns {int} - Number of columns
 * @gap {px} - Gutter gap between columns
 */
.columns(@columns:2, @gap: 20px) {
  .prefix(column-count, @columns);
  .prefix(column-gap, @gap);
}

/**
 * Sets font-size in rem and px-fallback for IE8
 * @size {px} - Pixel size
 * @style {string} - Font style
 */
.font(@size, @style:normal) {
  @clean-size:unit(@size);
  @rem: (@clean-size / 10);

  font-size: ~"@{clean-size}px";
  font-size: ~"@{rem}rem";
  font-style: @style;
}

/**
 * Gives element an inner shadow
 * @shadow {string} - Shadow string
 */
.inner-shadow(@shadow: "1px 1px 0px rgba(0,0,0,1)") {
  .prefix(box-shadow, "inset @{shadow}");
}

/**
 * Makes element transparent
 * @opacity {int} - Opacity in percentage
 */
.opacity(@opacity) {
  .prefix('opacity', (@opacity/100));
  filter:~"alpha(opacity=@{opacity})";
}

/**
 * Add all browser prefixes
 * @property {string} - What type of property
 * @value {string, value} - Arguments
 */
.prefix(@property, @value) {
  @prop: replace(~"@{property}","'","","g");
  @val: replace(~"@{value}","'","","g");
  @{prop}: @val;
}

/**
 * Positioning helpers
 * @direction1 {string} - Point to position element from
 * @direction2 {string} - Point to position element from
 * @position1 {px} - Amount to move from direction1
 * @position2 {px} - Amount to move from direction2
 * @positioning {string} - Set positioning type
 * @full {boolean} - Cover full width
 */
.positioned(@direction1, @direction2, @position1:0, @position2:0, @positioning: absolute) {
  @dir1: replace(~"@{direction1}", "'","","g");
  @dir2: replace(~"@{direction2}", "'","","g");
  @pos: replace(~"@{positioning}", "'","","g");

    @{dir1}: @position1;
    @{dir2}: @position2;
  position: @pos;
}

.position(@direction1, @direction2, @position1:0, @position2:0, @positioning: absolute, @full:false) when (@full = false) {
  .positioned(@direction1, @direction2, @position1, @position2, @positioning);
}

.position(@direction1, @direction2, @position1:0, @position2:0, @positioning: absolute, @full:false) when (@full = true) {
  .positioned(@direction1, @direction2, @position1, @position2, @positioning);
  .size(100%);
}

/**
 * Rotate element
 * @angle {deg} - Angle in degrees
 */
.rotate(@angle) {
  .transform(rotate(@angle));
}

/**
 * Scale an element
 * @ratio {int} - Scale by integer
 */
.scale(@ratio) {
  .transform(scale(@ratio));
}

/**
 * Adds text overflow properties CSS
 */
.text-overflow() {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

/**
 * Adds text-shadow
 */
.text-shadow(@shadow: 1px 1px 0px rgba(0,0,0,1)) {
  .prefix('text-shadow',@shadow);
}

/**
 * Transform
 * @transform {string} - Transform string
 */
.transform(@transform) {
  .prefix("transform", "@{transform}");
}

/**
 * Adds transitions
 * @type {string} - All or specific selector to transition
 * @time {s} - Time of transition
 * @animate {string} - Type of animation
 */
.transition(@type: all, @time: 300ms, @animate: ease) {
  .prefix("transition", @type @time @animate);
}

/**
 * Make triangles
 * @direction {string} - Pointing direction (switches directions for it to point the right way)
 * @size {px} - Size of triangle
 * @color {hex, rgb} - Color of triangle
 */
.triangle-base() {
  content:"";
  display:inline-block;
  height:0;
  width:0;
}

.triangle(@direction, @size, @color:@default-bg) when (@direction = up), (@direction = down), (@direction = 'up'), (@direction = 'down') {
  @string: replace(~"@{direction}", "'","","g");
  @dir: ~`('@{string}' === 'up') ? 'bottom':'top'`;

  .triangle-base();

  border-right: @size solid transparent;
  border-@{dir}: @size solid @color;
  border-left: @size solid transparent;
}

.triangle(@direction, @size, @color:@default-bg) when (@direction = right), (@direction = left), (@direction = 'right'), (@direction = 'left') {
  @string: replace(~"@{direction}", "'","","g");
  @dir: ~`('@{string}' === 'left') ? 'right':'left'`;

  .triangle-base();

  border-top: @size solid transparent;
  border-bottom: @size solid transparent;
  border-@{dir}: @size solid @color;
}

/**
 * Corner triangles
 * @direction1 {string} - Direction top or bottom
 * @direction2 {string} - Direction right or left (switches input left to right for arrow to actually point left)
 * @size {px} - Size of triangle
 * @color {hex, rgb} - Color of triangle
 */
.triangle-corner(@direction1, @direction2, @size, @color:@default-bg) {
  @string1: replace(~"@{direction1}", "'","","g");
  @string: replace(~"@{direction2}", "'","","g");
  @dir: ~`('@{string}' === 'right') ? 'left':'right'`;

  .triangle-base();

  border-@{string1}: @size solid @color;
  border-@{dir}: @size solid transparent;
}

/**
 * Ability to disable text highlighting
 * @arg {string} - What is selectable
 */
.user-select(@arg: none) {
  @argument: replace(~"@{arg}","'","","g");
  .prefix('user-select', @argument);
}
