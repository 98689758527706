@delete-queue-modal-button-margin: 26px 0 30px;
@delete-modal-width: 471px;
.delete-queue-modal {
  margin-top: 100px;
  #delete-modal();
  // to prevent margin collapse and to make sure the container is wrapped around the content with the margin
  overflow: hidden;
  
  &.delete-disabled {
    ul {
      margin-right: 65px;
      list-style-type:disc;
      li {
        margin: 3px 0 0 17px;
      }
    }
  }

  .delete-queue-modal-rules {
    background: @gray;
    margin: 28px -29px 0;
    padding: 21px 29px 0;
    line-height: 28px;

    .implioicons-open-in-tab {
      position: relative;
      &:before {
        .absolute-center();
      }
    }

    > b {
      margin-left: 7px;
    }

    > div {
      border-bottom: 1px solid @dark-gray;
      height: 28px;

      &:first-of-type {
        margin-top: 9px;
        border-top: 1px solid @dark-gray;
      }

      a {
        .flex-container;
        .flex-space-between;
        width: 100%;
        align-items: center;
        padding: 0 7px;
        span, i {
          display: inline-block;
        }

        i {
          height: 15px;
          width: 15px;
          color: @gray-darker;
          background-size: contain;
        }
        &:hover {
          color: @brand-third;
          text-decoration: none!important;
        }
      }
    }
  }
}