.security-warning-modal {
    width: 610px;
    padding:30px;
  font-size: 14px;
  color: black;
  white-space: normal;
}

.margin-top{
    margin-top: 10px;
}

.title{
    margin-bottom: 22px;
}

.action-buttons{
    margin-top: 22px;

        display: flex;
        justify-content: flex-end;

        .eva-primary-button {
          display: inline;
          width: 150px;
        }

        .eva-secondary-button {
          display: inline;
          width: 150px;
          margin-right: 20px;
        }
      
}