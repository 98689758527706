@margin-top: 10px;
.select-rule-decision {

  .eva-custom-radio-button {
    &.good {
      .eva-custom-radio-button(@green-4);
    }
    &.info {
      .eva-custom-radio-button(@yellow-2);
    }
    &.problem {
      .eva-custom-radio-button(@brand-danger);
    }
    &.critical {
      .eva-custom-radio-button(@gray-darker);
    }
    &.nothing, &.refuse, &.manual, &.approve {
      .eva-custom-radio-button(@brand-primary, @gray-eva-light);
    }

  }
  width: 100%;
  .title, .decision-radio-buttons, .detailed-decision {
    width: 100%;
    font-weight: @create-rule-view-label-font-weight;
  }
  label {
    font-weight: normal;
  }

  .title {
    margin-top: @margin-top;
    line-height: 28px;
    border-bottom: 1px solid @border;
  }

  .decision-radio-buttons {
    width: 70%;
    margin: 20px auto 8px auto;
    .flex-container;
    .flex-space-between;
    div { .flex-item; }
  }

  .detailed-decision {
    .arrow_box(20%);
    &.item-1 {.arrow_box(20%);}
    &.item-2 {.arrow_box(44%);}
    &.item-3 {.arrow_box(69%);}

    &.four-items {
      &.item-1 {.arrow_box(20%);}
      &.item-2 {.arrow_box(39%);}
      &.item-3 {.arrow_box(59%);}
      &.item-4 {.arrow_box(75%);}
    }
    padding: 20px;
    textarea {
      margin-top: @margin-top;
    }

    .decision-picker{
      width: 33%;
      padding-bottom: 15px;
    }
    .decision-picker-block{
      margin-bottom: 30px;
      .warning-text {
        margin-top: -10px;
      }
    }
    .decision-checkboxes {
      margin-top: @margin-top;
      div {
        display: block;
        margin-top: @margin-top;
      }
    }

    .message-category-radio-buttons {
      margin-top: @margin-top;
      .radio-button-group {
        width:50%;
        .flex-container;
        .flex-space-between;
        div {
          .flex-item;
        }
      }

      label {
        margin-bottom: 0px;
      }
    }

  }
  .review-feedback {
    margin: 0 20px 20px 0;
    width: 100%;
  }
  .eva-select-list {
    width: 350px;
  }
}
