.pager {
    li {
      a {
        color: @gray-darker;
        border-color: @gray-3;
        height: 40px;
      }

      a:hover,
      &:first-child a:hover,
      &:last-child a:hover {
        color: @black;
        background-color: @gray-1;
      }


      &.disabled {
        opacity: 1;
        > a,
        > a:hover,
        > a:focus {
          border-color: @gray-3;
          pointer-events: none;
          cursor: default;
          background-color: @white;
          color: @gray-3;
          filter: none;
        }
      }

      &.active {
        a, a:hover {
          color: @black;
          background-color: @gray-1;
        }
      }

    }
  
}
.eva-pagination-arrow-icons {
  font-size: 20px;
}