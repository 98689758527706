@eva-mass-action-decision-button-text-margin-right: 16px;
.eva-mass-action-decision {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  button {
    font-weight: 500;
  }

  .eva-drop-down-with-search {
    height: 100%;
    .dropdown-menu {
      left: unset;
      right: -2px;
      transform: none;
      min-width: 150px;
      max-width: 200px;
    }
  }

  .btn-approve.eva-decision-button {
    outline: none;
    &.btn, &.btn:hover, &.btn:focus {
      background: transparent;
      color: @white;
      &.selected, &:hover, &.eva-keyboard-focused {
        background-color: @brand-success;
        border-color: @brand-success;
      }
    }
  }

  .selected-decision {
    text-align: center;
    color: @white;
    position: absolute;
    width: 100%;
    left: 0;
    top: ~"calc(100% + 2px)";
    line-height: 24px;

    &.selected-decision-approve {
      background-color: @brand-success;
    }
    &.selected-decision-refuse {
      background-color: @brand-danger;
    }
    &.selected-decision-defer{
      background-color: @escalate-button-color;
    }
  }

  &.is-disabled .selected-decision {
    background-color: @refuse-button-disabled-selected-background-color!important;
    color: @refuse-button-disabled-selected-text-color!important;
  }

  .btn-approve.selected, .btn-approve.eva-keyboard-focused ,
  .eva-drop-down-with-search.selected, .eva-drop-down-with-search.eva-keyboard-focused{
    span.eva-shortcut-icon {
      color: @white;
      border-color: @white;
    }
  }

  .eva-drop-down-with-search.refuse {
    border: 2px solid transparent;
    button {
      background: transparent;
      color: @white;
      height: 100%;
      span:first-child {
        margin-right: @eva-mass-action-decision-button-text-margin-right;
      }
    }

    &.selected, &:hover, &.eva-keyboard-focused {
      border-color: @brand-danger!important;
      button {
        background-color: @brand-danger;
        border-color: @brand-danger;
      }
    }
  }

  .eva-decision-button {
    height: 48px;
    span {
      display: inline-block;
    }

    .eva-shortcut-icon + .eva-button-text {
      margin-left: 10px;
    }
  }

  .eva-dropdown-hover-area, .eva-dropdown-arrow {
    display: none;
  }

  .eva-drop-down-with-search li.focused {
    background-color: @gray;
    cursor: pointer;
  }
}


