.rule-conditions {
  padding: 20px 0 40px;

  .dashWrapper {
    padding: 20px 0;
    .dash {
      border-bottom: 1px dashed @gray-eva-light;
      vertical-align: top;
      height: 10px;
    }
    .dash-word {
      width: 40px;
      text-align: center;
      color: @gray-eva-light;
      text-transform: uppercase;
    }
  }
}

.rule-conditions-add-btn {
  margin-top:20px;
  align-self: center;
}

