.refuse-dropdown {
    background-color: white;
    width: 200px;
    border: rgb(220, 220, 220) solid 1px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px 0px;
}

.refuse-dropdown-container {
    right: 0px !important;
    top: 44px !important;
    z-index: 1000;
}