@file-uploader-background-color:@gray-36;
@file-uploader-background-dragover-color:@gray-base;
@file-uploader-border: @gray-dark;

.file-upload-wrapper {
  width: 460px;
  text-align: center;
  margin: 0 auto;

  .eva-primary-button-label {
    margin-top: 40px;
  }

  .eva-primary-button {
    margin-top: 20px;
  }

  .explain-text{
    color: @gray-mike;
    margin-top:30px;
  }

  .drag-drop-text{
    font-size: 18px;
    line-height: 34px;
    word-wrap: break-word;
    color: @brand-secondary;
    font-weight: 500;
    margin-top: 13px;
  }

  a.secondary-link{
    .eva-link-secondary(@gray-mike, @gray-mike);
  }

  .box{
    border-radius: 4px;
    background-color: @file-uploader-background-color;
    position: relative;
    text-align: center;
    margin: 0 auto 20px;
    width: 100%;
    height: 320px;
    border: 2px dashed @file-uploader-border;
    box-sizing: border-box;
    .flex-container;
    justify-content: center;
  }
  .box__file{
    position: absolute;
    z-index: -1;
  }
  .box__icon{
    width: 59px;
    height: 76px;
    display: block;
    margin: 0 auto;
  }

  .box__input {
    margin-top: 50px;
  }

  .box.is-dragover {
    background-color: @file-uploader-background-dragover-color;
  }
  .box.is-uploading .box__input {
    visibility: hidden;
  }
  .box.is-uploading .box__uploading {
    display: block;
  }
  .box .box__dragndrop {
    display: inline;
  }
  .box__uploading,
  .box__success,
  .box__error {
    display: none;
  }
  .notice{
    margin-top: 20px;
    background-color: @pale-yellow;
    border: 1px solid @yellow-2;
    padding: 5px 10px;
    font-size: 14px;
    text-align: left;
    color: @dark-orange;
    line-height: 1.43;
    font-weight: 500;
  }
}

.file-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  .drag-drop-text {
    width: 100%;
  }
  .error-text {
    margin-top: 50px;
  }
}
.file-upload-text {
  width: 100%;
}