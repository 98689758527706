
.add-domain-btn{
    margin:0;
    width: 96px;
    height: 38px;
}



.domains-list-container{
    margin-top: 10px;
    display: flex;
    .domains-list{
        flex-grow: 1;
        height: 300px;
        overflow-y: scroll;
        scroll-behavior: smooth;
        padding: 8px;
        margin-right: 10px;
        border :1px solid #d8dee2;
    }
}

.domain{
    padding: 4px 0px;
}
.selected-domain{
    background-color: #94BCF0;
}