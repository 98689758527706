.jobs {

  .stats-container {
    background-color: @white;
    display: block;
  }

  .has-error, .loading-error {
    margin: 0 auto;
    margin-top: 64px;
    max-width: 40%;
    text-align: center;

    .link {
      .h4();
      color: @brand-primary;
      text-transform: uppercase;
      font-weight: @font-weight-bold;
      &:hover {
        color: @brand-primary-dark;
      }
    }
  }

}

@media only screen and (min-width : 768px) {
  .jobs {

    .stats-container {
      background-color: @white;
      display: block;
    }

  }
}
