.modal-close-btn {
  z-index: 1031;
  position: absolute;
  right: -45px;
  top: -33px;
  width: 24px;
  height: 24px;
  opacity: @button-active-opacity;
  img {
    cursor: pointer;
  }
}
