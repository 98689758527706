body.app-assignment-csvImporter-step1 {
  background-color: @gray-base;
}

.csv-importer-step-container {
  padding: 160px 0px;
}

.csv-importer-navigation-container {
  width: 100%;
  background: @white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 101;
}

