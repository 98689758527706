
.item-content-detail {

    .eva-job.image-gallery-id-0 & {
        margin-top: 0.5px;
    }

    .eva-job.image-gallery-id-1 & {
        margin-top: 0.5px;
    }

    .eva-job.image-gallery-id-2 & {
        margin-top: 8.5px;
    }

}
.eva-job-content-item-price {
    position: relative;
    margin-bottom: 5px;
}

.job-content-items-edit-enabled .eva-job-content-item--editable.has-mouse {
    background-color: transparent;
}