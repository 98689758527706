.line-error {
  color: @brand-danger;
}

.import-dictionary-item-modal {
  width: 620px;

  .modal-footer, .modal-header {
    border: none;
  }

  .notification-error {
    margin: 15px;
  }

  .modal-body {
    padding: 0;

    .CodeMirror-line {
      font-family: 'Roboto', sans-serif;
    }

    .CodeMirror-gutters {
      border-right: none;
      background-color: transparent;
    }

    .CodeMirror {
      border-top: 1px solid @gray-base;
      border-bottom: 1px solid @gray-base;
      padding: .5em 0;
      background: @table-bg-accent;
    }

    .CodeMirror-linenumber {
      width: 35px;
      text-align: center;
    }

  }

}

.import-dictionary-item__buttons {
  display: flex;
}

.code-mirror-highlight-regex-mode {
  .cm-regex {
    color: @purple-2;
  }
}