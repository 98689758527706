@csv-column-mapper-width: 285px;
@table-row-height: 34px;


.csv-column-mapper{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  border-radius: 2px;

  &.should-show-validation{
    .invalid-column, .invalid-attribute {
      .btn-select {
        border: 1px solid @brand-danger;
      }
    }
  }
  position: relative;
  width: @csv-column-mapper-width;
  background-color: @white;

  .selection {
    padding: 20px 20px 0px 20px;
  }

  .select-or-input {
    width: 245px;
    input.custom-value {
      display: inline-block;
      width: 220px!important;
      &:focus {
        border-color: @input-border-focus!important;
      }

    }
  }

  label {
    line-height: 100%;
    margin:0px 0px 8px 0px;
    padding: 0px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: @gray-darker;
  }

  .attribute-name {
    margin-bottom: 0px;
    font-size: 20px;
    height: @input-box-height;
    line-height: @input-box-height;
  }

  .delete-link {
    .eva-link-secondary(@gray-mike, @gray-mike);
    position: absolute;
    right: 20px;
    bottom: 7px;
  }

  table {
    margin-top: 28px;
    margin-bottom: 0px;

    tr {
      width: 100%;
      height: @table-row-height;
      td {
        border-color: @gray-base;
        height: 34px;
        padding: 0px;
        vertical-align: middle;

        &.index {
          width: 40px;
          padding-left: 20px;
          color: @gray-mike;
        }

        &.text {
          .truncated();
          padding-right: 20px;
        }
      }
    }
  }

  .warning-text-block {
    height: 31px;
    color: @brand-danger;
    font-size: 12px;
    margin-top: 2px;
    &.column {
      height: 15px;
    }
  }

  .table-more-items {
    height: @table-row-height;
    padding-left: 20px;
    color: @gray-mike;
    p {
      line-height: @table-row-height;
      margin: 0px;
    }
  }

}
