.moderation-notes {
    position: relative;
}
.moderation-notes__create-note__text-area {
    overflow: hidden;
    width: 100%;
    z-index: 100;
    resize: none;
    outline: none;
    border: 1px solid @border;
    padding-bottom: 40px;
    &::placeholder {
        color: @gray-9;
    }
    &:focus {
        border-color: @gray-9;
    }
}

.moderation-notes__create-note__container {
    position: relative;
}

.moderation-notes__create-note__control {
    position: absolute;
    bottom: 12px;
    right: 0px;
    width: 100%;
    height: 26px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
}

.moderation-notes__create-note__character-count {
    flex-grow: 1;
    margin-right: 10px;
    .truncated();
    color: transparent;
    &.moderation-notes__create-note__character-count--close-to-limit {
        color: @gray-9;
    }
    &.moderation-notes__create-note__character-count--invalid {
        color: @brand-danger;
    }
}

.moderation-notes__create-note__icons-container {
    flex-shrink: 0;
    height: 100%;
}

.moderation-notes__create-note__icon {
    height: 26px;
    width: 26px;
    display: inline-block;
    border-radius: 13px;
    position: relative;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:not(.moderation-notes__create-note__icon--disabled):hover {
      background-color: @gray-1;
      cursor: pointer;
    }

    &.implioicons-paper-plane {
        font-size: 16px;
    }

    &.implioicons-cross {
        font-size: 22px;
    }

    &.moderation-notes__create-note__icon--disabled {
        color: @gray-9;
        cursor: default;
    }
}



.moderation-notes__text {
    background-color: @gray-1;
    padding: 15px;
}

.moderation-notes__text-container__refresh-icon {
    .eva-link-forth();
}