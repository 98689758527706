.eva-jobs-submit-cancel-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  gap: 8px;

  .eva-primary-button, .eva-secondary-button {
    border: 2px solid transparent;
  }

  .eva-primary-button {
    transition: width 0.3s;
  }
}

.single-job-page {
  .eva-job {
    margin-top: 15px;
  }
}