@moderation-note-field-size: 24px;
.moderation-note {
    position: relative;
    background-color: @gray-1;
    margin-bottom: 10px;
    padding: 12px;
    border-left: 2px solid @black;

    .eva-extendable-text__content {
        .text-clamp(6);
        &.is-body-extended {
            display: block;
            overflow: visible;
          }
    }
    
    &.created-by-current-user {
        border-left: 2px solid @brand-third;
    }
}

.moderation-note:hover {
    .moderation-note-actions {
      display: block;
    }
  }

.moderation-note__fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.moderation-note__fields__remote-id {
    .eva-link-primary();
    flex-grow: 1;
    .truncated();
    font-size: 12px;
    margin-right: 5px;
}

.moderation-note__fields__icons {
    flex-shrink: 0;
}

.moderation-note__fields__icon {
    display: inline-block;
    margin-right: 4px;
    position: relative;
    width: @moderation-note-field-size;
    height: @moderation-note-field-size;
    border-radius: (@moderation-note-field-size / 2);
    border: 1px solid @black;
    background-color: white;
    color: @black;
    opacity: 0.2;
    &--highlighted {
        background-color: @black;
        color: white;
        opacity: 1;
    }
    &:before {
        .absolute-center();
    }
}

.moderation-note__info {
    color: @gray-30;
    font-size:12px;
    display: flex;
    justify-content: space-between;
}

.moderation-note__info__email {
    .truncated();
    flex-grow: 1;
    margin-right: 10px;
}

.moderation-note__info__date {
    flex-shrink: 0;
}

.eva-moderation-note-actions-container {
    position: absolute;
    left: 41px;
    bottom: 38px;
    transition: opacity .3s ease-in-out;
    backface-visibility: hidden;
    pointer-events: none;
}

.moderation-note-actions {
    margin: 0;
    background: black;
    position: absolute;
    right: 0px;
    top: 0px;
    display: none;
    pointer-events: all;
  }
  
  @moderation-note-action-size: 30px;
  .moderation-note-action {
    border: 3px solid black;
    background-color: black;
    color: white;
    border-radius: 0;
    width: @moderation-note-action-size;
    height: @moderation-note-action-size;
    display: block;
    cursor: pointer;
    text-align: center;
    font-size: 19px;
    position: relative;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &:hover {
      background-color: gray;
    }
  
    .eva-tooltip {
      left: calc(100% + 11px);
      top: -3px;
      height: 30px;
      padding: 0px 8px 0px 8px;
      display: flex;
      align-items: center;
      &:before {
        top: 50%;
        left: 0px;
        transform: translate(-100%, -50%);
        border-right: 8px solid #000000;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }
    }
    &:not(:last-child):not(:first-child) {
      border-top: none;
      border-bottom: none;
    }
  }

  .eva-moderation-note-actions-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
