
  
  .manual {
    color: @gray-darker;
    position: relative;
    h1.manual-title{
      color: @gray-darker;
      font-size: 30px;
      font-weight: 300;
      line-height: 48px;
      margin: 0;
    }
    hr{
      margin-top:5px;
    }
  
  }
  .manual-queues-header {
    padding: 52px 0 53px;
    .eva-primary-button {
      float:right;
    }
  }
  
  .app-assignment-manual-queues {
  
    div:first-child {
  
      h1 {
        display: inline-block;
      }
    }
  
    .table-input {
      margin-bottom: 19px;
      .search-with-icon {
        float: left;
        height: 32px;
        border-radius: 16px;
        background-color: @gray;
        .flex-container;
        align-items: center;
        input#search-input {
          display: inline-block;
          width: 224px;
          padding: 10px 0;
          background: transparent;
          height: 100%;
          margin-left: 2px;
          transition: all 0.12s;
          transition-timing-function: ease-in;
  
          &:focus {
            width: 280px;
          }
        }
      }
      div:nth-child(2) {
        float: right;
      }
  
    }

  
  
    .empty-ng-table .has-no-data, .empty-ng-table .has-no-results, .empty-ng-table .has-error {
      margin: 64px auto;
    }
    .table {
  
      &.table-striped > tbody > tr:nth-of-type(odd) {
        background-color: @gray-24;
      }
  
      &.table-striped > tbody > tr:nth-of-type(even) {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      thead {
        border-top: 1px solid @border;
        font-weight: normal;
        th {
          border-bottom: none;
          font-size: 13px;
          color: @gray-darker;
          font-weight: normal;
          line-height: 25px;
        }
  
        th:nth-child(1){
          width: 377px;
          padding-left: 15px;
        }
  
        th:nth-child(2){
          width: 188px;
        }
  
        th:nth-child(3){
          width: 492px;
        }
  
        th:nth-child(4) {
          padding: 8px 0;
          width: 36px;
        }
  
        th:nth-child(5) {
          padding: 8px 25px 8px 0;
          width: 51px;
        }
  
      }
  
      i.eva-icon-edit, i.eva-icon-remove, .edit-icon, .delete-icon {
        position: relative;
        width: 36px;
        height: 36px;
        display:inline-block;
        border-radius: 18px;
        background-repeat: no-repeat;
        background-size: 26px 26px;
        background-position: center;
        background-color: transparent;
        color:@gray-darker;
  
        &:hover {
          background-color: @gray-25;
          color: @brand-third;
        }
  
        &:before {
          font-size: 20px;
          .absolute-center();
        }
      }
  
      i.eva-icon-edit {
        .implioicons-edit();
      }
  
      i.eva-icon-remove {
        .implioicons-cross();
      }
  
      tbody {
  
        td:nth-child(4),  td:nth-child(5) {
          padding: 8px 0;
          // I didn't understand why we should fix line-height: 0px; to make sure all cell height are 50px ???
          line-height: 0px;
          width: 45px;
        }
  
        tr > td {
          border: none;
          line-height: 0px;
  
          a {
            line-height: 34px;
            height: 34px;
          }
        }
  
  
        tr > td:nth-child(1) {
          padding-left: 15px;
        }
  
        tr:hover {
          td:nth-child(1), td:nth-child(2), td:nth-child(3) {
            color: @brand-third;
            font-weight: 500;
          }
        }
      }
    }
  
    .queues-explanation {
      p {
        white-space: pre-line;
        color: @gray-2;
      }
    }
  }
  
  
  i.eva-search-icon {
    font-size: 20px;
    margin: 0 10px;
    opacity: 0.5;
    width: 18px;
    height: 30px;
    transition: all 0.12s;
    transition-timing-function: ease-in;
  
    &.focused {
      opacity: 1;
    }
  }
  .positive-count-display {
    padding: 1px 11px;
    color: @white;
    text-align: center;
    border-radius: 9px;
    background-color: @brand-third;
  }
  
  .anchor-full-row-clickable {
      display:block;
      width:100%;
      height:100%;
      color:currentColor;
      text-decoration: none;
      .eva-word-break;
      &:hover {
        color:currentColor;
        text-decoration: none;
      }
  }
  
  .manual-queues-row--empty {
    td:nth-child(2), td:nth-child(3) {
      color: @gray-2;
    }
    &:hover {
      td:nth-child(2), td:nth-child(3) {
        color: @brand-third
      }
    }
  }
  
  
  .delete-icon {
    .implioicons-cross();
    .filters-lists-table-button();
  }
  
  .edit-icon {
    .implioicons-edit();
    .filters-lists-table-button();
  }

.error-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}