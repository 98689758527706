@job-title-text-padding-right: 0px;
.eva-job-title {
  margin-bottom: 20px;
  min-height: 55px;

  .eva-text-view--disabled {
    color: @gray-mike;
    border: 1px solid transparent;
  }

  .eva-text-view:not(.eva-text-view--disabled) {
    background-color: @gray-1;
    border: 1px solid @gray-11;
    cursor: text;
  }

  span {
    .eva-word-break;
    overflow: auto;
    font-weight: 600;
  }

  textarea{
    font-weight: 600;
    max-height: 500px;
    border-color: @gray-mike!important;
  }

  > div {
    width: 100%;
  }
}

.eva-job-title {

  .eva-text-view {
    padding: 9px @job-title-text-padding-right 9px 9px;
  }

  .eva-textbox--edit {
    position: relative;

    textarea, input {
      padding-right: @job-title-text-padding-right;
    }

    .eva-confirm-revert-buttons-container {
      position: absolute;
      right: 0px;
    }
  }
}

.eva-job-title {
  .eva-confirm-revert-buttons-container {
    bottom: 0;
    line-height: 24px;
    margin-bottom: 9px;
    margin-right: 5px;
    padding-left: 5px;

    i {
      height: 24px;
      width: 24px;

      &:first-child {
        margin-right: 2px;
      }
    }
  }
}

.eva-job-text-area {
  .eva-confirm-revert-buttons-container {
    i {
      bottom: 0;
      position: absolute;

      &:first-child {
        bottom: 24px;
      }
    }
  }
}
.eva-job-text-area-title {
  margin-bottom: 0px;
}

.eva-job-title-edit {
  margin-right: 18px
}

