@column-2-width: 356px;
@margin-column-1-2: 16px;
@margin-column-2-3: 32px;
.search-result-item.search-item-layout-list {
  padding: 8px 16px 8px 7px;
  .flex-container;

  div.value {
    max-width: 200px;
    .truncated();
    display: inline-block;
    vertical-align: bottom;
  }

  .eva-search-view-info-column-left, .eva-search-view-info-column-right {
    width: 280px;
  }
  
  .eva-search-view-info-column-left {
    margin-right: 16px;
  }

  &.no-media {
    .search-result-item-column-1 {
      display: none;
    }

    .search-result-item-column-2 {
      margin-left: 9px;
    }
  }

}


.search-result-item-column-1 {
  position: relative;
  border: solid 1px @gray-base;
  margin-right: @margin-column-1-2;
  .media-item-container {
    width: 100%;
    height: 100%;
    .media-item-content-container {
      width: 100%;
      height: 100%;
      position: relative;
      .media-item {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.search-result-item-column-2 {
  margin-right: @margin-column-2-3;
  flex-grow: 1;
  min-width: 0;
}

.eva-search-list-view-notice-info-container {
  .flex-container;
  flex-direction: column;
  width: 576px;
}

.eva-search-list-view-info{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.search-result-item-status-indicator {
  width: 2px;
  min-width: 2px;
  left: 0px;
  position: absolute;
  height: calc(100% - 8px);
  top: 4px;
  left: 0px;
}

.eva-search-list-view-status {
  margin-right: 8px;
}

.eva-search-list-view-time-ago {
  margin-right: 8px;
  flex: 0 0 auto; /* Prevent these items from growing or shrinking */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.eva-search-list-view-time-entered {
  color: @gray-2;
  flex: 0 0 auto; /* Prevent these items from growing or shrinking */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.eva-search-list-view-status, .eva-search-list-view-time-ago, .eva-search-list-view-time-entered {
  font-size: 12px;	
  font-weight: 600;	
  line-height: 20px;
}


.eva-search-view-title {
  margin-top: 8px;
  font-size: 18px;
  line-height: 24px;
  .truncated();
  max-width: 100%;
  display: inline-block;
  .eva-link-primary();
}

.eva-search-view-label {
  width: 95px;
  margin-right: 6px;
  color: @gray-2;
  flex-shrink: 0;
}

.eva-search-view-link {
  .eva-link-primary();
}

.eva-search-view-label, .eva-search-view-link, .eva-search-view-value {
  display: inline-block;
  line-height: 19px;
  vertical-align: bottom;
  .truncated();
}

.eva-search-view-link, .eva-search-view-value {
  margin-right: 5px;
}

.eva-search-list-view-info-item {
  .flex-container;
  flex-direction: row;
  align-items: center;
  height: 24px;
  position: relative;

  &:hover {
    background-color: @gray-1;
  }

  &.has-mouse {
    background-color: @gray-1;
    .eva-search-info__icon-container {
      // by default, the display is none and the icons are hidden
      display: flex;
    }
  }

}

.eva-search-view-image-count {
  position: absolute;
  bottom: 1px;
  right: 1px;
  height: 20px;
  border-radius: 12px;
  border: 2px solid @gray-3;
  width: 28px;
  text-align: center;
  background: white;
  font-size: 12px;
  z-index: 1;
  &.eva-search-view-image-count-highlight {
    border-color: @eva-bounding-box-color;
  }
}

.search-layout-list__video-container {
  width: 100%;
  height: 100%;
  .search__sized__play-button {
    width: 40px;
    height: 40px;
    img {
      width: 20px;
    }
  }
}

.search-layout__video-overlay { 
  // disablePictureInPicture is not supported in Firefox so far
  // (See https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement/disablePictureInPicture)
  // thus having to add an overlay to hide Firefox default "picture on picture" icon
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-color: white;
  opacity: .00001;
  top: -2px;
  left: -2px;
}