.job-version-info {
    height: 40px;
    width: 100%;
    line-height: 40px;
    padding: 0px 30px;
    background-color: @table-bg-accent;
    float: left;
    cursor: default;
    border: 1px solid @gray-eva-light;
    box-shadow: 0 1px 0 0 @gray-eva-light;
    .history-info {
      color: @gray-darker;
      font-weight: 500;
    }
  }
  
  .job-version-info-link {
    float: right;
    .eva-link-primary(@gray-mike, @gray-darker);
  
    &:hover {
      .job-version-info-link-icon {
        color: @gray-darker;
      }
    }
  }
  .job-version-info-link-icon {
    position: relative;
    margin: 0 9px 0 19px;
    &:before {
      .absolute-center();
    }
  }