@item-history-tab-height: 48px;
.modal-content .rules-and-history-modal {
	background: @gray-1;
	width: 780px;
	padding: 22px 16px 16px 16px;
}

.text-that-does-not-change-width-when-bold {
	&:after {
		display: block;
		content: attr(data-content);
		font-weight: bold;
		visibility: hidden;
		height: 1px;
	  }
}

.rules-and-history-modal__tab-container {
	.flex-container();
	width: 100%;
}

.rules-and-history-modal__close-btn {
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	font-size: 24px;
	text-align: center;
	width: 48px;
	height: 48px;
	cursor: pointer;
	&:before {
		color: @black;
	}
	&:hover {
		background-color: @gray-3;
	}
	&:active {
		background: @gray-2;
	}
}

.rules-and-history-modal__count {
	color: @white;
	background-color: @gray-darker;
	padding: 0px 9px;
	border-radius: 9px;
	margin: 0 16px 0 32px;
	line-height: 18px;
}

.rules-and-history-modal__title {
	color: @black;
	font-weight: normal;
	letter-spacing: 0;
	margin: 0 36px 27px 0;
}

.item-history-tab {
	height: @item-history-tab-height;
	background-color: @white;
	display: flex;
	align-items: center;
	border-bottom: 3px solid @gray-41;
	padding-left: 16px;
	margin: 0 2px 0 0;
	cursor: pointer;
	&:hover, &.tab-is-active {
		font-weight: bold;
		border-color: @brand-third;
		.rules-and-history-modal__count {
			font-weight: normal;
			background-color: @brand-third;
		}
	}
}