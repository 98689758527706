.empty-ng-table {

  .has-no-data, .has-no-results, .has-error {
    margin: 74px auto 0;
    max-width: 40%;
    text-align: center;

    .link {
      .h4();
      color: @brand-primary;
      text-transform: uppercase;
      font-weight: @font-weight-bold;
      &:hover {
        color: @brand-primary-dark;
      }
    }
  }

}

