
// show-icons className is used instead of Angular service eva-has-mouse but it does not include the rewrite
// element.find('.eva-single-select-with-search__input').focus(); and $('.eva-keyboard-focused').closeDropDown(); which are not clear
.show-icons{
        &:hover{
            background-color: @gray-1;
            .eva-search-info__icon-container {
              // by default, the display is none and the icons are hidden
              display: flex;
            }
        }
}

.cursor-pointer{
  cursor: pointer;
}


.active-generated-notices{
  background-color: @generated-notice-color-active;
  opacity: @generated-notice-transparency-active;
}

.eva-generated-notice-hover{
    &:hover{
       cursor: pointer;
       .active-generated-notices;
    }
}

@search-page-grid-margin-padding: 10px;
.search-item-list {

  .search-item-list-style, .search-item-grid-style {
    position: relative;
    .search-result-item-column-1 {
      .eva-search-transition;
    }

    .eva-notice {
      height: 24px;
      margin: 0 2px 2px 0px;
      padding: 0 0 0 2px;
    }
    .notice-message {
      font-size: 12px;
      line-height: 16px;
    }
    .notice-container {
      padding: 0px;
      gap: 1px;
    }

    .eva-notice-container {
      margin-bottom: 6px;
    }
  }

  &.in-moderation-mode {
    .eva-search-grid-layout-row {
        // margin-left: 32px;
        .eva-search-grid-view-column-1, .eva-search-view-info-column-left {
          width: 362px;
        }
    }

    .search-item-list-style {
      .search-result-item-column-1 {
        margin-left: 24px;
      }

      .search-result-item.search-item-layout-list.no-media {
        .search-result-item-column-2 {
          margin-left: 24px;
        }
      }
    }
  }
}

.search-item-list-checkbox {
  .eva-custom-checkbox(@gray-darker, @white, 16px, @gray-darker);
  margin: 0px;
}

.search-item-container {
  &:first-child {
    border-top: 1px solid @eva-search-border-color;
  }
  background-color: @white;
  border-bottom: 1px solid @eva-search-border-color;
}




.search-item-timestamp-container {
  display: flex;
  position: relative;
  display: flex;
  position: relative;
  width: 100%; /* Ensure the container takes up full width */
  position: relative; /* Needed for positioning the tooltip */
}

.flex-item-status{
  cursor: pointer;
  margin-right: 8px;
  flex: 0 1 auto; /* Allow the first item to fit its content and shrink if necessary */
  .truncated();
  cursor: pointer; /* Indicate that the item is interactive */
  position: static;
}

.flex-item-status .content {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}



