/* Sizes and margins
--------------------------------------------------------*/
@border-size: 3px;
@margin: 20px;
@container-width: 1200px;
@grid-gutter-width: 20px;

@minimum-responsive-screen-width: 1280px;
@screen-sm: 0px; // remove responsible design for small screen
@screen-md: 0px; // remove responsible design for middle-sized screen
@screen-lg-min: 1600px;

// NOTE: These are bootstrap variables and affect breakpoints for medi
@container-desktop: @container-width;
@container-md: @container-width;
@container-large-desktop: 1366px;

@width: 100%;

/* Affects the rounded corners on input/button elements
--------------------------------------------------*/
@border-radius-base: 2px;
@border-radius-small: 2px;
@border-radius-large: 2px;
@padding-base-vertical:     8px;
@padding-base-horizontal:   16px;

/* Start
--------------------------------------------------*/
@start-container-margin-top: 55px;
@start-margin-normal: 30px;
@start-btn-height: @start-margin-normal*2;
@start-container-width: 560px;
@start-form-element-width: 400px;

/* Modals
--------------------------------------------------*/
@modal-border-radius: 0px;
@modal-padding: 20px;

/* Z-index
--------------------------------------------------*/
// Bootstrap navigation bar z-index: 1030
@eva-navbar-primary-z-index: 1030;
@eva-navbar-primary-dropdown-z-index: 10001;
@eva-navbar-searchbox-dropdown-z-index: 10000;
@manual-moderation-progress-bar-z-index: 1030;
@reject-button-z-index: 10002;
@notifier-z-index: calc(@manual-moderation-progress-bar-z-index + 1);
@advanced-select-dropdown-z-index: 1034;


/* animations */
@keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
}