@data-deletion-modal-padding: 31px;
.delete-content-modal {
  height: 490px;
  width: 544px;


  .delete-content-modal-header {
    padding: 28px @data-deletion-modal-padding 15px;

    p:nth-child(1), p:nth-child(2) {
      font-size: 24px;
      font-weight: 300;
      line-height: 25px;
      margin: 0px;
    }
    p:first-child {
      color: @brand-secondary;
    }
    p:nth-child(2) {
      color: @brand-third;
    }

    p:nth-child(3) {
      margin-top: 17px;
    }
  }

  a {
    display: block;
    color: @gray-2;

    &:hover, &:focus {
      color: initial;
    }
    &.confirmed {
      color: @black;
      i {
        border-color: @brand-third;
        background-color: @brand-third;
        color: white;
      }
    }
    i {
      vertical-align: bottom;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      position: relative;
      border: 1px solid @gray-2;
      border-radius: 20px;
      color: @gray-2;

      &:before {
        .absolute-center();
      }
    }
  }
  .delete-content-modal-confirm {
    height: 250px;
    background: @gray;
    padding: @data-deletion-modal-padding;
    border-radius : 0 0 4px 4px;

    img {
      width: 20px;
      height: 20px;
    }

    > h4 {
      margin-top: 0px;
      color: @brand-secondary;
    }

    a {
      line-height: 20px;
      margin-top: 10px;
    }

    .btnWrapper {
      margin-top: 31px;
      button {
        width: 192px;
        height: 50px;
      }
    }
  }
  .eva-secondary-button:hover {
    background-color: white;
  }
}
