.csv-modal{
  margin:18px 20px !important;
  h3.title{
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
  }
  p{
    padding-bottom: 20px;
  }
  &.csv-modal-complete{
    width: 620px;
    h3.title{
      padding-bottom: 10px;
    }

    .csv-notice {
      border: 1px solid;
      display: block;
      font-weight:500;
      margin-bottom: 30px;
      padding: 5px 10px;
    }
    .err-notice{
      .csv-notice;
      border-color: @brand-danger;
      background:lighten(@brand-danger, 30%);
    }

    .success-notice{
      .csv-notice;
      border-color: @green-2;
      background:lighten(@green-2, 30%);
    }
  }
  &.csv-process-modal{
    .icon{
      margin-top:40px;
      margin-bottom: 60px;
      font-size: 4em;
      text-align: center;
      width: 100%;
    }
    .progress {
      margin-top: 15px;
      .active {
        text-decoration: none;
      }
    }
    p {
      margin-bottom: 10px;
      padding-bottom: 0px;
    }
    .csv-process-modal-accepted {
      color: @brand-success;
    }
    .csv-process-modal-rejected {
      color: @brand-danger;
    }
  }
}


.confirm-modal .csv-modal {
  margin: 20px 20px!important;
}
