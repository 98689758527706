/* Fonts - These are bootstrap overrides
--------------------------------------------------------*/

@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
src: url('../fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2')
}
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
src: url('../fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2')
}
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
src: url('../fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2')
}
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
src: url('../fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2')
}
@font-face {
font-family: 'Roboto';
font-style: normal;
font-weight: 900;
src: url('../fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2') format('woff2')
}


@font: "roboto", helvetica, arial;
@font-family-sans-serif: "Roboto", sans-serif;
@font-family-base: @font-family-sans-serif;
@font-size-base: 14px;

@font-size-h1:            ceil((@font-size-base * 2.571428571)); // ~36px
@font-size-h2:            ceil((@font-size-base * 1.714285714)); // ~24px
@font-size-h3:            floor((@font-size-base * 1.428571429)); // ~20px
@font-size-h4:            ceil((@font-size-base)); // ~14px
@font-size-h5:            ceil((@font-size-base) * 0.857142857); // ~12px
@font-size-h6:            ceil(@font-size-base);

@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-bold: 700;

@line-height-base: 1.428571429;

@line-height-h1:      42px;
@line-height-h2:      32px;
@line-height-h3:      28px;
@line-height-h4:      20px;
@line-height-h5:      18px;
@line-height-lead:    24px;