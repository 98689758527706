@input-padding: 8px;

input,
textarea,
.form-control {

  border-color: @input-border;
  box-shadow: none;

  &,
  .input-group & {

    padding: @input-padding;

    // element focus
    &:focus,
    &.focus {
      box-shadow: none;
      border-color: @input-border-focus;

      &.ng-valid,
      &.valid {
        border-color: @brand-success;
      }

      &.ng-invalid,
      &.invalid {
        &:not(.ng-pristine) {
          border-color: @brand-danger;
        }
      }

      &,
      &.ng-pristine {
        border-color: @input-border-focus;
      }
    }

    &[disabled] {
      color: @gray-dark;
    }

  }
}

.form-group {

  .help-block {
    display: none;
  }

  &.has-error .help-block {
    display: block;
  }

  .btn {
    margin-right: (@margin/2);
  }

  .input-group-btn .btn {
    margin-right: 0;
    min-width: 70px;
  }


}

.has-error {
  .control-label {
    color: inherit;
  }
}

/* Old Styling for forms begins here. Good luck and have fun! */

label.dropdown {
  &.invalid {
    border-left-color: @red !important;
  }
}

.item,
.modal-content,
.inner {
  label.dropdown {
    color: @color;
    background: @body-bg;
    &:after {
      color: @color;
    }
  }
}

.radio-inline,
.radio-inline + .radio-inline,
.checkbox-inline,
.checkbox-inline + .checkbox-inline {
  position: relative;
  margin-top: 0;
}

.radio-inline,
.checkbox-inline {
  label,
  .radio-label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    vertical-align: top;
  }
}

input[type="checkbox"],
input[type="radio"] {
  ~ .radio-label,
  ~ .checkbox-label {
    background: lighten(@gray, 25%);
    &:hover {
      background: lighten(@gray, 30%);
    }
  }
  &:checked {
    ~ .radio-label,
    ~ .checkbox-label {
      background: @green;
      &:hover {
        background: lighten(@green, 10%);
      }
    }
  }
}

.has-success,
.has-error,
.has-warning {
  .form-control {
    &,
    &:focus {
      box-shadow: none;
    }
  }
}


input {
  &[type="file"] {
    &:focus {
      outline: none;
    }
    &:before {
      &.valid {
        border-left-color: @green;
      }
      &.invalid {
        border-left-color: @red !important;
      }
    }
  }
  &.valid {
    border-left-color: @green;
  }
  &.invalid {
    border-left-color: @red !important;
  }
}

.disabled,
.form-group.disabled {
  opacity: .7;
  &.no-mouse-events {
    &,
    & * {
      pointer-events: none;
    }
  }
}

.no-border {
  border: 0;
  box-shadow: none;
}

.search-section {
  margin-top: 14px;
}

.search-field-focused {
  border: 1px solid  @input-border-focus !important;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 300ms ease;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}


/* Hide the spin-button when inputting numbers, 
*  see https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
*/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
