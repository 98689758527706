.edit-tree-modal {
  width: 500px;
  padding: 22px 16px 16px 16px;


  .tree {
    padding-left: 20px;

    .categoryName {
      cursor: pointer;
      color: #212121;
      font-weight: normal;
      font-size: 1.1em;
      height: 22px;

      &.selected {
        font-weight: bold;
      }
    }
  }

  .edit-value-in-tree__button-group {
    margin-top: 30px;
  }

}
