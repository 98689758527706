@item-history-tab-height: 48px;
.rules-and-history-modal {
	width: 930px;
	padding: 22px 16px 16px 16px;
}

.text-that-does-not-change-width-when-bold {
	&:after {
		display: block;
		content: attr(data-content);
		font-weight: bold;
		visibility: hidden;
		height: 1px;
	  }
}

.rules-and-history-modal__tab-container {
	display: flex;
	width: 100%;
}

.rules-and-history-modal__count {
	color: @white;
	background-color: @gray-darker;
	padding: 0px 9px;
	border-radius: 9px;
	margin: 0 16px 0 32px;
	line-height: 18px;
}

.rules-and-history-modal__title {
	color: @black;
	font-weight: normal;
	letter-spacing: 0;
	margin: 0 36px 27px 0;
}

.item-history-tab {
	height: @item-history-tab-height;
	background-color: @white;
	display: flex;
	align-items: center;
	border-bottom: 3px solid @gray-41;
	padding-left: 16px;
	margin: 0 2px 0 0;
	cursor: pointer;
	&:hover, &.tab-is-active {
		font-weight: bold;
		border-color: @brand-third;
		.rules-and-history-modal__count {
			font-weight: normal;
			background-color: @brand-third;
		}
	}
}
.rules-modal {
	background-color: white;
}
.rules-modal__header {
	#rules-and-history-modal.first-column-padding-left();
	#rules-and-history-modal.header();
}

.rules-modal__no-rule-text {
	#rules-and-history-modal.cell-padding-top-bottom();
	#rules-and-history-modal.first-column-padding-left();
}

.rules-modal__row {
	display: flex;
	position: relative;
	border-top: 1px solid @gray-3;
	&:first-child {
		border: none;
	}
	#rules-and-history-modal.cell-padding-top-bottom();
	#rules-and-history-modal.first-column-padding-left();

	.word-highlight {
		background-color: @yellow-3;
	}
}

.rules-modal__icon,
.rules-modal__details {
	float: left;
}

.rules-modal__details {
	width: 637px;
}

.rules-modal__link-to-rule {
	.eva-link-primary();
	font-weight: @font-weight-medium;
}

.rules-modal__code-mirror {
	margin-top: 8px;
	.code-mirror-blang-mode();
	.CodeMirror, .CodeMirror-gutters, .CodeMirror-focused {
		background-color: @gray-1;
		border: none;
	}
	.CodeMirror {
		height: auto;
	}
}

.expression-toggle-btn {
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	font-size: 24px;
	text-align: center;
	line-height: 48px;
	width: 48px;
	height: 48px;
	cursor: pointer;
	&:before {
		color: @black;
	}

	&:hover {
		background-color: @gray-4;
	}
	&:active {
		background: @gray-2;
	}
}