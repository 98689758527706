@extendable-text-line-height: 20px;
.eva-extendable-text__control-icon-container {
    display: none;
    text-align: center;
}
  
.eva-extendable-text {
    position: relative;
    &:hover {
      cursor: default;
    }
  
    &.eva-extendable-text--has-show-more-link {
      &:hover {
        background-color: @gray-1;
        cursor: pointer;
        .eva-extendable-text__read-more-icon, .eva-extendable-text__read-less-icon{
          color: @black;
          &:before {
            transform: translate(-50%, -30%);
          }
        }
      }
  
      .eva-extendable-text__control-icon-container {
        display: block;
      }
    }
}
.eva-extendable-text__hidden-content {
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    width: 100%;
}

.eva-extendable-text__read-more-icon, .eva-extendable-text__read-less-icon {
    padding: 0 20px;
    color: @gray-2;
    font-size: 18px;
    display: inline-block;
    height: 18px;
    width: 18px;
    position: relative;
    &:before {
      transition: all 0.2s;
      .absolute-center();
    }
}
  
.text-clamp(@number-of-lines) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: @number-of-lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eva-extendable-text__content {
    margin: 5px 0 13px;
    line-height: @extendable-text-line-height;
    font-weight: normal;
    .text-clamp(3);
    &.is-body-extended {
      display: block;
      overflow: visible;
    }
}