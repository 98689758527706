.arrow(@color) {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border-right: 0.2em solid @color;
  border-top: 0.2em solid @color;
  margin-right: 0.5em;
}

.arrow-right(@color: white) {
  .arrow(@color);
  transform: rotate(45deg);
}
.arrow-left(@color: white) {
  .arrow(@color);
  transform: rotate(225deg);
}

.cross-icon(@size, @color, @thickness) {
  width: @size;
  height: @size;
  transform: rotate(45deg);
  cursor: pointer;

  &:before, &:after {
    content: "";
    position: absolute;
    background: @color;
  }

  &:before {
    left: 50%;
    width: @thickness;
    margin-left: (-@thickness/2);
    height: 100%;
  }

  &:after {
    top: 50%;
    height: @thickness;
    margin-top: (-@thickness/2);
    width: 100%;
  }

}

.implioicons-speech-bubble:before {
  content: "\e03c";
}

.implioicons-phone:before {
  content: "\e055";
}
.implioicons-ip:before {
  content: "\e056";
}
.implioicons-hash:before {
  content: "\e057";
}

.implioicons-mail:before {
  content: "\e01d";
}