@searchbox-max-length: 500px;
@searchbox-min-length: 350px;
@searchbox-text-max-length: 100%;
@searchbox-dropdown-max-height: 300px;
@searchbox-input-height: 23px;
@searchbox-container-width: 550px;
@search-input-width: @container-width;

  .searchbox {

    position: relative;
    display: inline-block;
    font-family: @font-family-sans-serif;

    &.is-input-focused .angucomplete-holder:before{
      opacity: 1;
    }

    .angucomplete-holder {
      position: relative;
      width: @searchbox-container-width;
      height: @searchbox-input-height;
      padding-right: (@grid-gutter-width/2);
      .flex-container;
      flex-direction: row;
      justify-content: flex-end;

      .implioicons-search();

      &:before {
        display: inline-block;
        margin-right: 3px;
        opacity: 0.75;
        font-size: 27px;
        transition: all 0.5s;
      }

      input {
        position: relative;
        float: right;
        height: @searchbox-input-height;
        border: none;
        border-bottom: 1px solid @white;
        opacity: 0.75;
        width: @searchbox-min-length;
        padding: 0 0 2px 0;
        transition: all 0.5s;
        background: transparent;
        color: white;
        &:focus {
          width: @searchbox-max-length;
          opacity: 1;
        }
      }

      &.angucomplete-dropdown-visible {

        input {
          width: @searchbox-max-length;
        }
      }

      .angucomplete-dropdown {
        opacity: 1;
        visibility: visible;
        max-height: @searchbox-dropdown-max-height;
        transition: all 0.25s;
        position: absolute;
        width: @search-input-width;
        left: calc(@searchbox-container-width - @search-input-width);
        top: 42px;
        z-index: @eva-navbar-searchbox-dropdown-z-index!important;
        overflow-y: auto;
        box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.3);

        &.ng-hide:not(.ng-hide-animate) {
          display: block!important;
          opacity: 0;
          visibility: hidden;
          max-height: 0;
        }

        .angucomplete-row {
          background: @white;
          color: @gray-darker;
          border-width: 0 1px 1px 1px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.15);
          padding: 15px 50px 0;
          display: block;
          height: 50px;
          &:hover {
            background: @gray;
            cursor: pointer;
          }
          .angucomplete-title{
            max-width: @searchbox-text-max-length;
            .truncated;
          }
        }

        .angucomplete-selected-row {
          background-color: @gray-33 !important;
        }
      }
    }

    a{
      line-height: 60px;
      &.hide-link {
        display: none;
        transition: all .5s;
      }
    }

    .highlight{
      font-weight: 800;
    }

  }



@media (min-width: @screen-lg-min) {
    .searchbox .angucomplete-holder .angucomplete-dropdown {
      width: @container-large-desktop;
      left:calc(@searchbox-container-width - @container-large-desktop);
    }
}
  