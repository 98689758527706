.quick-add-to-list {
    position: relative;

    .eva-single-select-with-search {
        position: absolute;
        top: 22px;
        right: -2px;
        padding-top:4px;
        color: black;
    }

}

.quick-add-to-list__icon {
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    .icon-style-1();
    &:before {
        .absolute-center;
    }
    &:hover {
        .icon-style-1-hover-focus();
    }
}

.quick-add-to-list--open {
    .quick-add-to-list__icon {
        background: white;
        color: @black;
    }
}
