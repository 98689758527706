.video-player-wrapper {
    width: 100%;
    height: 100%;
}

.main-video {
    position: relative;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    &.is-deleted {
      opacity: 0.2;
    }  
}
  
.main-video-container {
    position: relative;
    width: 100%;
    height: 100%;
}
  
.main-video__action-container {
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
