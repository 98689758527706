@eva-loading-icon-size: 20px;
.eva-loading-indicator {
  width: @eva-loading-icon-size;
  height: @eva-loading-icon-size;
  display: inline-block;
  div {
    font-size: 20px;
    line-height: 38px;
    &.implioicons-check {
      color: @lime-green;
    }
    &.implioicons-warning {
      color: @brand-danger;
    }
  }
}
