@empty-statistics-container-width: 448px;
.empty-statistics {
  width: 1204px;
  height: 600px;
  background-image: url("../../../../../assets/overview_empty_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  overflow: auto;
  img {
    margin-top: 21px;
    width: @empty-statistics-container-width;
    height: auto;
  }
}
