.application-error-content {
  min-width: 300px;

  .alert-implio {

    margin-top: 220px;

    a {
      text-transform: uppercase;
    }

  }
}
