.empty-ng-table {

  .has-no-data, .has-no-results, .has-error {
    margin: 0 auto;
    margin-top: 64px;
    max-width: 40%;
    text-align: center;

    .link {
      .h4();
      text-transform: uppercase;
      font-weight: @font-weight-bold;
      .eva-link-primary(@brand-primary, @brand-primary);
    }
  }

}

