.confirm-modal {
  margin: 18px 20px !important;
  h3.title {
    font-size: 20px;
    line-height: 24px;
    font-weight: normal;
    padding-bottom: 10px;
  }
  p {
    padding-bottom: 20px;
  }
}
.confirm-modal__button-group {
  display: flex;
  justify-content: flex-end;
}