.date-picker-icon {
  color: @color;
}

.bootstrap-datetimepicker-widget {
  table {
    th, td {
      border-radius: 0px;
    }

    td.disabled, td.disabled:hover, td span.disabled, td span.disabled:hover {
      color: @gray-21;
    }

  }

  &.dropdown-menu.bottom:after {
    border-bottom: 6px solid @brand-primary;
    top: -2px;
  }
}


.eva-date-picker {
  &.input-group {
    width: 200px;
  }

  &.eva-react-date-picker {
    width: 218px;
    border: 1px solid #d8dee2;
    border-radius: 2px;
    padding: 0 8px;
    background-color: white;

    .datetimepicker-input {
      border: none;

    }


    .tempus-dominus-widget {
      width: 26rem;
      z-index: 5;
      inset: 5px auto auto -8px !important;

      &[data-popper-placement="top-start"] {
        margin-top: 75px !important;
      }

      .date-container-days {
        grid-auto-rows: 25px;
      }

      .date-container-days div:not(.no-highlight).today:before {
        bottom: 3px;
      }
    }

    .open-icon {
      cursor: pointer;
    }
  }

  .input-group-addon {
    background: @white;
    border: none;
    padding: 8px;
  }

}

.date-range-picker {
  display: flex;
  margin: 0 0 20px;

  .date-picker-line {
    line-height: 40px;
    height: 40px;
    margin: 0 5px;

    &.timezone {
      margin-left: 16px;
      padding-top: 2px;
      i{
        vertical-align: middle;
      }
    }
  }
}


.eva-date-picker-calendar-icon {
  .implioicons-calendar();
  font-size: 1.2em;
}

.overview-date-pickers {
  height: 38px;
  margin: 0 0 20px 0;

  .items-date-picker {
    ::placeholder {
      color: @brand-danger;
    }
  }
}
