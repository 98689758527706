.delete-user-data {
  .settings-page-title{
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
    margin-bottom: 30px;
  }
  label {
    display: block;
    margin-top: 30px;
  }
  input {
    width: 500px;
    display: block;
    border: none;
    border-bottom: 1px solid @gray-2;
    color: @gray-2;
    font-size: 16px;
    line-height: 19px;
    outline: 0 !important;
    padding: 0 8px 8px 0;
  }

  .main-text {
    white-space: pre-wrap;
  }

  .row {
    margin-top: 20px;
  }

}
