.add-button{
    height: 100%;
    margin-left: auto;
}

.add-container{
height: 38px;
}

.custom-add-list-tooltip {
  width: 165px;
  height: 110px;
  top: -115px;
  right: -137px;
  text-align: left;
  padding: 9px;
  background-color: black;
}


.add-item {
    margin-top: @margin;
  
    .form-control.add-word.focus,
    .form-control.add-word:focus {
        border-color: @input-border;
    }
    .form-control{
      width: 520px;
    }
    .comment-input {
      margin-left: 16px;
      border-color:@gray-eva-light;
    }
    .table {
      table-layout: fixed;
  
      td {
        word-wrap: break-word;
      }
    }
  }
  
  .button-row {
    margin-bottom: 3em;
  }
  
  .dictionary-item-editor__input-button-group {
    display: flex;
    
  }
  
  .regex-item {
    color: @purple-2;
  }
  
  .tooltip-icon {
    margin-left: 4px;
  }


  .item_description--icon {
    font-size: 16px;
    margin-left: 4px;
    .implioicons-speech-bubble();
    .eva-tooltip-container--left();
    .eva-tooltip {
      top: -6px;
      max-width: 250px;
      left: -5px;
      &:before {
        left: 5px;
      }
    }
  }