
.notification-error {
  background-color: fade(@brand-danger, 15%);
  border: 1px solid @brand-danger;
  border-radius: 3px;
  padding: .5em;
  margin: 1em 0;
}

.notification-text {
}

.notification-title {
  font-weight: bold;
}
