.info-modal {
  width: 620px;
  margin: 18px 20px !important;

  p {
    line-height: 26px;
    padding-bottom: 20px;
    margin: 0px;
  }

  span.highlight {
    border-radius: 2px;
    background-color: @gray-36;
    border: solid 1px @gray-eva-light;
    color: @red-3;
    padding: 2px;
  }
}

.info-modal.search-info-modal {
  p {
    padding-bottom: 10px;
  }
  ul {
    list-style-type: disc;
    li {
      margin-left: 35px;
    }
  }
}

