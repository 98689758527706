.stacked-chart-container {

  @color-received: #1E90FF;
  @color-totalDecisions: #5a0077;
  @color-rejectedManual: #ffa5a4;
  @color-rejectedAuto: #ff4d4c;
  @color-acceptedManual: #7fe0a6;
  @color-acceptedAuto: #459d68;
  @color-noDecisionAuto: #606060;
  @color-noDecisionManual: #999999;

  position: relative;
  padding-top:50px;
  padding-bottom: 50px;


  form {
    font-family: Roboto, sans-serif;;
    left: 10px;
    top: 10px;
    margin: 0 20px 12px 16px;

    input {
      position: absolute;
      top: -9999px;
      left: -9999px;
      visibility: hidden;
      /* For mobile, it's typically better to position checkbox on top of clickable
         area and turn opacity to 0 instead. */
    }
    .btn-group{
      border-radius: 2px;
      border: solid 1px @gray-eva-light;
    }

    label.radioBtn {
      display: inline-block;
      cursor: pointer;
      text-align: center;
      float: left;
      margin-bottom: 0;
      padding: 5px 14px;
      background-color: @white;
      font-weight: 400;

      &:first-child{
        border-right:solid 1px @gray-eva-light;
      }

      &.radio-selected {
        background-color: @gray-base;
      }

      &.radio-disabled {
        opacity: 0.1;
        cursor: not-allowed;
      }
    }

    label.legend {
      color: @gray-darker;
      font-weight: normal;
      margin-top: 10px;
      cursor: pointer;
    }

    label.legend:before {
      content: " ";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      margin-bottom: 2px;
      border-radius: 2px;
      cursor: pointer;
      vertical-align: middle;
      border: 2px solid;
    }

    label.legend.legend--received:before {
      border-color: @color-received;
    }

    label.legend.legend--received.legend--select:before {
      background-color: @color-received;
    }
    
    .label.legend.legend--totalDecisions:before {
      border-color: @color-totalDecisions;
    }
    label.legend.legend--totalDecisions.legend--select:before {
      background-color: @color-totalDecisions;
    }

    label.legend.legend--rejectedManual:before {
      border-color: @color-rejectedManual;
    }
    label.legend.legend--rejectedManual.legend--select:before {
      background-color: @color-rejectedManual;
    }

    label.legend.legend--rejectedAuto:before {
      border-color: @color-rejectedAuto;
    }
    label.legend.legend--rejectedAuto.legend--select:before {
      background-color: @color-rejectedAuto;
    }

    label.legend.legend--acceptedManual:before {
      border-color: @color-acceptedManual;
    }
    label.legend.legend--acceptedManual.legend--select:before {
      background-color: @color-acceptedManual;
    }

    label.legend.legend--acceptedAuto:before {
      border-color: @color-acceptedAuto;
    }
    label.legend.legend--acceptedAuto.legend--select:before {
      background-color: @color-acceptedAuto;
    }

    label.legend.legend--noDecisionAuto:before {
      border-color: @color-noDecisionAuto;
    }
    label.legend.legend--noDecisionAuto.legend--select:before {
      background-color: @color-noDecisionAuto;
    }

    label.legend.legend--noDecisionManual:before {
      border-color: @color-noDecisionManual;
    }
    label.legend.legend--noDecisionManual.legend--select:before {
      background-color: @color-noDecisionManual;
    }
  }

  svg#overview-graph {
    .highlighted{
      opacity: 0.5;
    }

    .line {
      fill: none;
      stroke: @white;
      stroke-width: 2px;
    }

    .grid line {
      stroke: @white;
      stroke-opacity: 0.2;
      shape-rendering: crispEdges;
    }

    .grid path {
      display: none;
    }

    .axis--x text,
    .axis--y text{
      fill: @gray-mike;
      font-size: 12px;
    }

    .axis--x line,
    .axis--y line,
    .axis--x path,
    .axis--y path{
      stroke: @gray-eva-light;
    }
  }

  .tooltip {
    position: absolute;
    font-size: 13px;
    white-space: nowrap;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.20);
    background-color: @white;
    pointer-events: none;
    border-radius: 2px;

    &.fadeOut{
      opacity:0;
      width:0;
      height:0;
      transition: width 0.2s 0.2s, height 0.2s 0.2s, opacity 0.2s;
    }
    &.fadeIn{
        opacity:1;
        transition: width 0.2s, height 0.2s, opacity 0.2s 0.2s;
    }

    h4{
      margin-top: 5px;
      text-transform: uppercase;
    }
  }

  .tooltip-wrapper {
    position: relative;
    padding: 10px;
  }

  .tooltip-table {
    text-align: left;
    td{
      padding-bottom: 5px;
    }
  }
  .color-totalDecisions {
    color:@color-totalDecisions;
  }
  .color-rejectedManual {
    color:@color-rejectedManual;
  }
  .color-rejectedAuto {
    color:@color-rejectedAuto;
  }
  .color-acceptedManual {
    color:@color-acceptedManual;
  }
  .color-acceptedAuto {
    color:@color-acceptedAuto;
  }
  .color-noDecisionAuto {
    color:@color-noDecisionAuto;
  }
  .color-noDecisionManual {
    color:@color-noDecisionManual;
  }
  td:not(:first-child){
    padding-left: 20px;
  }

  td.tooltip-percentage{
    color: @gray-dark;
  }


}


.overview-graph-container {
  flex-grow: 1;
}
