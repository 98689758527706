.api-integration-settings {
    max-width: 500px;
}

.api-integration-settings__item {
    margin-bottom: 35px;
}

.api-integration-settings__item__container {
    height: 38px;
    width: 100%;
    display: flex;
}
.api-integration-settings__item__input {
    .eva-input--full();
    height: 100%;
    flex-grow: 1;
}

.btn{
    height: 100%;
}

