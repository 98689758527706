/* The Modal (background) */
.custom-modal {
  position: fixed; /* Stay in place */
  z-index: 1031; /* Sit on top */
  padding-top: 20px; /* Location of the box */
  padding-bottom: 10px ;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}

/* Modal Content */
.custom-modal-content {
  .implioicons-close {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    font-size: 24px;
    text-align: center;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }
  position: relative;
  margin: auto;
  padding: 0;
  width: fit-content;    
  max-width: 80%;
  background: @gray-1;
  border-radius: 4px;
  -webkit-animation-name: animate;
  -webkit-animation-duration: 0.4s;
  animation-name: animate;
  animation-duration: 0.4s
}
/* Add Animation */
@-webkit-keyframes animate {
  from { opacity:0} 
  to { opacity:1}
}

@keyframes animate {
  from { opacity:0}
  to { opacity:1}
}
