.eva-link-primary(@defaultColor: @link-color, @hoverColor: @link-color) {
    color: @defaultColor;
    text-decoration: none;
    cursor: pointer;
    &:hover, &:focus, &:active {
      color: @hoverColor;
      text-decoration: underline;
      cursor: pointer;
    }
    &[ng-href=""] {
      color: @gray-darker;
      text-decoration: none;
      cursor: default;
    }
    &[disabled="disabled"] {
      color: @gray-mike;
      cursor: default;
    }
  }
  
  .eva-link-primary {
    .eva-link-primary();
  }

  .eva-link-secondary(@defaultColor: @link-color, @hoverColor: @link-color) {
    .eva-link-primary(@defaultColor, @hoverColor);
    text-decoration: underline;
  }

  .eva-link-secondary {
    .eva-link-secondary();
  }

  .eva-link-third(@defaultColor: @link-color) {
    .eva-link-primary(@defaultColor, @defaultColor);
    text-decoration: none;
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }

  .eva-link-third {
    .eva-link-third();
  }

  .eva-link-forth(@defaultColor: @link-color, @hoverColor: @brand-primary-dark) {
    .eva-link-primary(@defaultColor, @hoverColor);
    text-decoration: none;
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }

  .eva-link-forth {
    .eva-link-forth();
  }