// this is being override by  @header-height: 38px; in tabs.less
@header-height: 38px;
@header-items-margin-left: 20px;
@items-margin: 20px;
@eva-search-border-color: @mid-gray;
@eva-search-font-icon-size: 16px;

.app-assignment-search {
  background-color: @gray-1;

  .eva-pagination li a {
    height: @header-height;
    width: @header-height;
  }

  .eva-pagination-arrow-icons {
    &:before {
      .absolute-center;
      font-size: @eva-search-font-icon-size;
    }
  }

  .eva-drop-down-with-search {

    .drop-down-menu-title,
    .eva-dropdown-arrow,
    .eva-dropdown-arrow-cover,
    .eva-dropdown-hover-area {
      display: none;
    }

    .dropdown-menu-input-container {
      order: -1;
      border: none !important;
      background-color: @gray-3;
      border-radius: 0;
      margin: 0px;
      width: 100%;
    }

    .dropdown-menu-input-container-icon {
      color: @gray-2;
    }

    input.search-input {
      border: none !important;
      background-color: @gray-3;
      border-radius: 0px;
      margin: 0px;
      width: 100%;
      background-position: 16px 9px;
      background-size: 14px;
      padding-left: 8px;
    }

    .dropdown-menu .eva-dropdown-container>li a {
      height: 41px;
      line-height: 41px;
    }
  }

  .quick-add-to-list__dropdown {
    &.select2-drop {
      margin-top: 5px;
    }

    &.select2-drop-above {
      margin-top: -5px;
    }
  }
}

.eva-search-border {
  border: 1px solid @eva-search-border-color;
}

.eva-search-border-right {
  border-right: 1px solid @eva-search-border-color;
}

.eva-search-border-left {
  border-left: 1px solid @eva-search-border-color;
}

.eva-search-control-icon-left {
  .eva-search-border-right;
}

.eva-search-control-icon.eva-search-control-icon-refresh {
  float: right;
  .eva-search-border-left();

  &:before {
    transform: translate(-50%, -50%) scaleX(-1);
  }
}

.eva-search-control-icon {
  width: @header-height;
  height: @header-height;
  display: inline-block;
  font-size: @eva-search-font-icon-size;
  position: relative;

  &:before {
    .absolute-center;
  }

  color: @black;

  &.in-moderation-mode {
    color: @black;
  }

  &:hover {
    color: @black;
    background-color: @gray-1;
  }

  &.disabled {
    cursor: default;
    color: @gray-3;
  }
}

.job-search {

  padding-top: 20px;

  &.submitting-decisions {
    .eva-list-loading-indicator {
      display: block !important;
    }

    .eva-list-has-data {
      opacity: 0.5;
    }

    .pagination li {
      a {
        pointer-events: none;
        cursor: default;
        background-color: @white;
        color: @gray-3;
        opacity: 1;
        filter: none;
      }
    }
  }

  h3 {
    margin-top: 0
  }

  .end-buttons-container {
    display: inline-flex;
    gap: 3px;
    
    .tab {
      width: 38px !important;
      margin-right: 0;
      border-radius: 10px;
      display: block;
      color: black;

      &:hover:not(.disabled), &.in-moderation-mode:not(.disabled) {
        background-color: @white;
      }

      &.disabled {
        cursor: not-allowed;
        background-color: @gray-3;
      }
    }

    :nth-child(2) {
      border-left: none;
    }

    .filter-button {
      float: left;
      width: @header-height;
      align-items: center;
      justify-content: center;

      img {
        width: @eva-search-font-icon-size;
      }
    }
  }

  .view-icon-wrapper {
    height: @header-height;
    width: @header-height;
    color: @gray-eva-light;
    cursor: pointer;

    &:hover {
      background-color: @gray-1;
    }

    .eva-search-border-right;

    i {
      display: inline-block;
      width: 100%;
      height: 100%;
      position: relative;

      &:before {
        font-size: @eva-search-font-icon-size;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .view-icon-wrapper.view-icon-wrapper--selected {
    color: @gray-darker;
  }

  .eva-search-header {
    background-color: @white;
    height: @header-height;

    box-sizing: content-box;
    border-top: 1px solid @eva-search-border-color;
    border-bottom: 1px solid @eva-search-border-color;

    .count-tips-container,
    .eva-select-list,
    .view-types-buttons,
    .eva-search-control-icon-left {
      float: left;
    }

    .eva-pagination {
      float: right;
    }

    .count-tips-container {
      .eva-search-border-right;

      span {
        padding: 0 16px;
      }
    }

    .eva-select-list {
      width: 166px;
      height: auto;
      .eva-search-border-right;

      .btn-select {
        height: @header-height;
        border: none;
      }

      .dropdown-menu {
        margin: 1px 0 0 -1px;
        width: 100%;
        box-sizing: content-box;
        border-color: @gray-3;
      }
    }

    .view-types-buttons {
      font-size: @eva-search-font-icon-size;
    }

    .eva-pagination {
      li:first-child a {
        margin-right: 1px;
      }

      li:last-child a {
        .eva-search-border-right;
      }

      li a {
        border: none;
        .eva-search-border-left;
      }
    }
  }

  .count-tips-container {
    min-width: 300px;
    vertical-align: middle;
    line-height: @header-height;

    .count {
      display: inline-block;
    }

    span {
      vertical-align: middle;
    }
  }

  .eva-list-is-loading {
    opacity: 0.5;
  }

  .eva-search-decision-bar {
    width: 100%;
    .eva-search-transition;
    background: @dark-purple;
    .flex-container;
    flex-direction: row;
    align-items: center;
    .flex-space-between;
    flex-wrap: wrap;
    max-height: 0px;
    visibility: hidden;

    &.in-moderation-mode {
      margin-top: 0px;
      max-height: 48px;
      visibility: visible;
    }

    .eva-mass-action-decision {
      vertical-align: top;
      border-right: 1px solid @brand-secondary;
    }

    span {
      color: @white;
    }
  }
}
@keyframes submit-btn-animation {
  70% {
    box-shadow: 0 0 0 20px rgba(250, 0, 90, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(250, 0, 90, 0);
  }
}


.eva-search-submit-decision-button {
  background: transparent;
  height: 48px;
  width: 48px;
  color: @white;
  box-shadow :0 0 0 0 rgba(90, 0, 119, 0.3);
  border: 2px solid transparent !important;
  &:focus {
    animation: submit-btn-animation 1.5s infinite;
    background: @brand-secondary;
  }

  &:before {
    .absolute-center;
  }

  &:not(.disabled):hover {
    background: @brand-secondary;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

label.eva-search-decision-bar-checkbox {
  font-size: 16px;
  margin: 0 0 0 16px;
  vertical-align: text-bottom;
  .eva-custom-checkbox();

  i {
    .implioicons-check();
  }

  &.some-selected {
    i {
      .implioicons-dash();
      background-color: @white;

      &:before {
        display: block;
        .absolute-center;
      }
    }
  }
}

.eva-search-decision-bar-left {
  height: @header-height;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eva-search-decision-bar-right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.eva-search-decision-bar-text-container {
  display: inline-block;
  height: 100%;
  margin-left: 20px;

  &.has-decision {
    .eva-search-decision-bar-text--item-selected {
      top: 4px;
      opacity: 0.5;
    }

    .eva-search-decision-bar-text--decision {
      top: 23px;
      opacity: 1;
    }
  }
}

.eva-search-decision-bar-text--item-selected {
  position: absolute;
  top: 13px;
}

.eva-search-decision-bar-text--decision {
  position: absolute;
  top: 18px;
  opacity: 0;
  font-weight: 500;
  .implioicons-send-to();
  max-width: 200px;
  .truncated;

  &:before {
    margin-right: 6px;
    vertical-align: text-top;
  }
}

.eva-search-decision-bar-text--item-selected,
.eva-search-decision-bar-text--decision {
  .eva-search-transition;
}


.eva-search-list-has-no-results-image {
  width: 199px;
  display: block;
  margin: auto;
}

.eva-search-list-has-no-results-link {
  .eva-link-primary();
}

.eva-search-list-has-no-results-link-icon {
  font-size: 16px;
  vertical-align: text-bottom;
  margin-left: 10px;
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;

  &:before {
    .absolute-center;
  }
}

.eva-search-list-has-no-results-text {
  font-size: 24px;
  line-height: 32px;
  margin: 25px 0 5px 0;
}

#eva-search-decision-bar-container {
  background-color: @white;
  width: 100%;
}

.eva-search-transition {
  transition: all 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
}

.eva-search-info__value-container {
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.eva-search-info__icon-container {
  .eva-item-hover-show-icons();
}

.eva-search-info__value-container__text {
  .truncated();
}

.eva-search-info__value-container__left {
  display: inline-flex;
  align-items: center;
  min-width: 0;
}

.eva-item-hover-show-icons {
  display: none;
  flex-shrink: 0;
  align-items: center;
  position: absolute;
  top: 1px;
  border-bottom: 1px solid @gray-1;
  right: 0;
  transform: translate(0, -100%);
  width: auto;
  background-color: @black;
  color: white;
  z-index: 1; // make sure tooltips float above other text

  &:empty {
    border: none;
  }

  .quick-add-to-list,
  .eva-external-link,
  .eva-copy-icon {
    .eva-tooltip {
      top: -10px;
    }
  }

  >* {
    border: 1px solid @black;
    cursor: pointer;
  }

  &:last-child {
    border: 2px solid @black;
  }

  &:first-child {
    border: 2px solid @black;
  }
}