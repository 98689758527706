eva-create-invite {


  form, .form-group, .input-group {
    width: 100%;
  }
  .input-group {
    .flex-container;
    input {
      .flex-item;
      display: inline-block;
    }
    .eva-select-list {
      display: inline-block;
      margin-left: 10px;
      width: 190px;
    }
    .eva-primary-button {
      margin-left: 10px;
      display: inline-block;
    }
  }

}
