@search-item-layout-grid-column-margin-full: 16px;
.search-item-layout-grid {
  padding: 8px 0 @search-item-layout-grid-column-margin-full @search-item-layout-grid-column-margin-full;
  position: relative;

  .search-result-item-status-indicator {
    position: absolute;
    height: ~"calc(100% - 8px)";
    margin: 0px;
    top: 4px;
    left: 0px;
  }

  .eva-search-view-info-column-left, .eva-search-view-info-column-right {
    margin-right: @search-item-layout-grid-column-margin-full;
    min-width: 0;
  }
}

.eva-search-grid-layout-row {
  width: 100%;
  display: grid;
}
.search-item-layout-grid--text {
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
}
.eva-search-grid-view-column-1 {
  margin-right: @search-item-layout-grid-column-margin-full;
}

.search-result-images {
  grid-template-columns:  1fr 1fr 1fr;
  margin-top: @search-item-layout-grid-column-margin-full;

  .search-result-media-container {
    position: relative;
    margin-bottom: @search-item-layout-grid-column-margin-full;
    margin-right: @search-item-layout-grid-column-margin-full;
    border: solid 1px @gray-base;
  }

  .media-item-container, .media-item-content-container {
    width: 100%;
    height: 100%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.search__sized__play-button {
  width: 50px;
  height: 50px;
  .absolute-center();
  border-radius: 25px;
  background: white;
  opacity: 0.75;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}