@header-height: 38px;
@header-items-margin-left: 20px;
@items-margin: 20px;
@item-log-status-tab-height: 48px;
@items-log__decision-result-type__item__width: 70px;
@items-log__decision-result-type__item__height: 28px;
@items-log__decision-result-type__border-radius: 10px;

.eva-timezone-icon {
  line-height: @header-height;
  color: @gray-eva-light;
  font-size: 18px;
}

eva-items-list {

has-data ul {
  margin-bottom: 70px!important;
}


.status-tabs-container {
  display: inline-flex;
  margin-top: 10px;
}

.status-tab {
  height: @item-log-status-tab-height;
  background-color: @white;
  display: inline-block;
  border-bottom: 3px solid @gray-41;
  padding: 0 48px 0 16px;
  line-height: @item-log-status-tab-height;
  margin: 0 2px 0 0;
  &:hover {
    font-weight: @font-weight-medium;
    border-color: @gray-2;
  }
  &.is-selected {
    font-weight: bold;
    border-color: @brand-third;
  }
  &:after {
    display: block;
    content: attr(data-content);
    font-weight: bold;
    visibility: hidden;
    height: 1px;
  }
}

#search-input {
  border-width: 0 0 1px 0;
  height: 38px
}
.items-search-input-icon {
  font-size: 18px;
}

.search-header {
    height: @header-height;
    margin-bottom: @items-margin;

    .count {
      height: 40px;
      float: right;
      margin: 0 10px;
      max-width: 550px;
      display: inline-flex;
      align-items: center;
    }
    .count > span{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

.job-search {
  margin-top: 20px;

  h3 {
    margin-top: 0;
  }
  .view-icon-wrapper {
    border: 1px solid @gray-eva-light;
    padding: 8px 12px 0;
    color: @gray-eva-light;
    cursor: pointer;
  }
  .view-icon-wrapper ~ .view-icon-wrapper {
    margin-left: -1px;
  }
  .view-icon-wrapper.view-icon-wrapper--selected {
    color: @gray-dark;
    background: @gray-base;
  }
  .pager, .view-types-buttons {
    margin-left: @header-items-margin-left !important;
  }


  .search-item-list {
    margin-bottom: @items-margin;
  }

  .search-item-container {
    &:first-child {
      border-top: 1px solid @divider;
    }
    border-bottom: 1px solid @divider;
  }

  .eva-list-is-loading {
    opacity: 0.5;
  }

  .has-no-data > no-data {
    div {
      margin-bottom: 50px;
      p {
        margin-top: 15px;
      }
    }
  }
}

}

.input-wrap.items-date-picker {
  &.to {
    border: none;
    margin: 0px;
    padding: 0 5px;
    line-height: 38px;
    background: transparent;
  }

  &.left-date-picker {
    margin-right: 0px;
  }
}

.eva-items-log-refresh-icon {
  background-color: white;
  float: right;
  width: 36px;
  height: 40px;
  border: 1px solid @gray-3;
  font-size: 16px;
  position: relative;
  border-right: none;
  &:before {
    margin-top: 2px;
    .absolute-center();
    transform: translate(-50%, -50%) scaleX(-1);
  }
  cursor: default;
  &:not(.disabled):hover {
    background-color: @gray-1;
    cursor: pointer;
  }
  &.disabled {
    opacity: 1;
    &:before {
      color: @gray-3;
    }
  }
}

.eva-items-log-pagination {
  a {
    width: 36px;
    height: 40px;
  }
}
.eva-items-integrate-text {
  margin-top: 10px;
}

.items-log__decision-result-type-container {
  display: inline-flex;
  flex-direction: row;
  border-radius: calc(@items-log__decision-result-type__border-radius + 2px);
  background-color: @gray-3;
  position: relative;
  border: 2px solid @gray-3;
  align-items: center;
  margin-left: 20px;
}

.items-log__decision-result-type {
  width: @items-log__decision-result-type__item__width;
  height: @items-log__decision-result-type__item__height;
  text-align: center;
  box-sizing: content-box;
  line-height: @items-log__decision-result-type__item__height;
  z-index: 1;
  margin-right: 1px;
}

.items-log__decision-result-type__chip {
  border-radius: @items-log__decision-result-type__border-radius;
  width: @items-log__decision-result-type__item__width;
  height: @items-log__decision-result-type__item__height;
  position: absolute;
  background: white;
  transition: all 0.3s;
}

.items-log__decision-result-type--selected:nth-child(1) ~ .items-log__decision-result-type__chip {
  left: 0%;
}
.items-log__decision-result-type--selected:nth-child(2) ~ .items-log__decision-result-type__chip {
  left: 25%;
}
.items-log__decision-result-type--selected:nth-child(3) ~ .items-log__decision-result-type__chip {
  left: 50%;
}
.items-log__decision-result-type--selected:nth-child(4) ~ .items-log__decision-result-type__chip {
  left: 75%;
}

.job-search .col-md-12 {
  min-height: 0;
}