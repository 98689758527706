.job-group {
  border-radius: 2px;
  background-color: @white;
  cursor: default;
  &.focused {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
    transition: all 0.2s;
  }
  .eva-job {
    border: none;
    margin: 0px;
    width: 100%;
    padding: 15px;
  }
  .older-job-versions {
    width: 100%;
    .eva-job {
      margin: 0px;
    }
  }


  hr.version-divider {
    width:100%;
    height: 4px;
    border-color: @white;
    margin-bottom: 10px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);

    &.last-divider {
      margin:0px;
    }
  }
}
