.checkbox-switch {
  position: relative;
  width: 48px;
  user-select: none;
}

.smooth-transition {
  transition: all 0.2s ease-in 0.2s;
}

.checkbox-switch-checkbox {
  display: none;
}
.checkbox-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 18px;
}
.checkbox-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
}
.checkbox-switch-inner:before, .checkbox-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 18px;
  padding: 0;
  line-height: 18px;
  font-size: 10px;
  color: @white;
  font-weight: bold;
  box-sizing: border-box;
}
.checkbox-switch-inner:before {
  content: attr(data-content-on);
  padding-left: 7px;
  background-color: @brand-third;
  color: @white;
}
.checkbox-switch-inner:after {
  content: attr(data-content-off);
  padding-right: 7px;
  background-color: @gray-dark;
  color: @input-bg;
  text-align: right;
}
.checkbox-switch-switch {
  display: block;
  width: 12px;
  height:12px;
  margin: 5px;
  background: @input-bg;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 26px;
  border: 2px solid @input-bg;
  border-radius: 18px;
}
.checkbox-switch-checkbox:checked + .checkbox-switch-label .checkbox-switch-inner {
  margin-left: 0;
}
.checkbox-switch-checkbox:checked + .checkbox-switch-label .checkbox-switch-switch {
  right: 0;
}
