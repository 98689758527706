.navbar-secondary {
  background-color: @white;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.10);
  min-height: @navbar-secondary-height;
  position: relative;
  margin: 0;

  .sub-nav {

    li a {
      float: left;
      background-color: transparent;
      color: @navbar-secondary-link-color;
      margin: -1px 25px;
      padding: 10px 0;
    }

    li a:first-child {
      margin-left: 0;
    }

    li.active a {
      font-weight: @navbar-secondary-link-active-weight;
      border-bottom: 2px solid @navbar-active-color;
    }

    .active {
      text-decoration: none;
    }

    li a:focus,
    li a:hover {
    }
  }
}
