h1 {
  font-weight: @font-weight-light;
  line-height: @line-height-h1;
}

h2 {
  font-weight: @font-weight-regular;
  line-height: @line-height-h2
}

h3 {
  font-weight: @font-weight-medium;
  line-height: @line-height-h3;
}

p {
  font-weight: @font-weight-regular;
}

.lead {
  font-size: @font-size-h4;
  line-height: @line-height-lead;
  font-weight: @font-weight-regular;
}

.bold {
  font-weight: @font-weight-bold;
}
