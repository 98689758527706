@eva-bounding-box-color: #FDA5B4;
@eva-bounding-box-border-size: 2px;
.eva-bounding-box {
  position: absolute;
  border: @eva-bounding-box-border-size solid @eva-bounding-box-color;
  color: white;
}

.eva-bounding-box-text-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.eva-bounding-box-text {
  display: inline-block;
  vertical-align: top;
  transform: translate(-2px, ~"calc(-100% - 2px)");
  background: #FDA5B4;
  padding: 0 7px;
  font-size: 12px;
  margin-right: 5px;
}