@item-history-height: 48px;
.truncated{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#rules-and-history-modal() {
  .cell-padding-top-bottom {
    padding: 14px 0;
  }
  .first-column-padding-left {
    padding-left: 16px;
  }
  .header {
    height: @item-history-height;
    background: @gray-3;
    font-weight: bold;
    line-height: @item-history-height;;
  }
}
.history-container {
  table {
    table-layout: fixed;
    width: 100%;

    td  {
      #rules-and-history-modal.cell-padding-top-bottom();
      vertical-align: top;
    }
    th:nth-child(1), td:nth-child(1) {
      width: auto;
      #rules-and-history-modal.first-column-padding-left();
    }
    th:nth-child(2), td:nth-child(2) {
      width: auto;
      padding-left: 20px;
    }
    th:nth-child(3), td:nth-child(3) {
      padding-left: 20px;
      padding-right: 16px;
    }
  }
}

.history-header  {  
  #rules-and-history-modal.header();
}

.history__agent--actor, .history__agent--rule-link {
  width: 100%;
  .truncated;
  display: block;
  margin-bottom: 4px;
}

.history__agent--rule-link {
  .eva-link-primary();
}

.history__event-type {
  display: flex;
  align-items: center;
}

.item-event-type-icon {
  flex-shrink: 0;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 29px;
  position: relative;
  vertical-align: middle;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    color: @white;
  }
  &--new {
    .implioicons-moderation-new();
    background-color: @black;
  }
  &--image-fetched {
    .implioicons-moderation-new();
    background-color: @black;
  }
  &--approved, &--APPROVE {
    .implioicons-moderation-approved();
    background-color: @brand-success;
  }
  &--refused, &--REFUSE {
    .implioicons-moderation-refused();
    background-color: @brand-danger;
  }
  &--NOTHING {
    .implioicons-moderation-NOTHING();
    background-color: @mid-gray;
  }
  &--reviewable, &--deferred, &--MANUAL {
    .implioicons-moderation-reviewable();
    &:before {
      left: 56%;
    }
    background-color: @black;
  }
}

.history__event-type__text {
  margin-right: 4px;
  &--error {
    color: #FF4A4A;
  }
}

.history__row {
  background-color: #F3F3F3;
  border-bottom: 1px solid @border;
  height: @item-history-height;
  &:last-child {
    border: none;
  }
}
.history__date__comparison {
  color: @gray-2;
}

.white-background {
  background-color: @white;
}