.rulesTable {
  th:nth-child(1) {
    width: 75px;
  }
  th:nth-child(7),
  th:nth-child(8) {
    width: 45px;
  }
  th:nth-child(3), th:nth-child(4){
    width: 160px;
  }
  > tbody > tr > td {
    vertical-align: middle;
    height: 41px;
  }
  td label {
    margin: 1px 0;
  }
}
@media only screen and (min-width : 768px) {
  .rulesTable {
    th:nth-child(2) {
      width: auto;
    }
  }
}

  
  .rules-lists-table-button {
    &:before {
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .rules-lists-table-button-close {
    .implioicons-cross();
    .rules-lists-table-button();
  }
  
  .rules-lists-table-button-edit {
    .implioicons-edit();
    .rules-lists-table-button();
  }
  
  
  .rules-lists-input-search-icon {
    font-size: 20px;
  }
  
  .automation__table__description--icon {
    font-size: 16px;
    margin-left: 4px;
    .implioicons-speech-bubble();
    .eva-tooltip-container--left();
    .eva-tooltip {
      top: -6px;
      max-width: 250px;
      left: -5px;
      &:before {
        left: 5px;
      }
    }
  }
  
  .automation__table__link {
    display: block;
    width: 100%;
    height: 100%;
    .eva-link-third(@black);
    .eva-word-break;
  
    > .name-display {
      vertical-align: middle;
      display: inline-block;
      max-width: calc(100% - 20px);
  
      &.with_desc {
        max-width: 93%;
      }
    }
  }
  

.error-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}