.info-revision-tooltip{
    width: 200px;
    line-height: 17px;
}

.older-job-versions {
    background-color: @white;
    cursor: default;
  
    .first-row {
      height: 60px;
      border-top: 1px dashed @border;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    // fix padding for older job versions 
    // .eva-job {
    //   padding: 0px;
    // }
  
  }
  
  .eva-old-job-version-button-icon {
    margin: 0 20px 0 20px;
    color: @gray-mike;
    position: relative;
  
    &:before {
      .absolute-center();
    }
  }
  
  .eva-older-job-versions-link {
    .eva-link-primary(@gray-mike, @gray-darker);
    &:hover {
      .eva-old-job-version-button-icon {
        color: @gray-darker;
      }
    }
  }
  
  .job-number-text {
    margin-right: 10px;
    text-decoration: underline;
  }
  