.eva-multi-select-with-search {
  border: 2px solid transparent;
  display: inline-block;
  @multi-select-checkbox-size: 12px;
  @multi-selected-color: @gray-darker;

  &.is-disabled {
    cursor: not-allowed;

    .multi-select-button {
      pointer-events: none;
      background-color: @gray-decision-button;

      .selected-item {
        background-color: @refuse-button-disabled-text-color;
        color: @refuse-button-disabled-selected-text-color;

        i {
          color: @refuse-button-disabled-text-color;
        }
      }
      .placeholder, .caret {
        color: @gray-13;
      }
    }
  }

  &.fit-content {
    display: inline-block;
    min-width: 250px;
    max-width: 1300px;
  }
  .multi-select-button {
    text-align: left;
    width: 100%;
    min-width: 350px;
    max-width: 1200px;
    background: @gray-1;
    height: auto;
    min-height: 40px;
    padding: 7px 25px 0 10px;
    .flex-container;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 42px;

    // &.open {
    //   border: 2px #5a0077 solid;
    // }

    .placeholder {
      font-weight: 500;
      line-height: 26px;
    }

    .caret {
      position: absolute;
      right: 10px;
      margin-left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .placeholder, .caret {
      color: @black;
    }

    .selected-item {
      color: @white;
      height: 26px;
      max-width: 95%;
      background-color: @multi-selected-color;
      border-radius: 13px;
      padding: 0px 5px 0px 13px;
      margin: 0 6px 7px 0;
      .flex-container;
      .flex-align-item-middle;
      cursor: default;

      span {
        max-width: ~"calc(100% - 31px)";
        display: inline-block;
        .truncated;
      }

      i {
        cursor: pointer;
        display: inline-block;
        margin-left: 13px;
        width: 18px;
        height: 18px;
        background-color: white;
        border-radius: 9px;
        font-size: 16px;
        opacity: 0.9;
        position: relative;
        color: @multi-selected-color;
        &:before {
          .absolute-center();
        }
        &:hover {
          opacity: 1;
        }
      }

    }
  }




  .open {
    multiselect {
      .dropdown-menu {
        position: relative;
        display: inline;
      }
    }
  }

  button {
    &.is-disabled {
      pointer-events: none;
    }
  }

  .drop-down-menu-title {
    color: @dark-gray;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.55px;
    line-height: 13px;
    margin: 21px 0 8px 33px;
    text-transform: uppercase;
  }
  ul.dropdown-menu{
    width: 100%;
    z-index: @reject-button-z-index;
    border: 1px solid @gray-8;
    box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.15);
    margin: 0px 0px 2px 0px;
    padding: 0px;
    left: 0;

    .eva-dropdown-container {
      max-height: 275px;
      overflow-y: scroll;
      &.empty-list {
        height: 0px;
      }

      > li {
        height: auto;
        .flex-container;
        .flex-align-item-middle;


        label {
          padding: 0 10px 0 32px;
          width: 100%;
          font-weight: normal;
          margin: 0px;
          position: relative;
          cursor: pointer;
          user-select: none;
        }

        label > span.name {
          padding: 4px 0;
          display: inline-block;
        }

        /* Hide the browser's default checkbox */
        label input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          margin-left: 13px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          height: @multi-select-checkbox-size;
          width: @multi-select-checkbox-size;
          background-color: white;
          border: 1px solid @multi-selected-color;
        }

        /* When the checkbox is checked, add a blue background */
        label input:checked ~ .checkmark {
          background-color: @multi-selected-color;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

          /* Show the checkmark when checked */
        label input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        label .checkmark:after {
          left: 3px;
          top: 1px;
          width: 4px;
          height: 7px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(33deg);
        }

        &:hover {
          background-color: @gray-1;
        }
      }
    }

  }

}


.dropdown-menu-input-container {
  height: 32px;
  margin: 10px 12px 13px;
  border: 1px solid #D6D6D6!important;
  border-radius: 16px;
  width: ~"calc(100% - 24px)";
  .flex-container;
  input.search-input {
    display: inline-block;
    .flex-item;
    height: 32px;
    outline: none!important;
    padding-left: 4px;
    border: none!important;
    background-color: transparent;
  }
  .dropdown-menu-input-container-icon {
    display: inline-block;
    font-size: 22px;
    margin-left: 10px;
    color: @gray-2;
  }
}
