.sidebar {
  font-size: 13px;
  h3 {
    color: @brand-secondary;
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;
  }

  .sub-nav {

    li a {
      background-color: transparent;
      color: @navbar-secondary-link-color;
      padding: 2px 7px;
      margin-left: 5px;
      margin-right: 35px;
      margin-bottom: 5px;
      display: block;
      border-radius: 2px;
      width: fit-content;
    }

    li.active a {
      color: @brand-third;
      font-weight: 400;
    }

    .active {
      text-decoration: none;
    }

    li a:focus,
    li a:hover {
    }
  }
}
