.member-item, .pending-invite-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cfd6db;
    padding: 13px 0px;
}

.member-item__change-role, .pending-invite-item__change-role {
    width: 190px;
    position: relative;
    .eva-promise-status-indicator {
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translate(100%, -50%);
    }
}

.member-item__info__name {
    font-size: 16px;
    font-weight: 500;
}

.member-item__info__email {
    color: #717171;
    font-size: 14px;
}