.eva-job-other-information {
  display: flex;
  flex-direction: column;

  h4 {
    line-height: 25px;
    margin: 0;
    margin-top: 6px;
    margin-bottom: 6.5px;
  }
  .eva-user-previous-ads-count-container {
    display: inline-block;
    margin-left: 12px;
  }


  .eva-job-key-value-item {
    position: relative;

  }

  .matched-rules {
    .more-rules-block {
      line-height: normal;
    }
  }

}

.eva-job-other-information-section {
  .eva-job-key-value-item-container-with-icon, .eva-job-key-value-item {
    &:hover {
      background-color: @gray-1;
    }
  }
}

.eva-job-template-3 {
  .eva-job-links {
    order: -1;
  }
}

.eva-job-other-information__matched-rules {
  flex-grow: 1;
  min-width: 0;

  .more-rules-block {
    line-height: 17px;
    margin-top: 6.5px;
  }
}

.eva-job-other-information__user-id {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: @eva-job-key-value-item-margin-bottom;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.eva-job-other-information__user-id__content-left {
  display: inline-flex;
  justify-content: flex-start;
  flex-grow: 1;
  min-width: 0;
}

.eva-job-other-information__user-id__link {
  .eva-job-key-value-item__value-link();
}


.icon-sized-image { 
  width: 12px; 
  height: 12px;
  margin-bottom: 3px;
  margin-right: 3px;
}