
.eva-tooltip-container {
  position: relative;
  &:hover {
    .eva-tooltip {
      visibility: visible;
    }
  }
}

.eva-tooltip {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
  position: absolute;
  width: max-content;
  color: @gray-3;
  background: #000000;
  line-height: 15px;
  font-size: 12px;
  text-align: center;
  visibility: hidden;
  padding: 8px 9px 8px 8px;
  z-index: 999;
  font-weight: bold;
}

.eva-tooltip:before { // the arrow part
  content: "";
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 8px solid #000000;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.eva-tooltip-container--center {
  .eva-tooltip-container();

  .eva-tooltip {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);

    &:before {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.eva-tooltip-container--left {
  .eva-tooltip-container();

  .eva-tooltip {
    top: -11px;
    left: 0;
    transform: translate(0, -100%);

    &:before {
      top: 100%;
      left: 22px;
    }
  }
}

.eva-tooltip-container--right {
  .eva-tooltip-container();

  .eva-tooltip {
    top: 0;
    right: -2px;
    transform: translate(0, -100%);

    &:before {
      top: 100%;
      right: 5px;
    }
  }
}

.eva-tooltip-icon {
  .implioicons-question-mark-filled;
  color: @brand-primary;
  font-size: 20px;
}
