.settings {
  >.row {
    .sidebar {
      margin-top: 31px;
    }


    .component {
      margin-top: 31px;
      h2 {
        font-size: 30px;
        font-weight: 300;
        line-height: 35px;
      }

    }
  }
}
