.delete-all-content, .data-storing-options, .delete-user-data {

  .right-side {
    margin-left: 30px;
    width: 265px;
  }

  .main-text {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.gdpr__button-group {
  display: flex;
  margin-top: 50px;
}

