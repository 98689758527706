
.btn {
  border-radius: 0px;
  &.btn-primary,
  &.btn-default {
    font-weight: 500;
  }

  &[disabled] {
    opacity: 1;
  }
}

//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn:active,
.btn.active {
  box-shadow: none;
  text-decoration: none;
}

// NOTE: Figure out scenarios for these

.btn-sm,
.btn-group-sm > .btn {
  padding: 2px 8px;
  font-size: 12px !important;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 10px !important;
}

// -----------------------------------------------------------------------
// Ellipse button
// -----------------------------------------------------------------------

button.ellipse {
  height: 40px;
  border-radius: 100px;
  background-color: @white;
  border: solid 2px @gray-eva-light;
  padding: 0 20px;
  min-width: 150px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: @gray-6;
  i {
    padding-right: 6px;
    padding-left: 4px;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: 0.7
  }
}

// -----------------------------------------------------------------------
// Gray button
// -----------------------------------------------------------------------

.btn.btn-default {
  background-color: @gray-base;
  color: @gray-darker;
  border: solid 1px @gray-eva-light;
  &:hover {
    background: @gray-dark;
    border: solid 1px @gray-dark;
  }
}
