.table-cell{
    display: table-cell;
}

.table-container{
    width: 100%;
}

.cursor-pointer{
    cursor: pointer;
}