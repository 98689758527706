/* Root
--------------------------------------------------------*/

html {
  height: 100%;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-y: scroll;
  min-height: 100%;
  min-width: @container-width;
  padding: @navbar-primary-height  0 20px;

  &.has-secondary-menu {
    padding-top: (@navbar-primary-height + @navbar-secondary-height);
  }
}

body.modal-open {
  overflow: hidden;
  margin-right: 15px;
  .navbar {
    margin-right: 15px;
  }
  .process-bar-wrapper {
    right: 15px;
    width: calc(100% - 1px);
  }

}

.application-loading {
  display: none;
}

h2 {
  font-size: 26px;
}

/* Hrefs
--------------------------------------------------------*/
a {
  .firefox-outline();

  cursor: pointer;
  color: @gray-darker;
  text-decoration: none;

  &:hover { text-decoration: none; }
  &:visited { }

  // FF fix
  &:active { outline: none; }
  &.disabled { opacity: 0.4; }
}

a[ng-href=""], a[ng-href=""]:hover  {
  pointer-events: none!important;
}

a.no-access {
  color: @gray-22!important;
}

.warning-text {
  color: @brand-danger;
}

/* Inputs
--------------------------------------------------------*/
.firefox-outline() {
  &::-moz-focus-inner,
  &:focus {
    border: none;
    outline: none;
    -moz-outline-style: none;
  }
  :-moz-any-link:focus {
    outline: none;
  }
}

/* Lists
--------------------------------------------------------*/
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.empty-state {
  .loading-tracker();
}

/* Other general classes
--------------------------------------------------------*/

.width100{
  width:100%;
}
.inner,
.small-box,
.medium-box {
  border-bottom: 1px solid @box-border;
}



.overlay {
  .position(top, left, 0, 0, fixed, true);

  background: rgba(0,0,0,0.6);
  z-index: 1;
}

.tooltip { opacity: 1; }

.vertical-center {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.input-heading {
  padding: 0 5px 0 0;
  margin-top: 0;
  margin-bottom: 0;
}

.tab-content {
  .input-group {
    margin: 5px 0;
  }
  .inner {
    margin-bottom: 0;
    padding: 20px;
    background: @white;
    border-left: 1px solid @box-border;
    border-right: 1px solid @box-border;
  }
  th, td {
    vertical-align: middle;
  }
}

.clickable {
  cursor: pointer;
  padding: 0 4px;
  &.active {
    color: @red;
  }
}


@media @mobile {
  header#header .menu  {
    display: block;
    padding: 5px 0;
  }
}

.warning {
  color: @red;
}

.item {
  border-bottom: 1px solid @border;
  background-color: @body-bg;
  position: relative;
  padding: 15px;
}

.no-fade { transition: none !important; }

.modal-body .btn-group { margin-right: 10px; }



.highlight-filter-match {
  background-color: @yellow-3;
  float: none!important;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 4px 2px -2px rgba(0,0,0,.16);

  li:hover {
    background-color: @gray-1;
  }
}

.dropdown-menu,
.pagination {
  > li,
  > .active {
    a {
      text-shadow: none;
    }
  }
  > li {
    a {
      background-color: @white;
    }
  }
  > .active {
    a {
      &,
      &:hover {
        background-color: @blue;
      }
    }
  }
}


.table > {
  thead,
  tbody,
  tfoot > {
    tr {
      &.success,
      &.warning,
      &.danger {
        & > {
          th,
          td {
            color: @white;
          }
        }
      }
    }
    tr > {
      th,
      td {
        &.success,
        &.warning,
        &.danger {
          color: @white;
        }
      }
    }
  }
}


.pagination {
  > li {
    &,
    &:first-child,
    &:last-child {
      a {
        border-radius: 0;
        color: @blue;
        &:hover {
          color: @blue
        }
      }
    }
  }
  > .active {
    a {
      border-color: lighten(@blue, 10%);
      background: @blue;
      color: @white;
      &:hover {
        background: lighten(@blue, 15%);
        border-color: lighten(@blue, 5%);
        cursor: pointer;
      }
    }
  }
}

.badge {
  background: @blue;
  li.active & {
    background: @color;
  }
}

.error {
  h2 {
    margin: 0;
    padding: 0;
  }
}

.bootstrap-datetimepicker-widget {
  td,
  th {
    border-radius: 0;
  }
}

.page-title {
  margin-top: 4px;
  margin-bottom: 17px;
}

.truncated{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// Default styling for any partial view"s root container
.content {
  padding-top: 22px;
}

//flex-box
.flex-container {
  display: flex;
}
.flex-space-between {
  justify-content: space-between;
}

.flex-column{
  flex-direction: column;
}

.flex-center{
  justify-content:center
}


.flex-align-item-middle{
  align-items: center;
}
.flex-item{
  flex: 1 auto;
}

.flex-item-weight(@weight){
  flex: @weight auto;
}

.flex-align-self-center{
  align-self: center;
}


.remove-selection-color {
  ::selection {
    background: transparent;
  }
  ::-moz-selection {
    background: transparent;
  }
}

// arrow box


.arrow_box(@left; @size: 6px) {
  position: relative;
  background: @white;
  border: 1px solid @border;
  border-radius: 2px;

  &:after, &:before {
    bottom: 100%;
    left: @left;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: @white;
    border-width: @size;
    margin-left: -@size;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: @border;
    border-width: (@size + 1);
    margin-left: (-@size - 1);
  }
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ds-beta {
  padding: 10px;
  font-weight: bold;
  background-color: @yellow-4;
  border: 1px solid @strong-blue;
}

.image-placeholder {
  img {
    opacity: 0.5;
  }
}

.eva-has-loading-jobs-error {
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  text-align: center;
}

.eva-word-break {
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.full-row-clickable{
  a{
    display:block;
    width:100%;
    height:100%;
    color:currentColor;
    text-decoration: none;
    .eva-word-break;
  }
}

.full-row-clickable [ng-click],[data-ng-click] {
  cursor: pointer;
}


.eva-keyboard-focusable {
  border: 2px solid transparent;

  &.eva-keyboard-focused {
    border-color: @brand-secondary!important;
  }
}

.eva-shortcut-icon-mixin(@color) {
  border: 1px solid @color;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: inline-block;
  line-height: 14px;
  color: @color;
}

.page-loading-icon {
  width: 40px;
  height: 40px;
  position: relative;
  &:before {
    .absolute-center;
  }
  font-size: 40px;
  .implioicons-loading();
  color: @gray-darker;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}


[class*="item-status-indicator__"] {
  background-color: @item-reviewable-color;
}

.item-status-indicator__approved {
  background-color: @item-approved-color;
}

.item-status-indicator__refused {
  background-color: @item-refused-color;
}

.item-status-indicator__no-decision {
  background-color: @item-no-decision-color;
}
.item-status-indicator__processing {
  background-color: @item-processing-color;
}

[class*="item-status-text__"] {
  color: @item-status-text-default-color;;
}

.item-status-text__approved {
  color: @brand-success;
}

.item-status-text__refused {
  color: @brand-danger;
}

.item-status-text__no-decision {
  color: @black-2;
}

.app-assignment-overview-recentItems, .app-assignment-automation-filterView {
  background-color: @gray-1;
}

//-------------------
@eva-job-key-value-item-min-height: 30px;
@eva-job-key-value-item-margin-bottom: 3px;
.eva-job-key-value-item {
  min-height: @eva-job-key-value-item-min-height;
  line-height: 19px;
  height: auto;
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: @eva-job-key-value-item-margin-bottom;
  vertical-align: top;
}
.eva-job-key-value-item__label {
  width: 100px;
  color: @gray-2;
  .truncated;
  flex-shrink: 0;
  margin-right: 5px;
}

.eva-job-key-value-item__value {
  height: auto;
  color: @gray-darker;
}

.eva-job-key-value-item__value-link {
  .eva-job-key-value-item__value;
  .truncated;
  .eva-link-primary();
}

.eva-job-key-value-item__value-clickable-text {
  background-color: @gray-1;
  padding: 0 8px;
  cursor: text;
  .truncated;
}

.eva-job-key-value-item__value-disabled-text {
  .eva-job-key-value-item__value;
  color: @gray-darker;;
  word-break: break-word;
  display: inline-block;
  white-space: pre-line;
}

.eva-job-key-value-item-container-with-icon {
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: @eva-job-key-value-item-margin-bottom;

  .eva-job-searchable-item, .eva-job-key-value-item {
    margin-bottom: 0px;
  }

  eva-job-searchable-item {
    flex-grow: 1;
    min-width: 0;
  }
}

.tooltip-text-dynamic {
    color: white;
}

//---- notifications ---

.notyf__toast {
  max-width: 550px;
}

.notyf__ripple {
  width: 700px;
}

.eva-notification-heart-broken {
  .implioicons-heart-broken();
  margin-right: 10px;
  font-size: 19px;
  line-height: 21px;
}