.delete-user-modal {
  width: auto;
  padding: 25px 40px;

  h4 {
    color: @brand-third;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }

  p {
    width: 400px;
    font-size: 16px;
    line-height: 25px;
  }

  .btn {
    height: 50px;
  }

  p:nth-of-type(2) {
    margin-bottom: 22px;
  }

}
