@input-height: 38px;
.eva-input {
    outline: none;
}
.eva-input--full {
    .eva-input();
    height: @input-height;
    line-height: @input-height;
    padding: 8px;
    border: 1px solid @input-border;
    &.eva-input--invalid {
        border-color: @brand-danger;
    }
}

.eva-input--bottom {
    padding: 0;
    .eva-input();
    border: none;
    border-bottom: 1px solid @input-border;
    &.eva-input--invalid {
        border-color: @brand-danger;
    }
}