@stat-summary-card-line-height: 26px;
.stat-summary-card {
  width: 280px;
  margin: 20px 0px;
  padding: 10px 28px;
  border-right: 1px solid @border;
  &:last-child {
    border-right: none;
  }
  .right {
    float: right;
  }

  .number {
    font-weight: 500;
  }

  .detailed {
    p {
      margin: 0px;
      padding: 0px;
      line-height: @stat-summary-card-line-height;
    }

    .category-name {
      display: inline-block;
      color: @gray-darker;
      font-weight: normal;
    }

    .number {
      display: inline-block;
      float: right;
      text-align: right;
      p {
        font-weight: 500;
      }
    }

    .percentage {
      display: inline-block;
      float: right;
      padding-right: 15px;
      color: @gray-dark;
      text-align: right;
    }

  }



}

.stat-summary-card--approved .stat-summary-card__title {
  color: @lime-green;
}

.stat-summary-card--refused .stat-summary-card__title {
  color: @brand-danger;
}

.stat-summary-card--all .stat-summary-card__title {
  color: @black;
}

.stat-summary-card--no-decision .stat-summary-card__title {
  color: @dark-gray;
}

.stat-summary-card__title {
  letter-spacing: 0.7px;
  font-weight: 500;
  color: @black;
}

.stat-summary-card__line__tooltip {
  width: 20px;
  height: @stat-summary-card-line-height;
  margin-left: 4px;
  .eva-tooltip-icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    &:before {
      .absolute-center();
    }
  }
  .eva-tooltip {
    width: 200px;
  }
}

.stat-summary-card__text {
  display: flex;
  align-items: center;
  p {
    display: inline-block;
  }
}

.stat-summary-card__main {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 8px 0;

  .main-number {
    font-weight: 300;      
    color: @gray-darker;
    font-size: 36px;
    flex-shrink: 0;
    margin-right: 30px;
    line-height: 100%;
  }
}  
.stat-summary-card__line-chart {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}