.modal-content .edit-category-modal {
  width: 500px;

  padding: @margin;

  .heading {
    margin-top: 0;
  }

  .tree {
    margin-bottom: @margin;

    .label {
      color: @color;
      font-weight: normal;
      font-size: 1.1em;
      &.selected {
        font-weight: bold;
      }
    }

    i {
      background: none !important;
    }

    .active {
      text-decoration: none;
    }

  }

  .form-group {
    margin-bottom: 0;
  }

  .edit-tree-icons {
    position: relative;
    vertical-align: middle;
    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      display: inline-block;
      transform: translate(-50%, -50%);
      font-size: 21px;
    }
  }

}

.edit-value-in-tree__button-group {
  display: flex;
}

.job-category-select-icon {
  float: right;
}


.select-container {
  position: absolute;
  z-index: 1;
  top: 35px;
  left: 100px;
  width: calc(100% - 100px);
  .category-dropdown {
    background-color: white;
    width: 100%;
    border: rgb(220, 220, 220) solid 1px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px 0px;

    .select__control--menu-is-open {
      height: 30px;
      min-height: 30px;
      border-color: @gray-1;

      &:hover {
        border-color: @gray-1;
        background-color: white;
      }

      .select__value-container {
        height: 30px;
        min-height: 30px;
      }
    }

    .select__option {
      height: 30px;
      min-height: 30px;
      background-color: white;

      &:hover {
        background-color: @gray-1;
      }
    }

    .select__option--is-disabled {
      cursor: default;
      border-bottom: @gray-1 solid 1px;

      &:hover {
        background-color: white;
      }
    }
  }
}
