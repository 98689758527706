@step-indicator-icon-size: 20px;
.csv-importer-navigation {
  position: relative;
  .title {
    margin: 18px 0px 0px 0px;
    padding: 0px;
    font-size: 20px;
    letter-spacing: 0.4px;
    color: @gray-darker;
  } 

  .step-indicator-container {
    margin: 20px 0px;
    line-height: 24px;
    .flex-container;
  }
  .step-indicator {
    margin-right: 30px;

    .icon {
      margin-right: 4px;
      display: inline-block;
      width: @step-indicator-icon-size;
      border-radius: 2px;
      background-color: @gray-eva-light;
      color: @white;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      line-height: 20px;

      &.is-current-step {
        color: @gray-darker;
        background-color: @white;
        border: solid 2px @gray-eva-light;
        box-sizing: content-box;
      }
    }
    span.check {
      display: inline-block;
      font-size: 12px;
      width: @step-indicator-icon-size;
      height: @step-indicator-icon-size;
      background-color: @brand-success;
      color: @white;
      line-height: @step-indicator-icon-size;
      text-align: center;
      vertical-align: bottom;
      border-radius: 2px;
      margin-bottom: 2px;
    }
    .label {
      font-size: 14px;
      font-weight: normal;
      text-align: left;
    }

    &.finished {
      border:none;
      background-color: @brand-success;
    }
  }
}

.csv-importer-navigation__button-group {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  height: 38px;
}

