@item-log-image-container-height: 44px;
@item-log-image-container-width: 64px;
@item-log-table-header-height: 47px;
.jobs-table {
  table-layout: auto;
}

.jobs-table-header-row {
  height: @item-log-table-header-height;
  background-color: @gray-3;
}

.table {
  width: 100%;
  .jobs-table-header-cell {
    padding: 0;
  }
  
  .jobs-table-header-cell.jobs-table-header-preview {
    padding-left: 15px;
  }

  .jobs-table-content-cell, .jobs-table-header-cell  {
    vertical-align: middle;
    .truncated();
  }
.job-table-content-cell-preview{
  //make preview cell take as much place, and other column shrink if needed.
  //see https://stackoverflow.com/questions/26292408/why-does-this-behave-the-way-it-does-with-max-width-0/26293398#26293398
  max-width: 0;
  width: 100%;
}
  .jobs-table-content-cell {
    padding: 4px 30px 4px 0;
    &:last-child {
      padding: 4px 16px 4px 0;
    }
  }
  .jobs-table-content-row {
    background-color: white;
    &:hover {
      background-color: @gray-4;
    }
  }

  .job-table-content-cell-status.eva-tooltip-container--center {
    overflow: visible;
    .eva-tooltip {
      top: 7px;
      max-width: 200px;
      white-space: normal;
    }
  }
}
.job-table-content-cell-id-text{
  min-width: 65px;
  max-width: 145px;
  .truncated();
}
.job-list-text-link {
  position: relative;
  display: block;
  .flex-item();
  .truncated();
  &:hover, &:focus {
    color: @black;
  }
  .jobs-list-status-icon {
    position: absolute;
    height: 20px;
    left: 0px;
  }
}
.job-list-image-container {
  width: @item-log-image-container-width;
  height: 100%;
  position: relative;
  flex-shrink: 0;
  margin: 0 16px 0 0;
  background-color: @gray-1;
  max-height: 44px;
  .media-item-container {
    width: 64px !important;
    .media-item-content-container {
      height: 44px !important;
      .media-item {
        max-width: @item-log-image-container-width;
        max-height: @item-log-image-container-height;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.job-list-image {
  max-width: @item-log-image-container-width;
  max-height: @item-log-image-container-height;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.job-table-content-cell-preview-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: @item-log-image-container-height;
}
.job-list-item-status-indicator {
  height: 100%;
  width: 2px;
  margin: 0 7px;
  flex-shrink: 0;
}

.item-log-table-preview-body, .item-log-table-preview-item-id {
  color: @gray-mike;
}
.item-log-table-preview-body br {
  display: none;
}

.item-log-table-preview-title {
  font-weight: @font-weight-medium;
}
.job-table-content-cell-rule .matched-rules {
  min-width: 100px;
  max-width: 150px;
}
.item-log-status-text {
  font-weight: @font-weight-medium;
  min-width: 90px;
  max-width: 200px;
  .truncated();
}