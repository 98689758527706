.delete-all-content {
  .custom-modal{
    padding-top: 150px
  }
  >h2.title1 {
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
  }

  > h2.title2 {
    margin-top: 0;
    color: @brand-third;
  }

  .row {
    margin-top: 30px;
  }

}

.delete-all-content-notice-icon {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: @brand-third;
  position: relative;
  display: inline-block;
  margin-right: 6px;
  .implioicons-exclamation();
  &:before {
    color:white;
    .absolute-center();
  }
}

.content-deletion-icon {
    margin-right: 22px;
    font-size: 25px;
    color: @brand-secondary;
}


.delete-content-explanation-title {
  color: @brand-third;
  font-weight: bold;
  line-height: 20px;
}

.delete-content-explanation-container {
  .flex-container;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
  }
}