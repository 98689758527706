@template-item-draggable-hover-color: @brand-secondary;
.template-item-draggable {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: white;
    align-items: center;
    margin-top: 17px;
    border: 1px solid @border;
    border-radius: 3px;
    opacity: 1;
    cursor: grab;
    .eva-switch-box {
        margin-right: 70px;
    }

    &.template-item-draggable--hovered {
        cursor: grabbing;
        border-color: @template-item-draggable-hover-color;
        .template-item-draggable__icon {
            color: @template-item-draggable-hover-color;
        }
    }

    &.template-item-draggable--dragging {
        opacity: 0;
    }

    &.template-item-draggable--switch-off {
        background-color: @gray;
    }
}

.template-item-draggable__left {
    display: flex;
    align-items: center;
}

.template-item-draggable__icon {
    width: 15px;
    height: 100%;
    margin: 0 15px;
    color: @gray-15;
    font-size: 27px;
    position: relative;
    &:before {
        .absolute-center();
    }
}

.template-item-draggable__text {
    font-size: 16px;
    font-weight: bold;
}    