.team-members-setting {
    width: 615px;
}

.team-members-setting__header {
    display: flex;
}

.team-members-setting__header__icon {
    margin: 23px 0 0 5px;
    &:hover {
        cursor: pointer;
    }
}