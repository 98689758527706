@font-face {
	font-family: "implioicons";
	src: url("@{implio-icons-font-path}/implioicons.eot");
	src: url("@{implio-icons-font-path}/implioicons.eot?#iefix") format("embedded-opentype"),
		url("@{implio-icons-font-path}/implioicons.woff") format("woff"),
		url("@{implio-icons-font-path}/implioicons.ttf") format("truetype"),
		url("@{implio-icons-font-path}/implioicons.svg#implioicons") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="implioicons-"],
[class*=" implioicons-"],
.implioicons {
	&:before {
		font-family: "implioicons" !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-variant: normal !important;
		text-transform: none !important;
		line-height: 1;
		vertical-align: middle;
	}
}

.implioicons-moderation-approved {
	.implioicons();
	&:before {
		content: "\e00d";
	}	
}

.implioicons-receive,
.implioicons-moderation-new {
	.implioicons();
	&:before {
		content: "\e03a";
	}	
}

.implioicons-send-to,
.implioicons-moderation-reviewable,
.implioicons-moderation-deferred {
	.implioicons();
	&:before {
		content: "\e03b";
	}
}

.implioicons-cross,
.implioicons-moderation-refused {
	.implioicons();
	&:before {
		content: "\e011";
	}
}

.implioicons-arrow-1-down:before {
	content: "\e000";
}
.implioicons-arrow-1-left:before {
	content: "\e001";
}
.implioicons-arrow-1-right:before {
	content: "\e002";
}
.implioicons-arrow-1-up:before {
	content: "\e003";
}
.implioicons-arrow-2-down:before,
.implioicons-chevron-down:before {
	content: "\e004";
}
.implioicons-arrow-2-left:before,
.implioicons-chevron-left:before {
	content: "\e005";
}
.implioicons-arrow-2-right:before,
.implioicons-chevron-right:before {
	content: "\e006";
}
.implioicons-arrow-2-up:before,
.implioicons-chevron-up:before {
	content: "\e007";
}
.implioicons-branch:before {
	content: "\e00c";
}
.implioicons-check {
	.implioicons();
	&:before {
		content: "\e00d";
	}
}
.implioicons-cog:before {
	content: "\e00e";
}
.implioicons-credit-card:before {
	content: "\e00f";
}
.implioicons-crop:before {
	content: "\e010";
}
.implioicons-disabled:before {
	content: "\e012";
}
.implioicons-draggable:before {
	content: "\e013";
}

.implioicons-cross,
.implioicons-moderation-refused {
	.implioicons();
	&:before {
		content: "\e011";
	}
}

.implioicons-plus {
	.implioicons-cross();
	transform: rotate(90%);
}

.implioicons-edit {
	.implioicons();
	&:before {
		content: "\e014";
	}
}
.implioicons-enlarge:before {
	content: "\e015";
}
.implioicons-heart:before {
	content: "\e016";
}
.implioicons-heart-broken {
	.implioicons();
	&:before {
		content: "\e017";
	}
}
.implioicons-heart-cracked:before {
	content: "\e018";
}
.implioicons-home:before {
	content: "\e019";
}
.implioicons-image:before {
	content: "\e01a";
}
.implioicons-link:before {
	content: "\e01b";
}
.implioicons-mail:before {
	content: "\e01d";
}
.implioicons-minimize:before {
	content: "\e01e";
}
.implioicons-mirror-horizontal:before {
	content: "\e01f";
}
.implioicons-mirror-vertical:before {
	content: "\e020";
}
.implioicons-more-options:before {
	content: "\e021";
}
.implioicons-network:before {
	content: "\e022";
}
.implioicons-notifications:before {
	content: "\e023";
}
.implioicons-open-in-tab:before {
	content: "\e024";
}
.implioicons-play:before {
	content: "\e025";
}
.implioicons-question-mark:before {
	content: "\e026";
}
.implioicons-re-do:before {
	content: "\e027";
}
.implioicons-revert:before {
	content: "\e028";
}
.implioicons-rotate-clockwise:before {
	content: "\e029";
}
.implioicons-rotate-counter-clockwise:before {
	content: "\e02a";
}

.implioicons-search {
	.implioicons();
	&:before {
		content: "\e02b";
	}
}
.implioicons-send:before {
	content: "\e02c";
}
.implioicons-shoot:before {
	content: "\e02d";
}
.implioicons-signal:before {
	content: "\e02e";
}
.implioicons-statistics:before {
	content: "\e02f";
}
.implioicons-thumbs-down:before {
	content: "\e031";
}
.implioicons-thumbs-up:before {
	content: "\e032";
}
.implioicons-warning:before {
	content: "\e035";
}
.implioicons-wrench:before {
	content: "\e036";
}
.implioicons-yeah:before {
	content: "\e037";
}

.implioicons-lock:before {
	content: "\e01c";
}
.implioicons-question-mark-filled {
	.implioicons();
	&:before {
		content: "\e039";
	}
}
.implioicons-receive:before {
	content: "\e03a";
}

.implioicons-speech-bubble {
	.implioicons();
	&:before {
		content: "\e03c";
	}	
}
.implioicons-user:before {
	content: "\e01a";
}
.implioicons-trash:before {
	content: "\e034";
}
.implioicons-arrow-3-down:before,
.implioicons-arrow-down:before {
	content: "\e008";
}
.implioicons-arrow-down {
	.implioicons();
	&:before {
		content: "\e008";
	}
}
.implioicons-arrow-3-left:before,
.implioicons-arrow-left:before {
	content: "\e009";
}
.implioicons-arrow-3-right:before,
.implioicons-arrow-right:before {
	content: "\e00a";
}
.implioicons-arrow-3-up:before,
.implioicons-arrow-up:before {
	content: "\e00b";
}
.implioicons-calendar {
	.implioicons();
	&:before {
		content: "\e03d";
	}
}
.implioicons-eye:before {
	content: "\e03e";
}
.implioicons-code:before {
	content: "\e040";
}
.implioicons-dashboard:before {
	content: "\e03f";
}
.implioicons-list:before {
	content: "\e041";
}
.implioicons-loading {
	.implioicons();
	&:before {
		content: "\e042";
	}
}
.implioicons-profanity:before {
	content: "\e043";
}
.implioicons-sign-out:before {
	content: "\e044";
}
.implioicons-tie:before {
	content: "\e045";
}
.implioicons-clock:before {
	content: "\e046";
}
.implioicons-dash, .implioicons-moderation-NOTHING {
	.implioicons();
	&:before {
		content: "\e047";
	}
}
.implioicons-globe:before {
	content: "\e048";
	vertical-align: baseline;
}
.implioicons-keyboard:before {
	content: "\e049";
}
.implioicons-dot {
	.implioicons();
	&:before {
		content: "\e04a";
	}
}

.implioicons-bounding-box:before {
	content: "\e04b";
}
.implioicons-keyboard-r:before {
	content: "\e04c";
}
.implioicons-keyboard-a:before {
	content: "\e04d";
}
.implioicons-keyboard-s:before {
	content: "\e04e";
}
.implioicons-exclamation {
	.implioicons();
	&:before {
		content: "\e04f";
	}
}
.implioicons-paper-plane:before {
	content: "\e05a";
}

.implioicons-image-1:before {
	content: "\e051";
}

.implioicons-statistics:before {
	content: "\e02f";
}

.implioicons-network:before {
	content: "\e022";
}

.implioicons-cloud:before {
	content: "\e033";
}

.icon-notifications:before {
	content: "\e023";
}

.implioicons-team {
  .implioicons();
  &:before {
    content: "\e030";
  }
}

.implioicons-add-to-list {
  .implioicons();
  &:before {
    content: "\e052";
  }
}

.implioicons-copy-large:before {
  content: "\e054";
}
.implioicons-close:before {
	content: "\00D7";
}
.implioicons-up-arrowhead:before {
	content: "\2303";
}
.implioicons-down-arrowhead:before {
  content: "\2303";
  display: inline-block;
  transform: rotate(180deg);
}

.implioicons-search-img:before {
	content: "\e053";
}
