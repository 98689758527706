.item-with-revision-wrapper {
  outline: none;
  margin-top: 40px;
  &.focused .job-group {
    border: 2px solid #5a0077;
  }
}
.cursor-pointer{
  cursor: pointer;
}

.submit-tooltip {
  max-width: 150px;
  top:-9px !important;
}

.submit-btn-style{
  // on focus apply this class eva-keyboard-focused'
  &:focus{
    box-shadow: 0 0 0 0 rgba(250, 0, 90, .3);
    animation: submit-button-pulse 1.5s infinite;
    border: 2px solid @brand-secondary;
  }
}