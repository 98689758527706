.rich-info-modal{
  background-color:transparent;
  width: 100%;
  padding: 0px;
  border: 0px;
  margin: 0 !important;
  .intro-text {
    background-color: @brand-secondary;
    text-align: center;
    padding: 10px 100px;

    h2 {
      margin-top: 28px;
      color: @brand-third;
      font-weight: 500;
    }
    p {
      color: @white;
    }
  }
  button.text-btn {
    background: none;
    color: @white;
    font-size: 18px;
    font-weight: 500;
  }
  .close-icon {
    .cross-icon(15px, @black-2, 10%);
    position: absolute;
    top: 9px;
    right: 9px;
    z-index: 4;
  }
}

.modal {
  display: flex!important;
  align-items: center;

  &.fade .modal-dialog {
    .translate(0, 0px);
    .transition-transform(~"0.15s ease-out");
  }
  &.in .modal-dialog { .translate(0, -25px) }

  &.tall-modal { // for modals that can grow very tall, always show them from the top
    align-items: flex-start;

    &.fade .modal-dialog {
      .translate(0, 55px);
      .transition-transform(~"0.15s ease-out");
    }
    &.in .modal-dialog { .translate(0, 30px) }
  }

  .modal-dialog, .modal-content {
    width: auto;
    height: auto;
  }

  .modal-dialog {
    background: transparent;
    .flex-container;
    justify-content: center;
  }


  .modal-content {
    background-color: @white;
    border-radius: @modal-border-radius;
    padding: 0;
    margin: 0;

    h3.modal-title {
      margin: 0px 0px @modal-padding 0px;
    }
    hr.modal-title-divider {
      margin: 0px -@modal-padding 0px -@modal-padding;
      color: @table-border-color;
    }
  }

}


.modal-backdrop {
  height: 100%;
  width: 100%;
  display: block;
  position: fixed;
  &.in, &.out {
    opacity: 0.7;
  }
}





