.blang-editor {
  .bg-warning {
    padding-left: 7px;
    padding-right: 7px;
  }
  &:not(.blang-editor--readonly)
  .CodeMirror-focused {
    border: 1px solid @gray-darker;
  }
}

.code-mirror-blang-mode {

  .CodeMirror {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid @border;
    resize: both;

    .CodeMirror-placeholder {
      color: @gray-20;
    }
  }

  .cm-variable {
    color: @gray-darker;
  }

  .cm-list {
    color: @brand-primary;
  }

  .cm-keyword {
    color: @gray-darker;
    font-weight: 400;
  }

  .cm-boolean {
    color: @blue-2;
  }
  .cm-number {
    color: @orange;
  }
  .cm-string  {
    color: @green-5;
  }
  .cm-comment {
    color: @gray-2;
  }
  .cm-regex {
    color: @purple-2;
  }
}
