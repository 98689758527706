.add-button{
    height: 100%;
}

.add-container{
height: 38px;
}

.custom-add-phash-tooltip {
  width: 144px;
  height: fit-content;
  top: -70px;
  right: -116px;
  text-align: left;
  padding: 9px;
  background-color: black;
}


.add-phash-item {
    margin-top: @margin;
  
    .form-control.add-word.focus,
    .form-control.add-word:focus {
        border-color: @input-border;
    }
  
    .table {
      table-layout: fixed;
  
      td {
        word-wrap: break-word;
      }
    }
  }
  
  .button-row {
    margin-bottom: 3em;
  }
  
  .dictionary-item-editor__input-button-group {
    display: flex;
    
  }
  
  .regex-item {
    color: @purple-2;
  }
  
  .tooltip-icon {
    margin-left: 4px;
  }