.chip {
  border: 1px solid #d8dee2;
  border-radius: 2px;
  background-color: white;
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.chip span, .chip i {
  padding: 0 8px;
  height: 100%;
  cursor: pointer;
}

.chip span:hover, .chip i:hover {
  background-color: #F3F3F3;
}

.chip span {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
}

.chip i {
  padding-bottom: 8px;
  font-size: 20px;
}

.chips {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

.chips:not(:empty) {
  margin-bottom: 15px;
}