#delete-modal() {
    width: @delete-modal-width;
    padding: 10px 29px 0;
  
    .eva-primary-button, .eva-secondary-button {
      margin: @delete-queue-modal-button-margin;
    }
  
    .delete-modal__button-group {
      display: flex;
      justify-content: flex-end;
    }
    
    .delete-modal__text {
      margin-bottom: 0;
    }
  
    .delete-modal__title {
      font-size: 22px;
      line-height: 20px;
      font-weight: normal;
      margin-bottom: 20px;
    }
  }