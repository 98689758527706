@moderation-settings-gray-text-color: @gray-mike;
.moderation-settings {
    width: 615px;
}

.save-changes-btn{
    margin: 20px 0 50px;
}

.moderation-settings__section {
    width: 100%;
}
.moderation-setting__section__title {
    color: @brand-secondary;
    margin: 30px 0 18px;
    font-size: 14px;
    font-weight: bold;
}

.moderation-setting__config-item {
    display: flex;
    width: 100%;
    margin-bottom: 26px;
    justify-content: space-between;
}

.moderation-setting__config-item__text-container {
    display: flex;
    flex-direction: column;
    max-width: 380px;
    flex-shrink: 0;
    margin-right: 20px;
}
.moderation-setting__config-item__text-container__name {
    font-weight: 700;
    font-size: 16px;
    color: @gray-34;
}

.moderation-setting__config-item__text-container__text {
    color: @moderation-settings-gray-text-color;
}   

.moderation-setting__config-item__input {
    width: 44px;
    height: 22px;
    .eva-input--bottom();
}

.moderation-setting__config-item__input-container{ 
    display: flex;
    flex-direction: column;
    align-items: flex-end;   
    .setting__error {
        min-height: 20px;
        text-align: right;
    }
}

.moderation-setting__select-image-gallery__option {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    &.moderation-setting__select-image-gallery__option--active {
        .moderation-setting__select-image-gallery__option__text {
            color: @brand-secondary;
            border-color: @brand-secondary;
        }
        .moderation-setting__select-image-gallery__option__image {
            filter: none;
        }
    }
}

.moderation-setting__select-image-gallery__option__image {
    height: 70px;
    width: 118px;
    filter: grayscale(1) opacity(0.5);
}

.moderation-setting__select-image-gallery__option__text {
    margin-top: 14px;
    padding: 0 5px;
    font-weight: 500;
    line-height: 24px;
    border: 2px solid transparent;
    border-radius: 15px;
    width: 100%;
    color: @dark-gray;
    text-align: center;
}


.moderation-setting__select-image-gallery {
    margin-top: 26px;
    height: auto;
    width: 100%;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.1);
    padding: 30px 30px 40px 30px;
    display: flex;
    justify-content: space-around;
}

.moderation-settings__change-template-order {
    margin: 26px 0 0 0;
    width: 100%;
    border: 1px solid @border;
    background-color: @gray;
    padding: 16px 10px;
    margin-bottom: 40px;
}
.moderation-settings__change-template-order__header {
    display: flex;
    justify-content: space-between;
    color: @moderation-settings-gray-text-color;
    font-weight: bold;
    line-height: 24px;
    margin: 0 47px;
}