@media-gallery-thumbnail-action-icon-size: 24px;
.media-gallery {

    width: 366px;
  .media-gallery-carousel {
    .media-item-container {
      display: inline-block;
      vertical-align: top;
    }

    .main-image {
      z-index: 2;
      background: @white;
      position: relative;
      margin-bottom: 16px;
      .flex-container;
      .flex-center;
      .flex-align-item-middle;

      .media-item-container {
        width: 100%;
        height: 100%;
        display: none;
        &.is-selected {
          display: inline-block;
        }

        .media-item-content-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      .media-item {
        max-width: 100%;
        max-height: 100%;
      }

    }
  }

}

.media-gallery-sized-container {
  width: 100%;
  .flex-container;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 4px;

  .media-item {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    .absolute-center();
  }

  .media-item-container {
    width: 100%;
    height: 100%;
  }

  .media-item-container .media-item-content-container {
    width: 100%!important;
    height: 100%!important;
    border: 1px solid @border;
  }
}

