@nav-dropdown-box-shadow: 0 2px 3px 1px rgba(0,0,0,0.30);
@nav-dropdown-arrow-height: 14px;
@nav-dropdown-text-padding: 0 10px 0 54px;

.eva-grid-system {
  h1,h2,h3,h4,h5,h6,p,ul,li,input {
    font-family: @font;
    margin: 0px;
  }


  h1 {
    font-size: 30px;
    line-height: 30px;
    color: @dark-purple;
    font-weight: 300;
  }

  h3 {
    font-size: 18px;
    line-height: 25px;
    color: @black;
    font-weight: 400;
  }
  h4 {
    font-size: 13px;
    color: @purple;
    line-height: 18px;
  }
  h5 {
    font-size: 13px;
    color: @black;
    line-height: 18px;
  }
}

.eva-navbar-primary {

  background: @dark-purple;
  height: @navbar-primary-height;
  color: @white;
  position: relative;
  z-index: @eva-navbar-primary-z-index;



  h1 {
    font-size: 22px;
    line-height: 30px;
    color: @white;
    font-weight: 300;
    margin-top: 16px;
    border-bottom: 1px solid transparent;
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    padding: 0px;
    display: inline-block;
  }
  li {
    display: inline-block;
  }
}

#nav-team {
  position: relative;

  #nav-logo {
    position: absolute;
    top: 15px;
    left: -35px;
    height: 30px;
    width: 35px;
    border-right: 1px solid rgba(255,255,255,0.15);
    &.hide-divider {
      border-right: none;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  #eva-current-team {
    position: relative;
    display: inline-flex;
    justify-content: left;
    align-items: center;
    i {
      padding-left: 20px;
      padding-right: 10px;
      padding-top: 17px;
      line-height: 100%;
      font-size: 27px;
      opacity: 0.5;
      z-index: calc(@eva-navbar-primary-z-index + 1);
    }
    &:hover {
      cursor: pointer;
      i {
        opacity: 1;
        transition: all 0.15s;
      }
      h1 {
        border-bottom: 1px solid @white;
        transition: all 0.15s;
      }
    }
  }

}

#eva-primary-nav-links {
  margin-top: 12px;
  li {
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 40px;
    transition: all 0.25s;
    a {
      opacity: 0.75;

      &.nav-links-active {
        opacity: 1;
        cursor: pointer;
        color: @brand-third;
      }

      &:hover:not(.nav-links-active):not(.no-access) {
        opacity: 1;
        cursor: pointer;
        transition: all 0.15s;
      }
    }



    &:last-child{
      margin-right: 0px;
    }

    a {
      color: @white;
      text-decoration: none!important;
    }
  }
}

.nav-settings-dropdowns-container {
  z-index: @eva-navbar-primary-dropdown-z-index;
  position: absolute;
  top: 30px;
  right: -108px;
  box-shadow: @nav-dropdown-box-shadow;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  h4 {
    position: relative;
  }

  .eva-dropdown-arrow-holder {
    height: @nav-dropdown-arrow-height;
    width: 100%;
    background-color: transparent;
  }

  ul {
    padding: 25px 0;
    background: @white;
    width: 100%;
    &:before {
      content: "";
      height: 14px;
      width: 14px;
      position: absolute;
      left: 50%;
      top: (@nav-dropdown-arrow-height/2);
      margin-left:-7px;
      background: @white;
      transform: rotate(45deg);
    }
  }

  hr {
    margin: 10px 0;
  }

  li {
    display: block;
    color: @black;
    height: 34px;
    line-height: 34px;
    white-space: nowrap;

    a {
      width: 100%;
      height: 100%;
      padding: @nav-dropdown-text-padding;
      display: inline-block;
      text-decoration: none;
      color: @black!important;
    }

    &.nav-dropdown-sub-header {
      pointer-events: none;
    }
    &:hover, &:focus {
      font-weight: 500;
      cursor: pointer;
      background-color: @gray;
      a {
        color: @black;
        text-decoration: none;
      }
    }

    h4{
      line-height: 34px;
      cursor: default;
      padding: @nav-dropdown-text-padding;
    }
  }
}

.eva-nav-settings {
  margin-top: 23px;
  > ul {
    float: right;

    > li {
      position: relative;
      height: 20px;
      width: 20px;
      margin-left: 20px;
      opacity: 0.75;



      &.hovered {
        opacity: 1;

        .nav-settings-dropdowns-container {
          top: 20px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

searchbox {
  margin-top: 10px;
  float: right;
  display: inline-block;
  position: relative;
}


#dropdown-support, #dropdown-settings, #dropdown-gdpr {
  width: 238px;
}

#dropdown-gdpr.nav-settings-dropdowns-container {
  right: -109px;
}

#dropdown-user {
  width: 196px;
  right: -88px;
}

@media only screen and (max-width : 1360px) {
  #dropdown-user {
    width: 196px;
    right: -26px;
    ul:before {
      left: 81%
    }
  }

  #dropdown-settings {
    right: -36px;
    ul:before {
      left: 81%
    }
  }

  #dropdown-support {
    right: -36px;
    ul:before {
      left: 81%
    }
  }

}

@media only screen and (max-width : 1230px) {
  #dropdown-user {
    width: 196px;
    right: -10px;
    ul:before {
      left: 90%
    }
  }
}

@media only screen and (max-width : 1300px) {
  #eva-logo-and-team-name {
   padding-left: 50px;
  }
}

.system-status-icon {
  position: relative;
  &:before {
    position:absolute;
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 5px;
    top: 13px;
    left: 20px;
    background-color: @gray-22;
    box-shadow: 0 0 1px @gray-22;
  }

  &.none:before {
    background-color: @brand-success;
    box-shadow: 0 0 1px @brand-success;
  }

  &.minor:before {
    background-color: @orange;
    box-shadow: 0 0 1px @orange;
  }

  &.major:before, &.critical:before {
    background-color: @brand-danger;
    box-shadow: 0 0 1px @brand-danger;
  }

}

.eva-nav-menu-icons {
  font-size: 22px;
  color: white;
  display: inline-block;
  margin-top: -3px;
  &:before {
    vertical-align: top;
  }
}

.nav-dropdown-sub-header-icons {
  position: relative;

  &:before {
    position: absolute;
    left: 20px;
    top: 6px;
    color: @brand-secondary;
    font-size: 22px;
  }
}

.navbar-primary__user-name {
  .truncated();
}