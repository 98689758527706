
eva-user-previous-items-count {
    flex-shrink: 0;
}

.eva-user-previous-ads-count-container {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    vertical-align: top;
}
  
.eva-user-previous-ads-count__APPROVE {
    .eva-user-previous-ads-count;
    background-color: @green-2;
}
  
.eva-user-previous-ads-count__REFUSE{
    .eva-user-previous-ads-count;
    background-color: @bright-red;
}

.eva-user-previous-ads-count__new-user {
  .eva-user-previous-ads-count;
  background-color: @brand-primary;
}
  
.eva-user-previous-ads-count {
    height: 19px;
    display: inline-flex;
    align-items: center;
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    margin-right: 4px;
    vertical-align: top;
    line-height: 19px;

    padding: 0 10px;
    border-radius: 19px;
    .eva-tooltip-container--center;

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: white;
    }
  
    &:hover .eva-tooltip {
      left: 50%;
      top: -2px;
      transform: translate(-50%, 100%);
      padding: 7px 12px;
      font-size: 12px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.31);
      &:before {
        left: 50%;
        transform: translate(-50%, 0);
        border-top: 0px;
        border-bottom: 8px solid #000000;
        top: -8px;
      }
    }

  &:hover .eva-tooltip-user-previous-item-count {
    background-color: @black;
  }

    &.eva-user-previous-ads-count--empty {
      background-color: @gray-2;
      cursor: default;
      pointer-events: none;
    }
}
  
.eva-user-previous-ads-count__tooltip-status {
    color: @gray-3;
}

.eva-user-previous-ads-count__tooltip-number{
    font-weight: 500;
    color: white;
}