  .report-modal {
    width: 550px;
    padding: 10px 44px 20px;
    .header {
      margin: 0;
    }
    .form-part {
      margin-top: 22px;

      .time-period {
        display: inline-block;
        width: 70px;
      }

      &.bottom {
        display: flex;
        justify-content: flex-end;

        .eva-primary-button {
          display: inline;
          width: 150px;
        }

        .eva-secondary-button {
          display: inline;
          width: 150px;
          margin-right: 20px;
        }
      }

      .sendTo {
        width: 300Px;
      }

      .checkbox-group {
        label {
          &.disabled {
            color: @gray-5;

            input {
              cursor: not-allowed;
            }
          }

          height: 20px;
          line-height: 20px;
          font-weight: normal;

          span {
            margin-left: 15px;
          }

          input {
            width: 15px;
            height: 15px;
            margin: 0;
            vertical-align: middle;
            position: relative;
            top: -1px;
          }
        }

        &.subcheckbox {
          text-indent: 15px;
        }
      }
    }
  }
