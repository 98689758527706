.dictionariesTable {

    th {
      width: 170px;
    }
  
    th:nth-child(1) {
      width: auto;
    }
  
    th:nth-child(4) {
      width: 250px;
    }
  
    th:nth-child(5), th:nth-child(6) {
      width: 45px;
    }
  
  }
  
  
  .filters-lists-table-button {
    &:before {
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .delete-icon {
    .implioicons-cross();
    .filters-lists-table-button();
  }
  
  .edit-icon {
    .implioicons-edit();
    .filters-lists-table-button();
  }
  
  
  .filters-lists-input-search-icon {
    font-size: 20px;
  }
  
  .automation__table__description--icon {
    font-size: 16px;
    margin-left: 4px;
    .implioicons-speech-bubble();
    .eva-tooltip-container--left();
    .eva-tooltip {
      top: -6px;
      max-width: 250px;
      left: -5px;
      &:before {
        left: 5px;
      }
    }
  }
  
  .automation__table__link {
    display: block;
    width: 100%;
    height: 100%;
    .eva-link-third(@black);
    .eva-word-break;
  
    > .name-display {
      vertical-align: middle;
      display: inline-block;
      max-width: calc(100% - 20px);
      &.with_desc {
        max-width: 93%;
      }
    }
  }
  

.error-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}