/* Elements
--------------------------------------------------------*/

.check-inputs() {
  @size: 20px;

  .appearance();
  .position(top, left, 5px, 0, relative);
  .size(@size);

  background: @border;
  cursor: pointer;
  margin: 0 10px 0 0;

  &:focus { outline: none; }
  &:hover { background: @blue; }
  &.valid { background: @green; }
}

.form-inputs() {
  input[type="text"], select {
    .appearance();
    .border-radius(0);
    .transition(border-color);
    .valid-invalid();

    background: @white;
    border: none;
    border-left: @border-size solid @border;
    margin-bottom: 10px;
    padding: 10px;
    position:  relative;
    color: @gray;
    width: 100%;
    text-shadow: none;
  }

  .select-wrap {
    overflow:hidden;

    select {
      width:111%;
    }
  }

  .error {
    .border-radius();

    background: @red;
    color: @white;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;

    &.start-time { margin: 10px 0 0 0; }
    &.end-time { margin-top: 10px; }
  }
  .input-group {
    margin: 0 0 10px;
    &[class*="col-"] {
      padding: 0 0 0 15px;
    }
    input {
      background: @white;
      &,
      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
      }
    }
    .form-control {
      margin: 0;
    }
  }
}

.eva-form() {
  .form {
    .row-style(@modal-bg);

    text-align: right;

    h4 { text-align: left; }

    input[type="text"] { .box-sizing(); }

    section {
      .form-inputs();
    }

    .datepicker-wrap, .radio-wrap {
      display: flex;
    }

    .datepicker-wrap, .dropdown, .radio-inner {
      flex:1 1;
      text-align: left;

      &.first { margin-right: 2%; }
    }

    .datepicker-wrap,
    .dropdown {
      .input-group,
      a {
        color: @black-2;
        display: flex;

        .input-group-addon,
        .icon-wrap {
          background: @blue;
          display: flex;
          padding: 0;
          margin: 0;
          width: 40px;
          height: 40px;

          i {
            background: @blue;
            color: @white;
            padding: 5px 10px;
            align-self:center;
          }
        }
      }
      .input-group {
        width: 100%;
        &.first {
          margin-right: 2%;
        }
      }
    }

    .radio-wrap {
      margin:10px 0;

      input { .check-inputs(); }
    }

    .radio-inner,
    .checkbox-inner,
    .radio-inline,
    .checkbox-inline {
      label,
      .radio-label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
        vertical-align: top;
      }
    }

    .sites, .frequency {
      margin-bottom: 10px;
      text-align: left;

      li {
        background: @white;
        border-left: @border-size solid @border;
        cursor: pointer;
        float: left;
        padding: 5px;
        text-align: center;
        width: 15%;

        &:not(:last-child) { margin-right: 2%; }
        &:hover { border-left: @border-size solid @blue; }
        &.selected { border-left: @border-size solid @green; }
      }
    }

    .btn.save { margin-left: 20px; }
  }
}

.eva-input-styling () {
  .row-style(@modal-bg);

  h2 { margin-top: 0; }

  .urls li {
    position: relative;

    input {
      .transition(width, 100ms, linear);
      .valid-invalid();

      border: none;
      border-left: @border-size solid @border;
      padding: 5px;
      width: 100%;

      &:first-of-type { margin-bottom: 5px; }
      &:focus:not(.valid):not(.invalid) {
        border-left: @border-size solid @blue;
      }
    }

    .error {
      .border-radius();

      background: @red;
      color: @white;
      padding: 10px;
      margin-top: 10px;
      text-align: center;
    }
  }
}

/**
 * Modal
 */
.eva-modal() {
  .center(both);

  margin-bottom: @margin;
  position: fixed;
  width: 970px;
  z-index: 9001;

  &, & * {
    text-shadow: none !important;
  }


  h2 {
    font-size: 21px;
    margin: 5px 0 20px 0;
    text-align: left;
  }

  .eva-form();

  .col-md-6 > div {
    .eva-input-styling();
  }
}

.row-style(@bg:@body-bg) {
  background: @bg;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
}

.valid-invalid() {
  &:focus { border-left:@border-size solid @blue; }
  &.valid { border-left:@border-size solid @green; }
  &.invalid { border-left:@border-size solid @red; }
}

.input-group-addon-light() {
  background-color: lighten(@box-border, 30%);
  color: darken(@box-border, 30%);
}

.ng-table {
  th {
    text-align: left !important;
  }
}


hr {
  border-top: 1px solid @divider-color;
}


