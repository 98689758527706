.filter-tester {
  background: @white;

  .filter-body {
    border: 1px solid @border;
    border-radius: @border-radius-base;
    padding: 10px;
    margin-top: 5px;
  }

  input, textarea {
    border: 1px solid @border;
    border-radius: @border-radius-base;
    width: 100%;
    &:focus,
    &.focus {
      &.ng-valid,
      &.valid {
        // to fix double border in firefox
        border-color: transparent;
      }
      &.ng-invalid,
      &.invalid {
        &:not(.ng-pristine) {
          // to fix double border in firefox
          border-color: transparent;
        }
      }
      &,
      &.ng-pristine {
        // to fix double border in firefox
        border-color: transparent;
      }
    }
  }

  p.bg-info, p.bg-danger, p.bg-warning, p.bg-success {
    text-align: center;
  }

  div.test-result {
    margin-top: 10px;

    .label {
      margin: 2px;
      float: left;
    }
  }

  .form-group {
    padding-top: 5px;
  }

  .header {
    p {
      display: inline;
      font-weight: @create-rule-view-label-font-weight;
    }

    .more-info {
      width: 200px;
      position: relative;
      top: -2px;
      display: inline;
    }
  }

}
