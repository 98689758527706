@job-summary-approved-color: @green-2;
@job-summary-refused-color: @brand-danger;
@job-summary-no-decision-color: @black-2;
.eva-jobs .job-summary .job-summary-decision {
  border-radius: 0;
}

.job-summary {
  display: block;
  margin: 0;
  word-wrap: break-word;
  flex-grow: 1;

  &.approved {
    .job-summary-action {
      font-weight: bold;
    }

    hr {
      border-color: @job-summary-approved-color;
    }

    .job-summary-decision {
      background-color: @job-summary-approved-color;
    }
  }

  &.refused {
    .job-summary-action {
      font-weight: bold;
    }
    
    hr {
      border-color: @job-summary-refused-color;
    }

    .job-summary-decision {
      background-color: @job-summary-refused-color;
    }
  }

  &.no-decision {
    hr {
      border-color: @job-summary-no-decision-color;
    }

    .job-summary-decision {
      background-color: @job-summary-no-decision-color;
    }

  }

  hr {
    border-top: 3px solid;
    margin: 10px 0 0 0;
    border-color: @item-status-text-default-color;
  }

  .job-summary-decision {
    color: white;
    font-weight: 500;
    padding: 10px;
    border-radius: 10px;
    background-color: @item-status-text-default-color;

    .flex-container;
    .flex-space-between;

    .eva-tooltip {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 7px);

      &:before {
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        border-bottom: 8px solid #000000;
        border-top: none;
      }
    }

    .job-summary-time-wrapper {
      margin-left: 60px;
    }

    .job-summary-time {
      max-width: 300px;
      width: auto;
    }

    div:nth-child(1) {
      .flex-item;
      .flex-container;

      span {
        .flex-item;
      }
    }
  }
}

.job-feedback {
  color: @gray-mike;
  margin-top: 20px;
  padding: 0px 9px;
  .flex-container;
  .flex-align-item-middle;
  flex-wrap: wrap;

  > div:first-child {
    color: @gray-6;
    width: 100px;
    display: inline-block;
    margin: 0 -3px 8px 0;
  }

  div.job-feedback-item {
    height: auto;
    min-height: 30px;
    color: @gray-darker;
    display: inline-block;
    margin: 0px 15px 8px 0px;
    background-color: @gray;
    padding: 0px 10px;
    border-radius: 100px;
    .flex-container;
    .flex-align-item-middle;
  }
}

decision-summary {
  hr {
    margin: 15px 20px 0px 20px;
  }
}

.job-summary-action__icon {
  margin-right: 5px;
}


.decision-summary-icon {
  margin-right: 3px;
}