@stat-line-chart-stroke-color: @gray-6;
@stat-line-chart-fill-color: @gray-base;

.stat-line-chart {
  path {
    stroke: @stat-line-chart-stroke-color;
    stroke-width: 1;
    fill: none;
    &.area {
      fill: @stat-line-chart-fill-color;
      stroke: @stat-line-chart-fill-color;
    }
    &.average-line {
      stroke: black;
      stroke-opacity: 0.1;
    }
  }

}

