.eva-button {
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border:none;
    padding: 0;

    &__primary {
        background-color: @brand-third;
        color: @white;

        &:hover {
            background-color: @eva-primary-button-hover-background-color;
          }

        &:focus {
            outline: 0;
          }

        &--disabled {
            cursor: @cursor-disabled;
            background-color: @gray-1;
            color: @gray-2;
          }
    }

    &__secondary {
        color: @black;
        background-color: @white;

        &:hover {
            background-color: @gray-1;
          }

        &:focus {
            outline: 0;
          }

        &--disabled {
            cursor: @cursor-disabled;
            background-color: @white;
            color: @gray-2;
          }
    }

    &__text {
        margin: 0 32px;
    }

      
    &.height-as-parent {
      height: 100%;
    }

    &.width-as-parent {
      width: 100%;
      .eva-button__text {
        margin:auto;
      }  
    }
}


.center-content{
  display: flex;
  justify-content: center;
}

