.display-block {
    display: block;
  }
  
  
  .customStatusTextClassName{
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    padding-right: 3px;
  }
  