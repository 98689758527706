
@escalate-button-color: @gray-darker;

.eva-drop-down-with-search {
  border: 2px solid transparent;

  &.refuse:hover, &.escalate:hover {
    border-color: @brand-secondary;
  }

  &.refuse:hover button {
    background-color: @brand-danger;
    span {
      color: @white !important;
      border-color: @white !important;
    }
  }

  &.escalate:hover button {
    background-color: @escalate-button-color;

    span {
      color: @white !important;
      border-color: @white !important;
    }
  }
}

.eva-job-section-label {
  display: block;
  color: @dark-gray;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 13px;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: start;
}

.eva-job-decision {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;

  button {
    font-weight: 500;
  }

  .btn-approve.eva-decision-button {
    height: 48px;
    box-sizing: content-box;
    outline: none;
    border: 2px solid white;

    &.btn, &.btn:hover, &.btn:focus {
      background-color: @gray-decision-button;
      
      &:hover {
        border-color: @brand-secondary;
      }

      &.selected, &:hover, &.eva-keyboard-focused {
        background-color: @brand-success;
        color: @white;
      }

      &.is-disabled  {
        pointer-events: none;
        cursor: not-allowed;
        color: @refuse-button-disabled-text-color;
        background-color: @refuse-button-disabled-background-color;
        span.eva-shortcut-icon {
          color: @refuse-button-disabled-text-color;
          border-color: @refuse-button-disabled-text-color;
        }
        &.selected {
          color: @refuse-button-disabled-selected-text-color;
          background-color: @refuse-button-disabled-selected-background-color;
          span.eva-shortcut-icon {
            color: @refuse-button-disabled-selected-text-color;
            border-color: @refuse-button-disabled-selected-text-color;
          }
        }
      }
    }

    margin-right: 12px;
    span.eva-shortcut-icon {
      .eva-shortcut-icon-mixin(@brand-success);
    }

    &:hover .eva-shortcut-icon{
      color: @white;
      border-color: @white;
    }

    span:last-child {
      margin-right: 16px;
    }
  }

  .selected-decision-container {
    height: 24px;
    margin-top: 2px;
  }
  .selected-decision {
    text-align: center;
    color: @white;
    width: 100%;
    line-height: 24px;

    &.selected-decision-approve {
      background-color: @brand-success;
    }
    &.selected-decision-refuse {
      background-color: @brand-danger;
    }
    &.selected-decision-defer{
      background-color: @escalate-button-color;
    }
  }

  &.is-disabled .selected-decision {
    background-color: @refuse-button-disabled-selected-background-color!important;
    color: @refuse-button-disabled-selected-text-color!important;
  }

  .btn-approve.selected, .btn-approve.eva-keyboard-focused ,
  .eva-drop-down-with-search.selected, .eva-drop-down-with-search.eva-keyboard-focused{
    span.eva-shortcut-icon {
      color: @white;
      border-color: @white;
    }
  }

  .eva-decision-button {
    height: 48px;
    padding: 0;
    span {
      margin-left: 16px;
      display: inline-block;
      &:last-child {
        margin-right: 16px;
      }
    }

    span.eva-button-text {
      margin-left: 16px;
    }

    .eva-shortcut-icon + .eva-button-text {
      margin-left: 16px;
    }
  }
}


