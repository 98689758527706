.gallery-for-job-image {

    width: 100%;
    .remove-selection-color;
  
    .media-gallery {
      margin-left:3px;
      margin-top:10px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 2px;
      .media-item-container, .eva-video-item-wrapper {
        border: 1px solid @border;
        margin: 2px;
        min-width: 82px;
        min-height: 82px;
        display: inline-block;
        vertical-align: top;
      }
  
      .eva-video-item-wrapper {
        border: 1px solid @border;
      }
  
      .eva-video-item-wrapper {
        &.eva-video-item-wrapper--selected {
          border-color: @gray-darker;
        }
      }
        .media-item-container {
          .media-item-content-container {
            display: flex;
            align-items: center;
          }
          .media-item {
            border: none;
            width: auto;
            max-height: 100%;
            max-width:100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
  
          &.is-selected{
            .media-item-content-container{
              border-color: @gray-darker;
            }
          }
        }
  
    }
  }
  
  .media-viewer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      .media-viewer-nav-btn,  .media-item-actions {
        display: block;
      }
    }
  
    .media-viewer-nav-btn{
      display: none;
      position: absolute;
      z-index: 100;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.4;
      width: 38px;
      height: 38px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 25px ;
      &:hover{
        opacity: 1;
      }
  
      &.disabled{
        display: none;
      }
  
      &.media-viewer-nav-btn--prev{
        left:35px;
        background-image: url("../../../../../assets/icons/icon_gallery_arrow_left.png");
      }
      &.media-viewer-nav-btn--next{
        right:35px;
        background-image: url("../../../../../assets/icons/icon_gallery_arrow_right.png");
      }
    }
    .media-item-container {
      margin:0;
      height: 100%;
      width: 100%;
  
      display: none;
      &.is-selected {
        display: inline-block;
      }
      .media-item-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        height: 100%;
        width: 100%;
  
        .media-item {
          border: none;
          max-height: 100%;
          max-width:100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }