.app-assignment-automation-filterCreate, .app-assignment-automation-filterEdit  {
  .advanced-select-dropdown ul.select2-results, .advanced-select-dropdown.select2-drop-above ul.select2-results {
    max-height: 200px;
  }
}

.rule-condition{
  height: 38px;
  .rule-condition-del{
    padding: 12px 9px 0px 7px;
    font-size: 18px;
    color: @gray-darker;
    display: flex;
    .eva-tooltip-container--center();
    .eva-tooltip {
      top: 0px;
      width: fit-content;
      left: 0px;
      &:before {
        left: 46px;
      }
    }
  }

  .advanced-select-container a.select2-choice, .advanced-select-container.select2-drop-above a.select2-choice {
    height: 38px;
    line-height: 38px;
    span {
      max-width: 570px;
    }
  }

  .eva-select-list ul  {
    overflow-y: auto;
    max-height: 200px;
    li {
      line-height: 28px;
      height: 28px;
    }
  }

  eva-advanced-select.rule-condition-item {
    .advanced-select-container a.select2-choice, .advanced-select-container.select2-drop-above a.select2-choice {
      width: 245px;
    }
  }

  .rule-condition-item{
    margin-right: 10px;
  }
  .dash{
    width: 20px;
    border-bottom: 1px solid @gray-dark;
    vertical-align: top;
    height: 20px;
  }

  textarea {
    height: @input-box-height;
    resize: vertical;
    max-height: 100px;
  }

  .rule-value {
    .flex-container;
    flex-direction: column;
    width: 100%;
    textarea.ng-valid, textarea.valid {
      border-color: @gray-eva-light;
    }
    textarea:focus {
      border-color: @input-border-focus;
    }
    &.rule-value-invalid.submitted {
      textarea {
        border-color: @brand-danger;
      }
    }
  }
}

.rule-criteria-dropdown {
  width: auto!important;
  max-width: 1000px;
}