.csv-file-upload {

  .btn{
    width:140px;
    margin-top:40px;
  }

  .linkWrapper{
    text-align: center;
    margin-top:64px;
    margin-bottom: 40px;
    div{
      font-size: 16px;
      font-weight: 500;
    }
    a {
      display: block;
    }
  }

}
