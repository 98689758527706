.eva-confirm-revert-buttons-container {

  i {
    height: 26px;
    width: 26px;
    display: inline-block;
    font-size: 20px;
    border-radius: 13px;
    position: relative;

    &:hover {
      color:#fa005a;
      cursor: pointer;
    }
  }
}