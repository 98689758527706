#invite-user{
  width: 100%;
  font-family: Roboto;
  font-size: 24px;
  line-height: 1.3;
  color: @gray-darker;
  margin: 20px;
  
  .label-text {
    width: 364px;
    height: 48px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.5;
    color: @gray-darker;
  }
}


