@media-gallery-image-height: 84px;
@media-gallery-image-width: 84px;
@media-gallery-height: 150px;
@button-disabled-opacity: 0.4;
@button-inactive-opacity: 0.7;
@button-active-opacity: 1;

.media-gallery-modal {
  width: 100%;
  height: 100%;
  min-width: 1200px;

  .eva-video-item-wrapper {
    box-sizing: content-box;
    &.eva-video-item-wrapper--selected {
      border-color: #212121;
    }
  }

  &.fade .modal-dialog {
    .translate(0, 0); // override Bootstrap's default animation
  }
  .modal-dialog,
  .modal-content,
  .media-gallery-modal-container {
    z-index: 1031;
    width: 100%;
    height: 100%;
    margin: 0px;
    
  }

  .media-gallery-modal-container {
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    background: transparent !important;
  }

    .media-gallery-modal-container {
      background: transparent;

      .modal-close-btn {
        margin: 40px;
        right: 0px;
        top: 0px;
        width: auto;
        height: auto;
      }

      .remove-selection-color();

      .media-viewer {
        position: relative;
        cursor: default !important;

        .media-item-container,
        .main-video-container {
          background-color: black;
        }

        .media-item-container.is-deleted .media-item,
        .main-video.is-deleted {
          opacity: 1;
        }

        .media-item-container {
          display: none;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &.is-selected {
            display: inline-block;
          }

          .media-item-content-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            .media-item {
              background: unset;
              cursor: default;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }

      .media-gallery {
        display: flex;
        justify-content: center;
        position: relative;
        height: @media-gallery-height;
        background: @white;
        width: 100%;

        .item-group-container {
          display: flex;
          max-width: 920px;
          padding: 15px 0;
          max-height: 100%;
          overflow: hidden;
          margin: auto 0px;

          .slick-slide {
            outline: none;
          }
        }

        .media-item-container {
          display: inline-block;
          height: @media-gallery-image-height!important;
          width: @media-gallery-image-width!important;
          border: 2px solid @gray-base;
          &.is-selected {
            border-color: @gray-darker;
          }
          vertical-align: top;

          .media-item-content-container {
            width: 100%;
            height: 100%;
            border-radius: 2px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .media-item {
            border: none;
            width: auto;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        #btn-next,
        #btn-prev {
          display: inline-block;
          font-size: 43px;
          color: @brand-primary;
          cursor: pointer;
          margin: auto 15px;

          &.slick-disabled {
            visibility: hidden;
          }
        }
      }
    }
  }


#media-viewer-btn-prev,
#media-viewer-btn-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1032; // button should be above the image
  color: @white;
  opacity: @button-inactive-opacity;

  &:hover {
    opacity: @button-active-opacity;
  }

  &.disabled,
  &disabled:hover {
    opacity: @button-disabled-opacity!important;
    cursor: default;
    background-color: transparent;
  }

  img {
    width: 28px;
    height: auto;
    padding: 0 2px;
  }
}

#media-viewer-btn-prev {
  padding-left: 40px;
  left: 0;
  img {
    transform: rotate(180deg);
  }
}

#media-viewer-btn-next {
  padding-right: 40px;
  right: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
