@eva-switch-box-height: 18px;
@eva-switch-box-width: 44px;
@eva-switch-circle-size: 12px;
@eva-switch-text-margin: 7px;
.eva-switch-box {
    width: @eva-switch-box-width;
    height: @eva-switch-box-height;
    border-radius: (@eva-switch-box-height / 2);
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.eva-switch-box__value {
    position: absolute;
    display: flex;
    right: 0px;
    left: -@eva-switch-box-width;
    transition: all .2s ease-in .2s;
    &.eva-switch-box__value--checked {
        left: 0px;
    }
}

.no-animation{
    transition: none !important;
}

.eva-switch-box__on, .eva-switch-box__off {
    width: @eva-switch-box-width;
    height: @eva-switch-box-height;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: white;
    font-weight: bold;
    flex-shrink: 0;
    justify-content: space-between;
}

.eva-switch-box__on {
    background: @brand-third;
    .eva-switch-box__text {
        margin-left: @eva-switch-text-margin;
    }
}

.eva-switch-box__off {
    background: @gray-dark;
    .eva-switch-box__text {
        margin-right: @eva-switch-text-margin;
    }
}

.eva-switch-box__circle {
    width: @eva-switch-circle-size;
    height: @eva-switch-circle-size;
    border-radius: (@eva-switch-circle-size / 2);
    background-color: white;
    margin: auto ((@eva-switch-box-height - @eva-switch-circle-size) /2);
}