.eva-keyboard-layout {
    height: 643px;
    width: 533px;
    padding: 40px 26px;
  
    h2 {
      margin: 0 0 0 16px;
      font-weight: 300;
      line-height: 36px;
    }
  
    p {
      color: @gray-darker;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      margin: 8px 0 0 16px;
    }
  
    table {
      margin-top: 28px;
      &.table-striped > tr:nth-of-type(odd) {
        background-color: @gray-24;
      }
  
      &.table-striped > tr:nth-of-type(even) {
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
  
    th, td {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
    }
  
    td:nth-child(1){
      padding: 16px;
    }
  
    th {
      color: @brand-secondary;
      border-bottom: 2px solid @gray;
      padding: 0px 16px 2px 16px;
    }
  
    td:nth-child(1) {
      width: 315px;
    }
    td:nth-child(2) {
      width: 166px;
      padding-left: 16px;
    }
  
  }

  .custom-modal-style {
    background-color: white;
  }
  