@moderation-notes-indicator-size: 48px;

.eva-job__indicators__container {
  border: 1px solid @gray-26;
  border-top: 0px;
  padding: 30px;
  gap: 20px;
  display: flex;
  flex-direction: column;

  .item-detail-field {
    .item-detail-field-value-container-with-chips,
    .item-detail-field__value-link-internal,
    .eva-job-key-value-item__value-link,
    .item-detail-field__value-div,
    .external-link,
    .eva-job-key-value-item__value-disabled-text {
      margin-top: 5px;
      padding: 0 8px;
      line-height: unset;
    }
  }
}

.eva-job__indicators {
  display: flex;
  justify-content: center;

  :nth-child(1) {
    border-radius: 10px 0 0 0;
  }

  :nth-child(2) {
    border-radius: 0 10px 0 0;
  }
}

.eva-job__section-button {
  position: relative;
  flex-shrink: 0;
  padding: 5px 10px;
  background-color: @gray;
  flex: 1 1 0px;
  color: @black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &.active {
    background-color: @brand-third;
    color: white;
    fill: white;
    font-weight: bold;
  }

  &:not(.active):hover {
    background-color: @gray-1;
  }

  cursor: pointer;
}

.eva-job__section-button__icon {
  font-size: 20px;
}

.eva-job__section-button__count {
  display: none;
  position: absolute;
  background-color: @black;
  width: 28px;
  height: 18px;
  line-height: 18px;
  border-radius: 50px;
  right: 10px;
  top: (-18px / 2);
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;

  &.eva-job__section-button__count--active {
    display: flex;
  }
}