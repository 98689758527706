
.clickable-hover-cursor{
    &:hover {
        cursor: pointer;
    }
}

.single-dropdown {
    width: 177px !important;
    z-index: 2;
}

