@eva-select-dropdown-item-height: @input-height;
@eva-select-dropdown-item-padding: 8px 16px;
.eva-select-dropdown {
  position: relative;
  height: @eva-select-dropdown-item-height;

  &.eva-select-dropdown--has-error {
    .eva-select-dropdown__selected-item {
      border-color: @brand-danger;
      color: @brand-danger;
    }
  }
}

.eva-select-dropdown__selected-item {
  height: @eva-select-dropdown-item-height;
  border: 1px solid @border;
  padding: @eva-select-dropdown-item-padding;
  cursor: pointer;
  font-weight: bold;
  display: flex;
}

.eva-select-dropdown__selected-item__text {
  flex-grow: 1;
  .truncated();
}

.eva-select-dropdown__selected-item__icon {
  flex-shrink: 0;
}

.eva-caret--down {
  .implioicons-arrow-down();
}

.eva-caret--up {
  .implioicons-arrow-down();
  transform: rotate(180deg);
}

.eva-select-dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid @border;
  margin-top: -1px;
  z-index: 1;
  background: white;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .16);
}

.eva-select-dropdown__menu__item {
  height: @eva-select-dropdown-item-height;
  padding: @eva-select-dropdown-item-padding;
  cursor: pointer;

  &:hover {
    background-color: @gray-17;
  }

  &.disabled {
    cursor: not-allowed;
    color: @gray-5;
  }
}
