.has-failed-to-download-image-text {
  display: none;
}

.media-item-container {
  position: relative;
  padding: 0;

  .media-item-text {
    position: absolute;
    top: ~"calc(50% + 31px + 5px)";
    width: 100%;
    text-align: center;
  }

  .eva-bounding-box-container {
    z-index: 2000;
    pointer-events: none;
  }

  .eva-bounding-box-container-relative {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .eva-bounding-box-container-detection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .eva-bounding-box-container-detection {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .media-item-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    .media-item,
    canvas,
    .eva-bounding-box-container,
    .eva-media-item-actions-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      transition: opacity 0.3s ease-in-out;
      backface-visibility: hidden;
    }

    .eva-bounding-box-container {
      z-index: 1;
    }
  }

  &.is-deleted {
    .media-item {
      opacity: 0.2;
    }
  }

  &.is-selected.has-failed-to-download-image {
    .media-item-content-container {
      border-color: #212121;
    }
  }

  &.has-failed-to-download-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @gray;

    .media-item-content-container {
      background-color: #EFEFEF;
    }


    .has-failed-to-download-image-text {
      display: block;
    }

    .media-item-loading-animation-container {
      display: none;
    }
  }

  &.disable-loading-animation {
    .media-item-loading-animation-container {
      display: none;
    }
  }
}

.media-item-loading-animation-container {
  position: absolute;
  pointer-events: none;
}


.media-item-actions {
  margin: 0;
  background: black;
  position: absolute;
  right: 0px;
  top: 0px;
  display: none;
  pointer-events: all;
}

@media-item-action-size: 40px;

.media-item-action {
  border: 3px solid black;
  background-color: black;
  color: white;
  border-radius: 0;
  width: @media-item-action-size;
  height: @media-item-action-size;
  display: block;
  text-align: center;
  font-size: 20px;
  position: relative;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: gray;
  }

  .eva-tooltip {
    left: calc(100% + 11px);
    top: 0;

    &:before {
      top: 50%;
      left: 0px;
      transform: translate(-100%, -50%);
      border-right: 8px solid #000000;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  &:not(:last-child):not(:first-child) {
    border-top: none;
    border-bottom: none;
  }
}

.eva-media-item-actions-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.media-item-content-container:not(.has-failed-to-download-image):hover {
  .media-item-actions {
    display: block;
  }
}



.failed-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}








.media-item-container-xd {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}


.media-item-xd {
  position: relative;
  width: 100%;
  height: 100%;
}

.media-image-xd {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
}



.media-gallery {
  .media-item-container-xd {
    border: 1px solid @border;

  }
}





.media-gallery-modal-container {
  .media-viewer {
    .media-item-container {
      .media-item-content-container {
        .eva-bounding-box-container {
          z-index: 2000;
        }
      }
    }
  }
}