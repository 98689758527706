@no-data-container-width: 448px;
.no-data {
    box-shadow: 0 1px 3px 1px rgba(0,0,0,0.20);
    box-sizing: content-box;
    background-color: @gray-light;
    border: 1px solid @border;
    width: @no-data-container-width;
    height: 280px;
    margin: 40px auto;
    > div {
      padding: 0 30px;
      h2 {
        margin-top: 10px;
        font-weight: 500;
      }
      p {
        margin-bottom: 30px;
        color: @gray-karl;
      }
      .links-container {
        .flex-container;
        .flex-space-between;
        a {
          color: @brand-third;
          font-weight: bold;
        }
      }
    }
    img {
      margin-top: 21px;
      width: @no-data-container-width;
      height: auto;
    }
  }