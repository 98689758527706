
.eva-secondary-button {
  height: 48px;
  font-weight: 500;
  color: @black;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.height-as-parent {
    height: 100%;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    color: @black;
    background-color: @gray-1;
  }

  &.eva-secondary-button--disabled {
    cursor: @cursor-disabled;
    background-color: @white;
    color: @gray-2;
  }
}

.eva-secondary-button__text {
  margin: 0 32px;
}
