.app-passwordReset, .app-verifyToken, .app-newPassword, .app-inviteUserTokenVerify, .app-createTeam, .app-auth0ErrorPage {

  #notifier {
    width: auto;
  }

  padding: 62px 0px;
  background: none;
  width: 100%;
  min-width: 0px!important;

  div[data-ui-view="main"] {
    width: 100%;
    height: 100%;
  }

  .start-background {
    width: 100%;
    height: 100%;
    .flex-container;
  }

  .start-container {
    align-self: center;
    padding: 40px 20px;
    text-align: center;
    margin: auto;
    background-color: @start-container-bg-color;

    .bar {
      display: inline-block;
      position: relative;
      width: 100%;
      border-bottom: 1px solid @gray-28;
      content: ' ';
      height: 1px;
      top: -16px;
    }

    .input.input--nao.input--filled {
      .bar {
        border-bottom: 1px solid @start-color-primary;
      }
    }

    .input {
      position: relative;
      z-index: 1;
      display: inline-block;
      width: 100%;
      vertical-align: top;
    }

    .input__field {
      position: relative;
      display: block;
      float: right;
      padding: 0.8em;
      width: 60%;
      border: none;
      border-radius: 0;
      background: @gray-10;

      color: @gray-29;
      font-size: @font-size-h1;
      appearance: none;
    }

    .input__field:focus {
      outline: none;
    }

    .input__label {
      display: inline-block;
      float: right;
      padding: 0 1em;
      width: 40%;
      color: @gray-30;
      font-weight: bold;
      font-size: 70.25%;
      user-select: none;
    }

    .input__label-content {
      position: relative;
      display: block;
      padding: 1.7em 0;
      width: 100%;
    }

    .input--nao {
      overflow: hidden;
      padding-top: 1em;
    }

    .input__field--nao {
      padding: 0.5em 0;
      width: 100%;
      background: transparent;
      color: @gray-23;
      font-size: 1.25em;
    }

    .input__label--nao {
      color: @gray-28;
      position: absolute;
      top: 0.6em;
      font-size: @font-size-base;
      text-transform: uppercase;
      left: 0;
      display: block;
      width: 100%;
      text-align: left;
      padding: 0;
      pointer-events: none;
      transform-origin: 0 0;
      transition: transform 0.2s, color 0.5s;
      transition-timing-function: ease;
    }

    .input__field--nao:focus + .input__label--nao,
    .input--filled .input__label--nao {
      color: @start-color-primary;
      transform: translate3d(0, -2em, 0) scale3d(0.9, 0.9, 1);
    }

    .error-container {
      display: block;
      min-height: 14px;
    }

    .alert-error {
      visibility: hidden;
      display: none;
    }

    .alert-error.has-error {
      visibility: visible;
      display: block;
    }
    form.ng-invalid input[type="submit"] {
    }

    .start-title {

      .small {
        font-size: @font-size-h3;
        text-transform: uppercase;
        display: block;
      }
    }

    .alert.alert-error {
      color: @start-color-error;
      float: left;
      padding: 0;
      margin: -14px 0 0;
    }

    .start-input {
      font-size: @font-size-h3;
      height: 35px;
      margin-bottom: @start-margin-normal;
    }

    .btn-start {
      color: @white;
      background-color: @start-color-primary;
      margin: @start-margin-normal 0;
      font-size: @font-size-h3;
      font-weight: @font-weight-medium;
      padding: 20px;
    }

  }
}

@media only screen and (min-width : 768px) {
  .app-passwordReset, .app-verifyToken, .app-newPassword, .app-inviteUserTokenVerify, .app-createTeam, .app-auth0ErrorPage {
    background: @start-bg-color url("../../../assets/start_background.png") no-repeat fixed center center;
    background-size: cover;

    .start-container {
      padding: @start-margin-normal*2 80px @start-margin-normal 80px;
      width: @start-container-width;
      box-shadow: 0 4px 4px 0 rgba(0,0,0,0.21);
      border-radius: 4px;
      width: 620px;
    }
  }
}

.nav-link {
  font-weight: @font-weight-medium;
  font-size: @font-size-base;
  .eva-link-primary(@start-link-color, @start-link-color);

}