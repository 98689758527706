.more-info-tooltip {
  color: @brand-primary;
  font-size: 22px;

  &.popover {
    width: 180px;
    padding: 0;

    &.bottom > .arrow {
      &:after {
        border-bottom-color: @tooltip-bg;
      }
    }
    .popover-title {
      width: 100%;
      font-size: 12px;
      line-height: inherit;
      background-color: @tooltip-bg;
      border-bottom: 0px;
      border-radius: 2px 2px 0 0;
      color: @white;
    }
    div.popover-content {
      &:empty {
        display: none;
      }
    }
    .popover-content{
      font-size: 12px;
      width: 100%;
      border-radius: 0 0 2px 2px;
      background-color: @tooltip-link-bg;

      .link {
        .eva-link-secondary(white, white);
      }
    }
  }
}
