.csv-preview{
  .title{
    font-size: 20px;
    text-align: center;
    color: @gray-darker;
    line-height: 28px;
    padding: 0 0 40px;
  }

  table.table{
    display: block;
    overflow-x: auto;
    margin-bottom:0;

    tbody{
      display: block;
      th,td{
        min-width: 200px;
        max-width: 500px;
        display: table-cell;
        text-align: left;
        height: 35px;
      }
      td.more-text{
        color: @gray-mike;
        padding-left: 20px;
        line-height:40px;
        border:0 none;
        background: @white!important;
      }
      tr.more-text-tr:hover{
        background: @white!important;
      }
    }


  }
}
