body.app-assignment-manual-queue {
  background: @gray-1;
}

@process-circle-select-border-width: 2px;
@process-bar-wrapper-height: 60px;

.eva-jobs {
  margin-bottom: 100px;
}

.process-bar-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-width: @container-width;
  min-height: 50px;
  background: @white;
  z-index: @manual-moderation-progress-bar-z-index;
  border-bottom: 1px solid @gray-11;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.16);

  .process-bar-center {
    margin: 0 auto;
    position: relative;

    .long-tooltip + .tooltip .tooltip-inner {
      max-width: 100px;
      width: 99px;
    }

    #progress-bar-and-submit-button {
      float: right;
      margin-top: 3px;
      .flex-container;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .eva-jobs-progress-bar {
        margin-right: 9px;
      }
    }

    #open-keyboard-help {
      margin-right: 20px;
      opacity: 0.5;
      font-size: 23px;

      &:hover {
        opacity: 1;
      }
    }

  }

  @keyframes submit-button-pulse {
    70% {
      box-shadow: 0 0 0 20px rgba(250, 0, 90, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(250, 0, 90, 0);
    }
  }

}


.moderation-queue__submit-button {
  display: inline-block;

  .tooltip-inner {
    max-width: 120px;
    width: 120px;
  }

  button {
    color: @white;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    outline: none !important;
    background-color: @brand-third;
    height: 44px;
    width: 99px;
    border-radius: 22px;
    border: 2px solid transparent;
    text-transform: capitalize;
  }

  button.eva-keyboard-focused {
    box-shadow: 0 0 0 0 rgba(250, 0, 90, .3);
    animation: submit-button-pulse 1.5s infinite;
  }

  button:hover {
    animation: none;
  }

  button[disabled] {
    /* don't let button block mouse events from reaching wrapper */
    pointer-events: none;
    background-color: @gray-12;
    animation: none;
  }
}

#queue-info {
  display: inline-block;
  margin-top: 10px;
}

.queue-info__label {
  display: block;
  color: @dark-gray;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 13px;
  text-transform: uppercase;
}

.queue-info__name {
  display: block;
  color: @gray-darker;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  .truncated;
  max-width: 335px;
}
