.loading-tracker {
  .center(both);

  //font-size: 21px;
  text-align: center;
  position: fixed;
  z-index: 1;
}

.animate-show {
  opacity: 1;
}

.animate-show.ng-hide-add,
.animate-show.ng-hide-remove {
  transition: all linear 0.25s;
}

.animate-show.ng-hide {
  opacity: 0;
}
