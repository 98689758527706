.eva-team-picker {

  transition: all 0.25s;
  z-index: @eva-navbar-primary-dropdown-z-index;
  width: 389px;
  background: @white;
  position: absolute;
  left: 0;
  top: 46px;
  color: @black;
  box-shadow: 0 2px 3px 1px rgba(0,0,0,0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  opacity: 0;
  max-height: 0px;
  visibility: hidden;

  &.display {
    visibility: visible;
    opacity: 1;
    max-height: 600px;
  }

}  
  .team-picker-list {
    margin: 0;
    flex:0 1 auto;
  }

  .eva-team-picker-input-container {
    margin: 20px 15px 11px;
    border-radius: 16px;
    height: 32px;
    float:left;
    background: @gray;
    vertical-align: top;
    input {
      vertical-align: top;
      height: 32px;
      background-color: transparent;
      border: none;
      outline: none!important;
      padding-left: 0px;
      width: 85%;
    }
    .eva-team-picker-input-container-icon {
      vertical-align: middle;
      font-size: 22px;
      margin-left: 9px;
      color: @gray-2;
    }
  }


  .team-picker-list {
    overflow-y: auto;
    flex:1 1 auto;
    margin-bottom: 20px;
    position: relative;
  }

  .team-picker-list-item__team-name {
    .flex-item;
    padding-right: 10px;
    &:focus, &:hover {
      color: @black;
      text-decoration: none;
    }
  }

  .team-picker-list-item__team-count {
    cursor: default;
    height: 16px;
    padding: 0px 9px;
    line-height: 16px;
    color: @dark-gray;
    font-size: 12px;
    text-align: center;
    border-radius: 7.5px;
    background-color: @gray-8;
    position: relative;

    &.team-picker-list-item__team-count--non-zero {
      background-color: @brand-third;
      color: white;
    }
  }

  .team-picker-list-item {
    position: relative;
    padding: 0 15px 0 50px;
    width: 100%;
    height: 34px;
    line-height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.is-team-selected {
      .team-picker-list-item__team-name {
        color: @brand-secondary;
        font-weight: bold;
      }
      .implioicons-team();
      &:before {
        position: absolute;
        left: 20px;
        top: 7px;
        width: 20px;
        height: 16px;
        color: @brand-secondary;
        font-size: 20px;
      }
    }

  }

.team-picker-list-focused {
  font-weight: 500;
  cursor: pointer;
  background-color: @gray;
  .team-picker-list-item__team-name {
    color: @black;
    text-decoration: none;
  }

  .team-picker-list-item__team-count:not(.team-picker-list-item__team-count--non-zero) {
    background-color: @white;
  }
}

