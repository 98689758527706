
.item-detail-field {
  .eva-job-key-value-item;
  position: relative;
  margin-bottom: 3px;

  input {
    height: 28px;
  }

  .item-detail-field__value-div {
    line-height: 28px;
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .item-detail-field__label {
    .eva-job-key-value-item__label;
    text-transform: capitalize;
    line-height: 28px;
  }

  .item-detail-field__label-customer-specific {
    .eva-job-key-value-item__label;
    text-transform: capitalize;
    margin-top: 5px;
  }

  .item-detail-field-value-container {
    width: 100%;
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .item-detail-field-value-container-with-tootip {
    .item-detail-field-value-container;
    overflow: visible;
  }

  .item-detail-field-value-container-with-chips {
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    margin-bottom: 3px;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .item-detail-field__value--disabled {
    .eva-job-key-value-item__value-disabled-text;
    padding: 0 8px;
    min-height: 30px;
    line-height: 28px;
    border: 1px solid transparent;

    .eva-tooltip {
      left: -60%;
      top: 130%;
    }

    .eva-tooltip:before {
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      border-bottom: 8px solid #000000;
      border-top: none;
    }

  }

  .item-detail-field__value-link {
    .eva-job-key-value-item__value-disabled-text;
    .eva-job-key-value-item__value-link;
    color: #fa005a;
    line-height: 28px;
    padding: 0 8px;
    border: 1px solid transparent;
    .externalLinkHover;

    &:visited {
      color: #fa005a;
    }

    &:focus {
      color: #fa005a;
    }

    &:active {
      color: #fa005a;
    }

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .item-detail-field__value-link-internal {
    .eva-job-key-value-item__value-link;
    color: #3C82E6;
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .matched-rules {
      .more-rules-block {
        line-height: normal;
      }
    }
  }

  .item-detail-field__value-link-with-chips {
    .eva-job-key-value-item__value-link;
    color: #3C82E6;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;


  }

  .item-detail-field__value-link-username {
    .eva-job-key-value-item__value-link;
    color: #3C82E6;
    line-height: 28px;
    display: block;
    max-width: calc(100% - 5px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.item-detail-field, .item-detail-field-edit {
  .eva-job-key-value-item__icon-container {
    display: none;
    .eva-item-hover-show-icons();
  }
}

.item-detail-field-edit {
  .eva-job-key-value-item;

  .eva-job-key-value-item__icon-container {
    margin-top: 4px;
  }
  
  input {
    height: 30px;
    padding: 0 8px;
    line-height: 30px;
  }

  select {
    width: calc(100% - 70px);
    height: 28px;
    margin-top: 4.5px;
    border-color: #d8dee2;
  }

  .edit-input {
    display: flex;
    width: 100%;

    textarea {
      padding: 0 8px;
      min-height: 28px;
      line-height: 28px;
    }

    .eva-confirm-revert-buttons-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      position: absolute;
      right: 0;
      gap: 10px;
      top: -24px;
      height: 31px;
      background: white;
      padding: 5px 10px;
      border: 1px solid gainsboro;
      border-radius: 30px;
      z-index: 1;

      i {
        font-size: 17px;
        width: 18px;
        height: fit-content;
      }
    }
  }

  .textarea-container {
    display: flex;

    textarea {
      width: calc(100% - 30px);
    }

    .textarea-buttons-container {
      width: 30px;

      .eva-confirm-revert-buttons {
        .eva-confirm-revert-buttons-container {
          width: 26px;
          vertical-align: top;
          height: 38px;
          bottom: 45%;

          i {
            height: 14px;
          }
        }
      }
    }
  }

  .item-detail-field__label {
    .eva-job-key-value-item__label;
    text-transform: capitalize;
    line-height: 28px;
  }

  .item-detail-field__label-customer-specific {
    .item-detail-field__label;
  }

  .item-detail-field__value--disabled {
    .eva-job-key-value-item__value-disabled-text;

    margin-top: 0px;
    line-height: 28px;
  }

  .eva-select-dropdown {
    height: 30px;
    width: 100%;
  }

  .eva-select-dropdown__selected-item {
    height: 30px;
    line-height: 28px;
    padding: 0 8px;
  }

  .item-detail-field__value-link {
    .eva-job-key-value-item__value-link;
    line-height: 28px;
    color: #fa005a;
    .externalLinkHover;

    &:visited {
      color: #fa005a;
    }

    &:focus {
      color: #fa005a;
    }

    &:active {
      color: #fa005a;
    }
  }

  .item-detail-field__value-link-internal {
    .eva-job-key-value-item__value-link;
    color: #3C82E6;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .matched-rules {
      .more-rules-block {
        line-height: normal;
      }
    }
  }

  .item-detail-field__value-link-username {
    .eva-job-key-value-item__value-link;
    color: #3C82E6;
    line-height: 28px;
  }


  .item-detail-field-value-container {
    background-color: @gray-1;
    width: 100%;
    padding: 0 8px;
    cursor: text;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 30px;
    line-height: 28px;
    border: 1px solid #ccc;
    display: flex;
  }

  .item-detail-field-value-container-with-tootip {
    .item-detail-field-value-container;
    overflow: visible;
  }

  .item-detail-field-value-container-with-chips {
    .item-detail-field-value-container;
    flex-shrink: 1;
    min-width: 0;
    align-items: center;
    justify-content: space-between;
  }

  position: relative;
  // remove margin-bottom from the field
  // margin-bottom: 15px;

}

.item-detail-field:hover, .item-detail-field-edit:hover {
  background-color: @gray-1;

  &.item-detail-field-edit {
    background-color: transparent;
  } 

  .eva-job-key-value-item__icon-container {
    border: 2px solid #212121;
    background-color: #212121;
    display: flex;

    .implioicons-copy-large {
      background-color: #212121;
    }

    .quick-add-to-list__icon {
      background-color: @black;
    }

    .quick-add-to-list--open {
      .quick-add-to-list__icon {
        background: white;
        color: @black;
      }
    }

    .eva-tooltip {
      top: -47px;
      left: 50%;
      transform: translate(-50%, 7px);

      &:before {
        top: 23px;
        left: 50%;
        transform: translate(-50%, 100%);
        border-bottom: none;
        border-top: 8px solid #000000;
      }

    }
  }
}


