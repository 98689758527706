@eva-job-view-mode-padding: 9px;
.eva-job-text-area {
  position: relative;
  border-bottom: 1px solid transparent;
  textarea.form-control{
    padding: 9px;
    border-color: @gray-eva-light;
    &:focus {
      border-color: @gray-mike;
    }
  }

  > div {
    overflow: auto;
  }

  .value-container {
    width: 100%;
  }

  .icon {
    margin-left: 5px;
    height: 15px;
    text-align: right;
    visibility: hidden;
    color: @gray-mike;
  }

  &.eva-job-text-area--view {
    border: 1px solid transparent;

    div.plain-text {
      width: 100%;
      white-space: pre-wrap;
      padding: @eva-job-view-mode-padding;
      word-break: break-word;
      > span {
        width: 100%;
      }
      span {
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }

    &:not(.eva-job-text-area--disabled) {
      border: 1px solid @gray-11;
      background-color: @gray-1;
      cursor:text;
    }
  }


  &.eva-job-text-area--disabled {
    cursor: default;
    color: @gray-mike;
  }


  eva-confirm-revert-buttons {
    position: absolute;
    bottom: 0px;
    right: -29px;

    .eva-confirm-revert-buttons-container {
      width: 26px;
      i {
        cursor: pointer;
        visibility: hidden;
        margin-left: -13px;
        opacity: 0;
        transition: all 0.25s;
        vertical-align: bottom;
        margin-top: 3px;
        right: 6px;
      }
    }


    &.show {
      .eva-confirm-revert-buttons-container {
        i {
          visibility: visible;
          opacity: 1;
          margin-left:0px;
        }
      }

    }
  }
}

.eva-job-text-area-edit {
  margin-right: 0px;

  .item-detail-body& {
    margin-right: 27px;
    border-bottom: 0px;
  }
}


.eva-job-body-view-mode-placeholder {
  color: @gray-mike;
  padding: @eva-job-view-mode-padding;
  width: 100%;
}