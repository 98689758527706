.eva-copy-icon {
    .icon-style-1();
    display: block;
    position: relative;
    &:before {
        .absolute-center();
    }
    &:hover {
        .icon-style-1-hover-focus();
    }
}