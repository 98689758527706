.forbidden {
  text-align: center;
  margin-top: 50px;

  .forbidden-main-text {
    font-size: 24px;
    line-height: 32px;
    margin: 25px 0 5px 0;
    text-align: center;
  }
  p {
    text-align: center;
  }
  div {
    display: inline-block;
    margin: 0 auto;
    button {
      display: inline-block;
      margin: 20px 20px 0px 0px;
    }
  }

  img {
    width: 580px;
    margin: 0 auto;
  }

}
