@create-rule-view-label-font-weight: 500;
@rule-mode-margin-top: 30px;


rule-editor {
  .rule-editor-header {
    overflow: hidden;
    border-bottom: 1px solid @border;
    h3, .link-to-switch-mode {
      display: inline-block;
    }

    .link-to-switch-mode {
      margin-top: 30px;
      float: right;

      a.tooltips {
        position: relative;
        display: inline;
      }
      a.tooltips span {
        position: absolute;
        width: 140px;
        color: @white;
        background: #000000;
        line-height: 15px;
        font-size: 13px;
        text-align: center;
        visibility: hidden;
        border-radius: 6px;
        padding: 5%;
      }
      a.tooltips span:before { // the arrow part
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        margin-left: -8px;
        width: 0;
        height: 0;
        border-bottom: 8px solid #000000;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
      }
      a:hover.tooltips span {
        visibility: visible;
        opacity: 0.8;
        top: 25px;
        left: 50%;
        margin-left: -76px;
        z-index: 999;
      }
    }
  }

  label {
    font-weight: @create-rule-view-label-font-weight;
  }
  .rule-name-description {
    margin-top: 30px;
    .flex-container;
    .rule-name {
      flex: 1;
    }
    .rule-description {
      flex: 2;
      padding-left: 30px;
    }
    .rule-name, .rule-description {
      label, input {
        display: block;
        width: 100%;
      }
    }
    textarea#rule-description {
      max-height: 110px;
      resize: vertical;
    }
  }

  .status-switch-label {
    margin-top: 50px;
  }

  .rule-mode {
    margin-top: @rule-mode-margin-top;
    display: inline-block;
    width: 100%;
    label {
      width: 100%;
      border-bottom: 1px solid @border;
      padding-bottom: 5px;
    }
    &.advanced-mode-selected {
      width: 67%;
      label {
        border: none;
        padding-bottom: 0px;
      }
    }
  }

  filter-tester {
    display: inline-block;
    width: 30%;
    margin-top: @rule-mode-margin-top;
    vertical-align: top;
    float: right;
  }

  .decision-buttons {
    margin-top: 50px;
    display: flex;
  }

  .has-error .CodeMirror {
    border: 1px solid @red-2;
  }

  .advanced-select-container, .advanced-select-container.select2-drop-above {
    a.select2-choice {
      font-weight: 500;
    }
  }

}
