@refuse-button-disabled-background-color: @gray-1;
@refuse-button-disabled-text-color: @gray-14;
@refuse-button-disabled-selected-background-color: @gray-19;
@refuse-button-disabled-selected-text-color: @gray-2;
.eva-drop-down-with-search {

  vertical-align: top;
  display: inline-block;

  &.dropdown {
    .dropdown-menu {
      margin-top: 2px;
    }
  }

  .dropdown-menu {
    display: none;
  }

  &.open {
    .dropdown-menu {
      display: flex;
      flex-direction: column;
    }
  }

  button.btn {
    display: inline-block;
    outline: none!important;
    border: none;
  }

  .drop-down-menu-title {
    color: @dark-gray;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.55px;
    line-height: 13px;
    padding: 0 15px;
    margin-top: 21px;
  }
  .dropdown-menu{
    min-width: 250px;
    max-width: 500px;
    z-index: @reject-button-z-index;
    border: 1px solid @gray-8;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
    margin-bottom: 12px;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);

    .eva-dropdown-container {
      max-height: 275px;
      overflow-y: scroll;

      &.empty-list {
        height: 0px;
      }

      > li a {
        height: 32px;
        line-height: 32px;
        max-width: 500px;
        .truncated;
        color: @gray-darker;
        text-decoration: none;
        display: block;
        padding: 0px 15px;
        clear: both;
        font-weight: normal;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
      }
    }

    .eva-dropdown-arrow-cover{
      width: 100%;
      height: 15px;
      background-color: @white;
      position: relative;
    }
    .eva-dropdown-arrow {
      position: absolute;
      bottom: -7px;
      left: 50%;
      width: 15px;
      height: 15px;
      transform: translateX(-50%) rotate(45deg);
      background-color: @white;
      box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.20);
    }

    .eva-dropdown-hover-area {
      position: absolute;
      width: 100%;
      height: 13px;
      left: 0px;
      bottom: -13px;
      background: transparent;
    }
  }

}

.eva-drop-down-with-search-button(@selected-state-background-color) {
  button {
    background-color: @gray-decision-button;
  }

  &.selected, &.eva-keyboard-focused {
    button {
      background-color: @selected-state-background-color;
      color: @white;
    }
  }

  .focused .eva-shortcut-icon{
    color: @white;
    border-color: @white;
  }

  span.eva-shortcut-icon {
    .eva-shortcut-icon-mixin(@selected-state-background-color);
  }

  &.is-disabled  {
    pointer-events: none;
    button {
      cursor: not-allowed;
      color: @refuse-button-disabled-text-color;
      background-color: @refuse-button-disabled-background-color;
    }
    span.eva-shortcut-icon {
      color: @refuse-button-disabled-text-color;
      border-color: @refuse-button-disabled-text-color;
    }
    &.selected {
      button {
        color: @refuse-button-disabled-selected-text-color;
        background-color: @refuse-button-disabled-selected-background-color;
      }
      span.eva-shortcut-icon {
        color: @refuse-button-disabled-selected-text-color;
        border-color: @refuse-button-disabled-selected-text-color;
      }
    }
  }


}

.eva-drop-down-with-search.refuse  {
  .eva-drop-down-with-search-button(@brand-danger);
}

.eva-drop-down-with-search.escalate  {
  .eva-drop-down-with-search-button(@escalate-button-color);
  margin-left: 12px;
}

.eva-dropdown-caret {
  &:before {
    vertical-align: sub;
  }
}
