@generated-notice-color: @soft-red;
@generated-notice-transparency: 0.85;
@generated-notice-color-active: @light-red;
@generated-notice-transparency-active: 0.7;
eva-notices {
  flex-grow: 1;
  min-width: 0;
}

.notice-container {
  padding: 0px 9px;
  .flex-item;
  .flex-container;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 14px;
  line-height: 30px;
  max-width: 100%;
  min-width: 0;
  gap: 12px;

  .eva-tooltip {
    max-width: 100%;
  }
}

.implio-notice-icon {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: @white;
  display: inline-block;
  position: relative;
  flex-shrink: 0;
  &:before {
    font-size: 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    .absolute-center();
  }
}

.eva-generated-notice {
  color: @dark-blue;
  background-color: @generated-notice-color;
  opacity: @generated-notice-transparency;
  &.active {
    background-color: @generated-notice-color-active;
    opacity: @generated-notice-transparency-active;
  }
}


.eva-notice-icon {
  flex-shrink: 0;
  height: 32px;
  width: 32px;
  background: @gray;
  display: inline-block;
  color: @black;
  font-size: 18px;
  border-radius: 16px;
  position: relative;
  color: @gray-6;
  margin-right: 12px;
  &:before {
    .absolute-center();
  }
}

.eva-notice-container {
  flex-grow: 1;
  min-width: 0;
  display: flex;
  flex-direction: row;
}

.notice-message {
  margin: 0 16px 0 8px;
  line-height: 32px;
  vertical-align: bottom;
  .truncated();
  .flex-item;
  min-width: 0;
}

.eva-notice {
  border-radius: 16px;
  padding: 0 0 0 4px;
  height: 32px;
  color: black;
  font-weight: 500;
  .flex-container;
  align-items: center;
  max-width: 100%;
  min-width: 0;

  &.notice-good {
    background-color: @green-2;
    opacity: 0.85;
    i {
      .implioicons-check();
    }
  }
  &.notice-info {
    background-color: @light-orange;
    opacity: 0.85;
    i {
      .implioicons-exclamation();
    }
  }
  &.notice-problem {
    background-color: @bright-red;
    opacity: 0.85;
    i {
      .implioicons-cross();
    }
  }

  &.notice-critical {
    color: @white;
    background-color: @dark-magenta;
    opacity: 1;
    i {
      color: @dark-magenta;
      .implioicons-exclamation();
    }
  }
}