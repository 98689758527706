/* The Modal (background) */
.custom-sidebar {
  position: fixed; /* Stay in place */
  z-index: 1031; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
  overflow-x: hidden; /* Slide transition */
}

/* SideBar Title and close button */
.custom-sidebar-head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.custom-sidebar hr {
  width: calc(100% + 50px); /* twice the value of the parent's padding*/
  margin-left: -25px;
  height: 1px;

  margin-top: 15px;
  margin-bottom: 25px;
}

.custom-sidebar-footer {
  display: flex;
  gap: 1rem;
  margin-top: auto;
}

.custom-sidebar-head h3 {
  margin: 0;
}

/* SideBar Close Button */
.custom-sidebar-close {
  font-size: 24px;
  display: flex;
  cursor: pointer;
}

/* SideBar Content */
.custom-sidebar-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: auto;
  padding: 0;
  width: fit-content;
  min-height: 100%;
  max-width: 80%;
  background: white;
  -webkit-animation-name: animate-slide;
  -webkit-animation-duration: 0.4s;
  animation-name: animate-slide;
  animation-duration: 0.4s;
  right: 0px;
  padding: 25px;
  padding-top: 15px;
  overflow: hidden;
  height: 100%;
}

/* Add Animation */
@-webkit-keyframes animate-slide {
  from { opacity:0; transform: translateX(50%); }
  to { opacity:1; transform: translateX(0%); }
}

@keyframes animate-slide {
  from { opacity:0; transform: translateX(50%); }
  to { opacity:1; transform: translateX(0%); }
}