.more-rules-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    .eva-link-primary();
  }
  
  .rules-secondary-header div {
      .eva-word-break;
      overflow: auto;
  }
  
  .more-rules-block {
    cursor: pointer;
    color: @white;
    background-color: @brand-primary;
    padding: 0 6px;
    border-radius: 11px;
    margin-left: 9px;
    text-decoration: none!important;
    font-size: 14px;
    flex-shrink: 0;
    line-height: 21px;
  }
  .matched-rules-count-container {
    max-width: 100%;
    flex-direction: row;
  }
  .no-rule-container, .matched-rules-count-container {
    display: inline-flex;
    align-items: center;
  }