.data-storing-options {
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-side {
    h4 {
      margin-top: 40px;
      margin-bottom: 10px;
    }

    button.storing-option-button {
      height: 50px;
      width: 105px;
      color: @brand-third;
      border: 1px solid @brand-third;
      border-radius: 20px;
      outline: 0 !important;
      background-color: @white;
      flex: 1 0 30%;
      font-weight: 500;
      font-size: 15px;

      &.selected {
        color: @white;
        background-color: @brand-third;
      }
    }

    .btnWrapper {
      margin-top: 50px;
    }
  }
}

.pending-deletion {
  margin-top: 38px;

  .main-text {
    margin-top: 20px;
  }
}

.periods-container{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 532px;
  margin-top: 20px;
}