.csv-column-mappers-container{
  .flex-container;
  flex-direction: row;
  flex-wrap: wrap;

  .add-new-mapper {
    border-radius: 2px;
    height: 80px;
    width: @csv-column-mapper-width;
    padding: 20px;
    background-color: @white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);

    .eva-primary-button {
      height: 40px;
    }
  }

  .mapper-container {
    margin-right: 20px;
    margin-bottom: 20px;

    &:nth-child(4n+0) {
      margin-right: 0px;
    }
  }
}
