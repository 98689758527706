@process-circle-width: 30px;
@process-circle-height: 30px;

.eva-jobs-progress-bar {
  position: relative;
  margin-right: 9px;
  height: @process-circle-height;
  .process-circle {
    vertical-align: top;
    display: inline-block;
    width: @process-circle-width;
    height: @process-circle-height;
    line-height: @process-circle-height;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: @gray-12;
    text-decoration: none;
    color: @white;

    &.process-circle--refuse {
      background-color: @brand-danger;
    }
    &.process-circle--approve {
      background-color: @brand-success;
    }
    &.process-circle--defer {
      background-color: @gray-darker;
    }

  }


  .eva-jobs-progress-bar-overlay-mask {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(@process-circle-width  - 6px);
    height: calc(@process-circle-height - 6px);
    border-radius: 12px;
    overflow: hidden;
    pointer-events: none;
    transition: left 400ms;
    transform: translateZ(0);
  }

  .eva-jobs-progress-bar-overlay {
    transition: left 400ms;
    transform: translateZ(0);
    position: absolute;
    top: -3px;
    left: -3px;
    width: 740px;
    height: @process-circle-height;
    .process-circle {
      background: @white;
      color: @gray-12;

      &.process-circle--refuse {
        color: @brand-danger;
      }
      &.process-circle--approve {
        color: @brand-success;
      }
      &.process-circle--defer {
        color: @gray-darker;
      }
    }
  }

}

