.filter-edit {

  .loading-error {
    margin: 0 auto;
    margin-top: 64px;
    max-width: 40%;
    text-align: center;

    .link {
      .h4();
      color: @brand-primary;
      text-transform: uppercase;
      font-weight: @font-weight-bold;
      &:hover {
        color: @brand-primary-dark;
      }
    }
  }

}
