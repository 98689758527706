
.react-code-mirror-blang-mode {
  background-color: @gray-20;

  .cm-variable {
    color: @gray-darker;
  }

  .cm-list {
    color: @brand-primary;
  }

  .cm-keyword {
    color: @gray-darker;
    font-weight: 400;
  }

  .cm-boolean {
    color: @blue-2;
  }

  .cm-string {
    color: @green-5;
  }

  .cm-number {
    color: @orange;
  }

  .cm-comment {
    color: @gray-2;
  }

  .cm-regex {
    color: @purple-2;
  }
}
