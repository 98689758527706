.quick-add-to-list {
    position: relative;

    .eva-single-select-with-search-for-big-icon {
        position: absolute;
        right: -2px;
        color: black;
        width: 272px;
        border: 1px solid @gray-3;
        border-radius: 0;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
        background-color: white;
    }
}


.quick-add-to-list__icon {
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    .icon-style-1();
    &:before {
        .absolute-center;
    }
    &:hover {
        .icon-style-1-hover-focus();
    }
}

.quick-add-to-list--open {
    .quick-add-to-list__icon {
        background: white;
        color: @black;
    }
}
