@eva-loading-icon-size: 20px;
.eva-promise-status-indicator {
  width: @eva-loading-icon-size;
  height: @eva-loading-icon-size;
  display: inline-block;
}

.eva-promise-status-indicator__icon {
    font-size: 20px;
    width: @eva-loading-icon-size;
    height: @eva-loading-icon-size;
    position: relative;
    &:before {
      .absolute-center;
    }
    &.implioicons-check {
      color: @lime-green;
    }
    &.implioicons-warning {
      color: @brand-danger;
    }

    &.implioicons-loading {
      color: @gray-darker;
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }