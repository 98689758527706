.ng-table-pager.pager {
    color: @gray-2;
    font-weight: normal;
  }

.pager, .eva-items-log-pagination.pager {
    li {
      a {
        color: @gray-darker;
        border-color: @gray-3;
      }
  
      a:hover,
      &:first-child a:hover,
      &:last-child a:hover {
        color: @black;
        background-color: @gray-1;
      }
  
  
      &.disabled {
        opacity: 1;
        > a,
        > a:hover,
        > a:focus {
          border-color: @gray-3;
          pointer-events: none;
          cursor: default;
          background-color: @white;
          color: @gray-3;
          filter: none;
        }
      }
  
      &.active {
        a, a:hover {
          color: @black;
          background-color: @gray-1;
        }
      }
  
    }
  
  }
  
  .local-search-list {
  .pager {
  
    .count {
      color: @gray-2;
      b {
        font-weight: normal;
      }
    }
  
    li {
  
      a {
        color: @gray-2;
        border: none;
        background-color: transparent!important;
        float: unset;
      }
  
      a:hover,
      a:focus,
      &:first-child a:hover,
      &:last-child a:hover {
        color: @brand-primary;
      }
  
  
      &.disabled {
        > a,
        > a:hover,
        > a:focus {
          pointer-events: none;
          cursor: default;
          color: @gray-2;
        }
      }
  
      &.active {
        a, a:hover {
          color: @gray-darker;
          border:none;
          font-weight: bold;
          text-decoration: none;
        }
      }
  
    }
  }
  }
  
  .eva-pagination-icons {
  font-size: 18px;
  }
  
  .eva-pagination-arrow-icons {
  &:before{
    font-size: @eva-search-font-icon-size;
  }
  }