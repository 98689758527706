.warning {
    color: black;
  .implioicons-warning {
    color: @brand-danger;
    font-size: 18px;
  }
}


@orchestration-settings-gray-text-color: @gray-mike;
.orchestration-settings {
    width: 615px;
    .eva-primary-button {
        margin: 20px 0 50px;
    }
    .title{
        margin: 20px 0 30px 0; 
        font-size: 30px;
        font-weight: 300;
        line-height: 35px;
    }
}


.orchestration-settings-section {
    width: 100%;
    margin :24px 0px;
}

.section-label {
    margin-bottom: 6px;
}

.section-title {
    font-weight: 700;
    font-size: 16px;
    color: @gray-34;
    margin: 10px 0px;
}

.section-description{
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 12px;
}

.decision-priority {
    margin-top: 6px;
    width: 100%;
    border: 1px solid @border;
    background-color: @gray;
    padding: 16px 10px;
}
