@user-introduction-modal-width: 620px;
@user-introduction-modal-height: 545px;
@user-introduction-image-height: 375px;
@intro-text-height: 170px;
@carousel-control-bottom-margin: 13px;
@user-introduction-text-background-color: @gray;
@user-introduction-image-background-color: @gray;

.user-introduction-modal {
  background-color: @user-introduction-image-background-color;
  border-radius: @modal-border-radius;
  margin:0 !important;
  width: @user-introduction-modal-width;
  background-color: @user-introduction-image-background-color;


  .file-upload-wrapper {
    height: 375px;
    padding: 13px 13px 0 13px;
    form {
      width: 595px;
      border: 2px dashed @brand-secondary;
    }

    .box {
      height: 346px;
    }

    .box__input {
      margin-top: 84px;
    }

  }


  .active {
    text-decoration: none;
  }

  div {
    margin: 0px;
  }
  .carousel-inner > .item{
    outline: 1px solid transparent;
    background-color:transparent;
    width: 100%;
    height: @user-introduction-modal-height;
    padding: 0px;
    border: 0px;
    transition: transform 0.6s;
    &.next, &.active.right {
      .intro-text {
        h2 { animation-name: text-animation-swipe-left; }
        p { animation-name: text-animation-swipe-left; }
      }
    }
    &.prev, &.active.left {
      .intro-text {
        h2 { animation-name: text-animation-swipe-right; }
        p { animation-name: text-animation-swipe-right; }
      }
    }

    .intro-text {
      height: @intro-text-height;
      background-color: @user-introduction-text-background-color;
      h2, p {
        animation-duration: 0.8s;
        animation-timing-function:cubic-bezier(0.77, 0, 0.175, 1);
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        left: 50px;
        right: 50px;
        width: 83%;
      }

      h2 {
        margin-top: 18px;
        color: @brand-third;
        font-weight: 500;
      }
      p {
        color: @brand-secondary;
        margin-top: 54px;
        font-weight: 500;
      }
    }
  }

  .carousel-inner .user-intro-slide-6 {
    .intro-text {
      h2 { margin-top: 5px;}
      p { margin-top: 41px; }
    }
  }

  .carousel-indicators {
    bottom: @carousel-control-bottom-margin;
    li, li.active {
      margin: 0 0 0 10px;
      width: 8px;
      height: 8px;
      border: none;
    }
    li{
      background-color: @brand-secondary;
    }
    li.active {
      transition: background-color 0.6s;
      background-color: @brand-third;
    }
  }

  .btn-control {
    position: absolute;
    color: @brand-secondary;
    background: transparent;
    bottom: @carousel-control-bottom-margin;
    font-weight: bold;
    padding-left:0px;
    padding-right:0px;
  }
  #btn-prev { &:before { .arrow-left; border-color: @brand-secondary; } }
  #btn-next { &:after { .arrow-right; border-color: @brand-secondary;  } }
  #btn-finish { right: 25px; }



  /* Animation for button*/

  .fadein-and-move-up {
    animation-name: fadein-and-move-up;
    animation-duration: 0.6s;
  }

  @keyframes fadein-and-move-up {
    from {opacity: 0; margin-top: 15px;}
    to {opacity: 0.9; margin-top: 0px;}
  }

  /* Animation for text*/

  @keyframes text-animation-swipe-left {
    from {right: -20px;}
    to {right:50px;}
  }

  @keyframes text-animation-swipe-right {
    from {left: -20px;}
    to {left:50px;}
  }
}


// Scale up the modal
@media (max-width: @screen-sm-min) {
  // Automatically set modal's width for smaller viewports
  .user-introduction-modal {
    width: 100%;
  }
}


.user-intro-image-container {
  width: @user-introduction-modal-width;
  height: @user-introduction-image-height;
  background-repeat: no-repeat;
  background-size: contain;
}

.user-intro-slide-1 .user-intro-image-container {
  background-image: url('../../../../assets/intro1.png');
}

.user-intro-slide-2 .user-intro-image-container {
  background-image: url('../../../../assets/intro2.png');
}

.user-intro-slide-3 .user-intro-image-container {
  background-image: url('../../../../assets/intro3.png');
}

.user-intro-slide-4 .user-intro-image-container {
  background-image: url('../../../../assets/intro4.png');
}

.user-intro-slide-5 .user-intro-image-container {
  background-image: url('../../../../assets/intro5.png');
}

.user-intro-slide-6 .user-intro-image-container {
  height: 388px;
  background-image: url('../../../../assets/intro6.png');
}

.user-intro-slide-7 .user-intro-image-container {
  background-image: url('../../../../assets/intro7.png');
}

.user-introduction__control-relative {
  position: relative;
  height: 29px;
  display: flex;
  align-items: center;
}
.user-introduction__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.user-introduction__text {
  pointer-events: none;
  color: @brand-secondary;
  background: transparent;
  bottom: @carousel-control-bottom-margin;
  font-weight: bold;
  padding-left:0px;
  padding-right:0px;
}

.user-introduction__control--left {
  position: absolute;
  left: 25px;
  bottom: 18px;
  .user-introduction__link {
    justify-content: flex-start;
  }
}

.user-introduction__control--right {
  position: absolute;
  right: 25px;
  bottom: 18px;
  .user-introduction__link {
    justify-content: flex-end;
  }
}

#btn-prev + .user-introduction__text {
  margin-left: 20px;
}

#btn-next + .user-introduction__text {
  margin-right: 20px;
}