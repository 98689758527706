

@header-height: 38px;
@header-items-margin-left: 20px;
@items-margin: 20px;
@item-log-status-tab-height: 48px;
@buttons-tabs__item__width: 70px;
@buttons-tabs__item__height: 28px;
@buttons-tabs__border-radius: 10px;

.tabs-container {
  display: inline-flex;
  .tab {
    height: @item-log-status-tab-height;
    background-color: @white;
    display: inline-block;
    border-bottom: 3px solid @gray-41;
    padding: 0 48px 0 16px;
    line-height: @item-log-status-tab-height;
    margin: 0 2px 0 0;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      font-weight: @font-weight-medium;
      border-color: @gray-2;
    }

    &.selected {
      font-weight: @font-weight-medium;
      border-color: @brand-third;
    }

    &:after {
      display: block;
      content: attr(data-content);
      font-weight: bold;
      visibility: hidden;
      height: 1px;
    }
  }
}

.button-tabs-container {
  display: inline-flex;
  flex-direction: row;
  border-radius: calc(@buttons-tabs__border-radius + 2px);
  background-color: @gray-3;
  position: relative;
  border: 2px solid @gray-3;
  align-items: center;
  margin-left: 24px;

  .tab {
    width: @buttons-tabs__item__width;
    height: @buttons-tabs__item__height;
    text-align: center;
    box-sizing: content-box;
    line-height: @buttons-tabs__item__height;
    z-index: 1;
    margin-right: 1px;
    cursor: pointer;
  }

  .tab__chip {
    border-radius: @buttons-tabs__border-radius;
    width: @buttons-tabs__item__width;
    height: @buttons-tabs__item__height;
    position: absolute;
    background: white;
    transition: all 0.3s;
  }

  .selected:nth-child(1) ~ .tab__chip {
    left: 0%;
  }
  .selected:nth-child(2) ~ .tab__chip {
    left: 25%;
  }
  .selected:nth-child(3) ~ .tab__chip {
    left: 50%;
  }
  .selected:nth-child(4) ~ .tab__chip {
    left: 75%;
  }

}

