.eva-single-select-with-search {
    width: 272px;
    border: 1px solid @gray-3;
    border-radius: 0;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
    background-color: white;
}

.eva-single-select-with-search__input-container {
    position: relative;
    .implioicons-search();
    &:before {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        font-size: 18px;
        color: @gray-darker;
    }
}
.eva-single-select-with-search__input {
    outline: none;
    width: 100%;
    border:none;
    border-bottom: 1px solid @gray-3!important;
    color: @black;
    height: 32px;
    padding: 8px 8px 8px 32px;
}
.eva-single-select-with-search__results {
    max-height: 160px;
    overflow-y: scroll;
    margin: 4px 0;
    position: relative;
}
.eva-single-select-with-search__results-item {
    color: @black;
    max-width: 100%;
    line-height: 32px;
    padding: 0 8px;
    .truncated();
}

.eva-single-select-with-search__results-item--highlighted {
    background-color: @gray-1;
}