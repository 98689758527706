.eva-video-item-wrapper {
    position: relative;
    background-color: @border;
    position: relative;
    display: inline-block;
    &.eva-video-item-wrapper--deleted {
      opacity: 0.2;
    }
    &.eva-video-item-wrapper--waiting {
      cursor: default;
      pointer-events: none;
      .eva-video-lazy-loading-animation {
        display: block;
      }
    }
  }
  .eva-video-item__play-button {
    cursor: pointer;
    .absolute-center();
    width: 35px;
    height: 35px;
    border-radius: 20px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    img {
      width: 15px;
    }
  }
  
  .eva-video-item__play-image {
    width: 15px;
  }
  
  .eva-video-item__pause-image {
    width: 15px;
  }
  
  .eva-video-item__youtube-image {
    width: 22px;
    position: absolute;
    top: 2px;
    right: 5px;
  }
  
  .eva-video-item__youtube-image--small {
    width: 15px;
    right: 3px;
  }
  