.test-mode-warning {
  margin: 0px auto;
  text-align: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: (@eva-navbar-primary-z-index + 1);
  opacity: 0.8;
}
.test-mode-warning-production {
  background-color: yellow;
}

.test-mode-warning-staging {
  background-color: @green;
}
.navbar-implio {

  .navbar-fixed-top();
  border: none;

  .navbar-aux span {
    padding-left: 6px;
    color: @navbar-font-color;
  }

  .navbar-aux {
    .open > a,
    .open > a:hover,
    .open > a:focus {
      background-color: @navbar-bg-color;
    }
  }
  .active a {
    background-color: @navbar-bg-color;
  }

  .icon-bar {
    background-color: @white;
  }

  .navbar {
    border-radius: 0;
    margin: 0;
  }

  .navbar-toggle {
    padding: 14px 10px;
  }

  .separator {
    float: left;
    margin-right: 5px;
    margin-top: 11px;
  }

  .nav-dropdown {
    background-color: @navbar-bg-color;
    color: @white;
  }

  .user > a {
    background: none;
  }

  .logo {
    display: inline-block;
    font-size: 20px;
    height: 20px;
    margin-right: @margin;
    margin-top: 3px;

    img {
      height: 20px;
    }
  }

}

.team-content-deletion-notice {
  background-color: @gray;
  border-bottom: 1px solid @border;

  > div {
    height: 50px;
    .flex-container;
    justify-content: left;
    align-items: center;
  }


  img {
    margin: 0 6px 0 0;
  }

  p {
    margin: 0 32px 0 0;
  }


  button {
    background: transparent;
    border: 1px solid @brand-third;
    color: @brand-third;
    outline: 0!important;

    &:hover {
      background: @brand-third;
      color: @white;
    }

    &:active {
      background: @brand-third-dark;
    }
  }
}
