.select-or-input {

  input.custom-value {
    display: inline-block;
    width: 220px!important;
  }

  .implioicons-cross {
    color: @gray-eva-light;
    font-size: 19px;
    &:before {
      line-height: 38px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
