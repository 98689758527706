eva-search-array-result {
  flex-grow: 1;
  min-width: 0;
}

.search-array-result__value-container {
  flex-grow: 1;
  min-width: 0;
  display: inline-flex;
}
.search-array-result {
    width: 100%;
    display: flex;
    height: 19px;
    align-items: center;
    justify-content: space-between;
}

.search-array-result__more-value-block {
  .more-rules-block;
  margin-left: 0;
  display: inline-block;
  height: 19px;
  font-size: 12px;
  vertical-align: top;
  &.highlight-filter-match {
    color: @brand-primary;
    background-color: @yellow-3;
    border: 1px solid @brand-primary;
  }
}
.search-array-result__more-value-block,  eva-quick-add-to-list {
    flex-shrink: 0;
}
