.eva-external-link {
    display: inline-block;
    .eva-tooltip {
      top: -8px;
    }
  }
  
  .eva-external-link-icon {
    color: white;
    display: inline-block;
    .icon-style-1();
    &:hover {
      .icon-style-1-hover-focus();
    }
    position: relative;
    vertical-align: top;
    &::before {
      position: absolute;
      .absolute-center();
    }
  }