.user-role-modal {
  padding: 10px 44px 20px;

  table {
    width: 670px;
    color: @white;
    margin-bottom: 10px;

    td, th {
      padding: 10px;
      border: 1px solid black;
    }

    tr {
      height: 40px;

      td {
        background-color: @brand-danger;
        &.allowed {
          background-color: @brand-success;
        }
      }

      th, td:first-child {
        background-color: @brand-secondary;
      }

    }



  }
}
