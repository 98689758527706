.external-link {
  &:hover {
    color: #fa005a !important;
  }

  &:focus {
    color: #fa005a;
  }

  &:active {
    color: #fa005a;
  }

  &:visited {
    color: #212121;
  }
}