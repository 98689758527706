.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-cell-padding;
        line-height: @line-height-base;
        vertical-align: top;
        border-top: 1px solid @table-border-color;
        border-bottom: 1px solid @table-border-color;
      }
    }
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  > tbody > tr {
    &:hover {
      background-color: @table-bg-hover-color;
    }
  }


}

// Override ng-table background sortable th;
.ng-table {
  th {
    &.sortable {
      &.sort-desc, &.sort-asc {
        background-color: transparent;
        text-shadow: none;
      }
    }
  }
}

.table-input {
  margin-bottom: 8px;

  > .input-wrap:focus {
    border-color: @red-2;
  }

  > .input-wrap.active {
    border: 1px solid @input-border-focus;
    text-decoration: none;
  }

  > .input-wrap {
    float: left;
    border: 1px solid @input-border;
    padding: 0 8px;
    margin-right: 16px;
    margin-bottom: 0;
    border-radius: 2px;
    background-color: white;


    label {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 0;
      line-height: 28px;
    }

    input {
      padding: 0 8px;
      border: 0 !important;
      display: inline-block;
      width: auto;
      appearance: none;
      line-height: (@input-box-height - 2px);
      height: (@input-box-height - 2px);
    }

  }

  > .pager-wrapper {
    float: right;
  }
  eva-select {
    display: inline-block;
  }
}

.pager {
  float: right;
  margin: 0 !important;

  .count {
    float: left;
    line-height:  @input-box-height;
    padding: 0 16px 0 0;
  }

  .pagination {
    float: right;
    margin: 0;
  }

  li > a {
    padding: 0;
    line-height: (@input-box-height - 2px);
    width: ( @input-box-height - 2px);
  }
}

.pagination > li a,
.pagination > li:first-child a,
.pagination > li:last-child a {
  color: @text-color;
}

.jobs-list-status-icon {
  margin-left: 4px;
  margin-right: 8px;
  display: inline-block;
  font-size: 18px;

  color: @gray-dark;

  .implioicons-dot();

  &:before {
    vertical-align: baseline;
  }

  &.refused, &.REFUSE{
    color: @brand-danger;
  }
  &.approved {
    color: @brand-success;
  }
  &.MANUAL, &.reviewable, &.escalated {
    color: @brand-primary;
  }
  &.NOTHING, &.no-decision {
    color: @gray-mike;
  }
}

.ng-table {
  th {
    &.sortable {
      cursor: pointer;

      .sort-indicator {
        &:after {
          border: none;
        }
        &:before {
          border: none;
        }
      }

      &.sort-asc .sort-indicator:hover:after,
      &.sort-dest .sort-indicator:hover:after {
        opacity: 1;
      }

      &.sort-desc .sort-indicator:after,
      &.sort-asc .sort-indicator:after  {
        border-style: solid;
        border-color: @text-color transparent;
        opacity: 1;
      }
      &.sort-asc .sort-indicator:after {
        border-width: 0 4px 4px 4px;
      }
      &.sort-desc .sort-indicator:after {
        border-width: 4px 4px 0 4px;
      }
    }
  }
}

.table {
  table-layout: fixed;
  display: table;

  th {
    width: 150px;
  }

  td[data-title="'Title'"] > a {
    .overflow-table();
    display: block;
    color: @text-color;
  }
}


