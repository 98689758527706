.add-queue-modal {
    height: 180px;
    width: 680px;
    padding: 30px 35px 38px 30px!important;
    margin-top: 100px;
    h3.title {
      font-size: 24px;
      line-height: 25px;
      font-weight: normal;
      margin-top: 0px;
      padding-bottom: 10px;
    }
    input {
      border: none;
      border-bottom: 1px solid @black!important;
      padding-left: 0;
      font-size: 16px;
    }
  
    .add-queue-modal-input-container {
      width: 440px;
      display: inline-block;
      label {
        display: block;
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: @brand-secondary;
      }
      input {
        display: block;
        width: 100%;
        outline: 0!important;
      }
    }
  }
  
  .modalContent{
    background-color: white;
  }

  .confirm-btn{
    align-self: end;
    margin-left: 12px;
  }