@eva-primary-button-margin: 16px;
.eva-primary-button {
    height: 48px;
    font-weight: 500;
    background-color: @brand-third;
    color: @white;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border:none;
    padding: 0;
  
    &:focus {
      outline: 0;
    }
  
    &:hover {
      color: @white;
      background-color: @eva-primary-button-hover-background-color;
    }
  
    &[disabled]{
      cursor: @cursor-disabled;
      background-color: @gray-1;
      color: @gray-2;
    }

    &.height-as-parent {
      height: 100%;
    }

    &.width-as-parent {
      width: 100%;
      .eva-primary-button__text {
        margin:auto;
      }  
    }
}

.eva-primary-button__text {
    margin: 0 @eva-primary-button-margin;
}  

.eva-primary-button__left-icon {
    margin-left: @eva-primary-button-margin;
}

.eva-primary-button__right-icon {
    margin-right: @eva-primary-button-margin;
}

eva-secondary-button + eva-primary-button {
  margin-left: 8px;
}
.eva-primary-button-label {
  .eva-primary-button();
  padding: 0 32px;
}