@import "icons/implio-icons-font";
@import "icons/implio-icons-icons";

/*icon style 1*/
@icon-style-1-size: 24px;
@external-link-icon-size: @icon-style-1-size;
@quick-add-to-list-icon-size: @icon-style-1-size;

.icon-style-1 {
    width: @icon-style-1-size;
    height: @icon-style-1-size;
    color: @gray-2;
    background-color: transparent;
    color: white;

}

.icon-style-1-hover-focus {
    background-color: rgba(255,255,255,0.3);
}