.create-invite__content {
    display: flex;
    height: @input-height;
    margin-bottom: 10px;
    .eva-select-dropdown {
        width: 200px;
        margin-right: 10px;
    }
}

.create-invite__input {
    .eva-input--full;
    flex-grow: 1;
    margin-right: 10px;
}

.create-invite__input-error {
    .setting__error();
}
.btn{
    height: 100%;
}