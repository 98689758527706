.eva-edit-image-modal-window {
    width: 100%;
    height: 100%;
    &.fade .modal-dialog {
      .translate(0, 0); // override Bootstrap's default animation
    }
  
    .modal-content {
      border: none!important;
    }
  
    .edit-image-container {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: max-content;
      background-color: white;
    }
  
    .image-cropper-dash {
      color: @dark-gray;
    }
  
    .edit-image-main {
      width: 100%;
      .flex-container;
      align-items: stretch;
      .cropper-js-container {
        background-color: @gray-1;
      }
      .image-info {
        padding: 7px 20px 0;
        width: 309px;
        line-height: 19px;
        background-color: @gray-1;
  
        .field-name {
          color: @black;
          font-weight: 500;
          margin-top: 26px;
        }
  
        .image-info-label {
          color: @dark-gray;
          width: 100px;
          display: inline-block;
          vertical-align: top;
        }
  
        .eva-file-name-value {
          display: inline-block;
          width: 169px;
          word-break: break-all;
        }
      }
    }
  
  
  
    #btn-revert-to-original {
      margin-top: 20px;
      margin-bottom: 30px;
      height: 40px;
      width: 264px;
      background-color: @brand-third;
      text-transform: none;
  
      &:hover {
        background-color: @brand-third-dark;
      }
  
      &.disabled {
        background-color: @gray-35;
      }
    }
  
  
  
    .edit-image-control {
      height: 108px;
      margin-left: 3px;
      display: block;
      .edit-image-control-button {
        vertical-align: top;
        width: 65px;
        height: 100%;
        box-sizing: border-box;
        border-bottom: 4px solid @white;
        text-align: center;
        display: inline-block;
        cursor:pointer;
        i {
          margin-top: 17px;
          font-size: 28px;
          display: block;
          line-height: 0px;
          &:hover {
            color: @brand-third;
          }
        }
        span {
          display: block;
          margin-top: 12px;
        }
  
        &:hover {
          color: @brand-third;
          border-color: @brand-third;
        }
  
      }
  
      .edit-image-modal-buttons {
        float: right;
        display: flex;
        gap: 8px;
        margin-right: 26px;
        margin-top: 44px;
      }
    }
  
  
  
  }
  
  
  