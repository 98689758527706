@sign-up-tooltip-color: white;
@sign-up-tooltip-font-size: 12px;
@sign-up-tooltip-border-radius: 2px;
@sign-up-title-color-transition-time: 0.5s;
.sign-up-tooltip {
  color: @sign-up-tooltip-color;
  font-size: @sign-up-tooltip-font-size;
  text-transform: none;
  font-weight: normal;
  border-radius: @sign-up-tooltip-border-radius;
}
.signup-container {
  .user-email-input{
    padding: 0.5em 0;
    width: 100%;
    background: transparent;
    color: @gray-23;
    font-size: 1.25em;
    text-align: left;
  }

}

.app-createTeam .start-container {
  .alert-recaptcha{
    margin-top: 0px !important;
    float: none !important;
    text-align: left;
  }

  .select-region {
    .flex-container;
    margin: 40px 0 40px 0;
    flex-direction: column;
    justify-content: flex-start;
    label {
      text-align: left!important;
      color: @start-color-primary;
    }
    .eva-select-list {
      width: 100%;
    }
    .alert.alert-error {
      height: 14px;
      margin: 0px;
      text-align: left;
      display: block!important;
    }
  }
}


// team name
.sign-up__team-name-container {
  position: relative;
  margin: 14px 0 0 0;
}

.sign-up__company-container {
  position: relative;
  margin: 50px 0 0 0;
}

.sign-up__team-name__title, .sign-up__company__title {
  line-height: 19px;
  color: @gray-28;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: transform 0.2s, color 0.2s;
  .eva-tooltip {
    .sign-up-tooltip();
    width: 270px;
  }
}

.sign-up__team-name__error-container, .sign-up__company__error-container {
  position: absolute;
  bottom: -10px;
  transform: translate(0, 100%);
  color: @brand-danger;
  text-align: left;
}

.sign-up__team-name__title__icon-container .eva-tooltip{
  top: -6px;
}

.sign-up__team-name__title__icon-container, .sign-up__select-region__title__icon-container  {
  display: inline-block;
}

@sign-up__team-name__input-height: 41.5px;

.sign-up__team-name__input, .sign-up__company__input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5em 0;
  height: @sign-up__team-name__input-height;
  border-bottom: 1px solid @gray-28!important;
  font-size: 1.25em;
}

.sign-up__team-name__title__icon {
  color: transparent;
}

.input--filled {
  .sign-up__team-name__input {
    border-color: @brand-secondary!important;
  }

  .sign-up__team-name__title, .sign-up__company__title {
    pointer-events: all;
    color: @start-color-primary;
    transform-origin: 0 0;
    transform: translate(0, -38px) scale3d(0.9, 0.9, 1);
  }
  .sign-up__team-name__title__icon {
    color: @brand-primary;
  }
}

// select region

.sign-up__select-region__title__icon {
  color: @brand-primary;
}
.sign-up__select-region__title {
  transform-origin: 0 0;
  transform: scale3d(0.9, 0.9, 1);
  .eva-tooltip {
    .sign-up-tooltip();
    width: 230px;
  }
}

.sign-up__team-name__title__icon, .sign-up__select-region__title__icon {
  font-size: 22px;
  transition: color 0.2s;
}

.sign-up__team-name__title , .sign-up__select-region__title {
  z-index: 1;
}

.terms-of-service {
  margin-top: (@start-margin-normal/2);
  color: @gray-mike;
}
.terms-of-service-link {
  .eva-link-secondary(@start-link-color, @start-link-color);
}
