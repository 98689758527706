@input-box-height: 38px;

.input-wrap {
  float: left;
  border: 1px solid @input-border;
  padding: 0 8px;
  margin-right: 16px;
  margin-bottom: 0;
  border-radius: 2px;
  background-color: white;

  label {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
    line-height: 28px;
  }

  input {
    padding: 0 8px;
    border: 0 !important;
    display: inline-block;
    width: auto;
    appearance: none;
    line-height: (@input-box-height - 2px);
    height: (@input-box-height - 2px);
  }

}

// TO DO move this class to the parent component
.items-search-input-icon {
    font-size: 18px;
}