@job-content-item-height: 28px;
@row-divider-margin: 10px;
@job-title-font-size: 24px;
.eva-job {
  position: relative;
  display: block;
  clear: both;
  background-color: @white;
  width: 100%;

  hr {
    width: 100%;
    margin: @row-divider-margin 0px;
  }

  .eva-job-notices-row {
    margin: 0;

    .eva-notice-container {
      margin-bottom: 25px;
    }
  }

  &.eva-job-selected {
    border: 1px solid @light-blue;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
  }

  .eva-job-images-container {
    padding-left: 0;
    padding-right: 25px;
  }

  .eva-job-content-image-container {
    padding-bottom: 20px;
    border-bottom: 1px solid transparent;

    &.image-gallery-id-0 {
      .eva-job-content-item > ul {
        padding: 0 9px;
        column-count: 2;
        column-gap: 20px;
        list-style-position: inside;

        > li {
          min-height: @job-content-item-height;
          break-inside: avoid;
          page-break-inside: avoid;
          display: inline-block;
          width: 100%;

          .eva-text-box--disabled {
            align-items: center;
          }

        }
      }
    }

    &.image-gallery-id-1 {
      .flex-container;

      .eva-job-content-item-container {
        margin-left: 25px;
        flex-grow: 1;
        min-width: 0;
      }
    }

    &.image-gallery-id-2 {
      order: 3;

      .eva-job-content-item-container > ul {
        .flex-item;
      }
    }
  }

  .eva-job-title {
    font-size: @job-title-font-size;

    textarea {
      font-size: @job-title-font-size;
    }
  }

  .reviewing-bar-row {
    margin: 0;
  }

  .reviewing-bar {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
    padding: 0;
    gap: 10px;
  }

  .review-feedback {
    .select2-container-multi .select2-choices .select2-search-choice {
      box-shadow: none;
      background-image: none;
    }

    .select2-container-multi.select2-container-active .select2-choices {
      border: 1px solid @gray-9;
      box-shadow: none;
    }

    .select2-dropdown-open.select2-drop-above .select2-choices {
      border: 1px solid @gray-9;
    }

    .select2-search-choice-close {
      top: 3px;
    }

    .select2-drop.select2-drop-above {
      box-shadow: none;
    }

    .select2-dropdown-open.select2-drop-above .select2-choices {
      background-image: none;
    }

    .select2-container-multi .select2-choices .select2-search-choice {
      margin: 5px 0px 3px 5px;
    }
  }


  eva-select-list-with-header, eva-select-list {
    flex: 1 auto;

    .eva-select-list {
      width: 100%;
    }
  }

  .eva-textbox--edit {
    height: 100%;

    input.textbox {
      width: 100%;
      padding: 0 5px;
      height: 100%;
      box-sizing: border-box;
    }

  }

  .eva-job-content-item-container > ul {

    input {
      height: @job-content-item-height;
      padding: 6px;
    }

    .eva-select-list {
      height: @job-content-item-height;

      .btn-select {
        height: @job-content-item-height;
      }

      ul li:not([role="separator"]) {
        height: @job-content-item-height;
        line-height: @job-content-item-height;
      }
    }
  }
}

.eva-jobs-decision-bar {
  margin-top: 20px;
  display: flex;
  align-items: stretch; 
  gap: 15px;
}

.eva-job.image-gallery-id-2 {
  .eva-job-content-item-container > ul {
    margin-top: 8px;
  }
}

.eva-job {
  .eva-multi-select-with-search li.focused, .eva-drop-down-with-search li.focused {
    background-color: @gray;
    cursor: pointer;
  }
}

.externalLinkHover {
  &:hover {
    color: #fa005a !important;
  }

  &:focus {
    color: #fa005a;
  }

  &:active {
    color: #fa005a;
  }
}

.titleAndBodyVisitedLinkColor {
  &:visited {
    color: #212121;
  }
}