.eva-invite-users {
  width: 620px;
  height: 375px;
  padding: 32px;
  .create-invite__item {
    height: 38px;
  }
  img {
    display: block;
    width: 67px;
    height: 55px;
    margin: 24px auto 0;
  }
  h3 {
    margin: 33px auto;
    color: @brand-secondary;
    text-align: center;
  }

  > p {
    text-align: center;
    color: @gray-2;
    margin-top: 5px;
  }

  eva-create-invite {

    .help-block {
      margin: 0;
    }
    .input-group {
      margin-top: 5px;
      display: block;

      input {
        width: 348px;
      }

      .eva-select-list {
        width: 100px;
        .btn-select {
          width: 100px;
          background: transparent!important;
          border: none;
          margin: 0;
          padding: 5px;

          span {
            overflow: visible;
            white-space: normal;
          }
        }
        .dropdown-menu {
          width: auto;
          right: 0;
          left: auto;
          border: solid 1px @gray-5!important;
        }
      }

      .input-group-btn {
        vertical-align: top;
        button {
          width: 85px;
          background-color: @brand-third;
        }
      }
    }

  }

  button.add-member {
    outline: none;
    width: 348px;
    background: transparent;
    height: 38px;
    border-radius: 0;
    border-style: dashed;
    margin: 5px 0 0 0;
  }
}
