.setting__label {
  display: block;
}

.setting__subtitle {
  font-weight: 700;
  font-size: 16px;
  color: #606060;
  margin: 10px 0px;
  display: block;
}

.setting__title {
  margin: 20px 0 30px 0;
  font-size: 30px;
  font-weight: 300;
  line-height: 35px;
}

.setting__error {
  min-height: 20px;
  color: @brand-danger;
}

.data-storing-options {
  > .row {
    margin-top: 30px;
  }

  .right-side {
    margin-left: 30px;
    width: 265px;
  }

  .main-text {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.gdpr__button-group {
  display: flex;
  margin-top: 50px;
}
