.filter-sidebar-filters {
  min-width: 350px;
  overflow: auto;
  padding: 25px;
  margin: -25px;
  margin-bottom: -15px;
  padding-top: 15px;
  flex: 1;
}

.filter-sidebar-filters .multi-select .select__value-container {
  max-width: 200px;
}

.filter-sidebar-filters .multi-select input {
  position: absolute;
}

.filter-sidebar-filters>div {
  margin-bottom: 2rem;
}

.filter-sidebar-filters [data-filter-type="separator"] {
  margin-bottom: 0;
}

.filter-sidebar-filters [data-filter-type="separator"]:not(:first-child) {
  margin-top: 2rem;
}

.filter-sidebar-filters .warning-text {
  margin-top: 1rem;
  clear: both;
}

.filter-sidebar-search {
  margin: 0 !important;
  width: 100%;
  display: flex;
  align-items: baseline;
}

.filter-sidebar-search input {
  flex-grow: 1;
}

.filter-sidebar-filters .filter-group {
  display: flex;
  flex-direction: column;
  gap: inherit;
}

.filter-sidebar-buttons {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.filter-sidebar-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 25px;
  margin: -25px;
  flex-grow: 1;
}

.filter-sidebar-body .filter-date-wrapper {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.filter-date-wrapper .eva-date-picker {
  position: relative;
}

.filter-sidebar-body .eva-date-picker,
.filter-sidebar-body .form-control {
  width: 100%;
}

.filter-sidebar-body {

  .eva-select-dropdown__menu,
  .select__menu {
    z-index: 5;
  }
}

.filter-sidebar-buttons>* {
  flex-grow: 1;
}

.filter-sidebar-body h4 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-sidebar-body h4 i {
  font-size: 18px;
  height: 25px;
}

.filter-sidebar-body .input-wrap {
  float: unset;
  display: flex;
  padding: 0;
  width: 100%;

  i {
    cursor: pointer;
    font-size: 18px;
    margin-right: 8px;

    &:hover {
      opacity: 0.6;
    }
  }
}

.filter-sidebar-filters [data-filter-type="separator"] hr {
  margin: 0;
  width: 100%;
}

.expand-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 19px;
  padding: 4px;
  padding-top: 2px;
  margin-left: auto;
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-default{
  cursor: default;
}