.select2-drop-mask {
  z-index: @advanced-select-dropdown-z-index;
}

.advanced-select-dropdown, .advanced-select-dropdown.select2-drop-above {
  z-index: calc(@advanced-select-dropdown-z-index + 1);

  &.select2-drop-active {
    border: 1px solid @gray-eva-light;
  }

  .select2-search {
    margin: 4px 0px;
  }

  ul.select2-results {
    max-height: 328px;
    padding: 0px;
    margin: 0px;

    .select2-result-label, .select2-more-results {
      color: @gray-darker;
      line-height: 28px;
      padding: 0px 10px;
    }
    .select2-results-dept-1 .select2-result-label {
      padding-left: 22px;
    }

    .select2-highlighted {
      background: @gray-33;
      color: @gray-darker;
    }

    .select2-disabled{
      background-color: @white;
      > .select2-result-label {
        cursor: default;
      }
    }

    .select2-result-with-children > .select2-result-label {
      color: @gray-18;
      font-weight: normal;
    }

    .select2-results-dept-0  {
      .select2-result-label {
        border-bottom: 1px solid @gray-4;
        &:only-child {
          border: none;
        }
      }
    }

  }

}

.advanced-select-container, .advanced-select-container.select2-drop-above {

  a.select2-choice {
    height: 28px;
    line-height: 28px;
    color: @gray-darker!important;
    text-decoration: none!important;
    background: @white;
    border:1px solid @gray-eva-light;
    border-radius: 2px;
    box-shadow: none;
    .select2-arrow {
      background: @white;
      border: none;
      b {
        background: none;
        .caret();
      }
    }
  }
}
