.filter-view {

  .loading-error, .has-error {
    margin: 0 auto;
    margin-top: 64px;
    max-width: 40%;
    text-align: center;

    .link {
      .h4();
      color: @brand-primary;
      text-transform: uppercase;
      font-weight: @font-weight-bold;
      &:hover {
        color: @brand-primary-dark;
      }
    }
  }

  .rule-details {
    margin-top: 7px;
  }

  .filter-status {
    margin-top: -6px;
    display: inline-flex;
  }


  .date-picker {
    input {
      width: 100%;
    }
  }

  td[data-title="'Title'"] {
    width: 35%;
  }
  td[data-title="'Id'"] {
    width: 15%;
  }

  td > a {
    display: block;
    color: @text-color;
  }

  .filter-enabled-text {
    display: inline-block;
    position: relative;
    left: 10px;
  }

  .select-wrap {
    width: 50%;

    select {
      display: block;
      width: 100%;
      padding: 3px 5px;
      background-color: @white;
    }
  }

  .pagination {
    margin: 0;
  }
}

.filter-option, .filter-status {
  margin-right: 20px;
}

.filter-status-container {
  margin-bottom: 20px;
}